import { ChooseArticleLinkComponent } from "../choose-article-link/choose-article-link.component";
import Quill from "quill";
import { ChooseVideoComponent } from "../choose-video/choose-video.component";
import QuillLinkExtended from "./QuillLinkExtended";
const fontStyle = Quill.import("attributors/style/font");
fontStyle.whitelist = [
  "Arial",
  "Open Sans"
];
Quill.register(fontStyle, true);

class MyLink extends QuillLinkExtended {
  static create(value) {
    const node = super.create(value);
    value = super.sanitize(value);
    node.setAttribute("href", value);
    if (!value.startsWith("https://") || !value.startsWith("http://")) {
      node.removeAttribute("target");
    }
    return node;
  }
}

Quill.register(MyLink);

export class QuillHelper {
  constructor(private modalService,
    private quill) {
    const toolbar = quill.getModule("toolbar");
    toolbar.addHandler("link", this.showLinkDialog);
    toolbar.addHandler("image", this.showImageDialog);
    toolbar.addHandler("video", this.showVideoDialog);
  }

  showLinkDialog = (value) => {
    if (value) {
      const selection = this.quill.getSelection(false);
      const articleDialog = this.modalService.open(ChooseArticleLinkComponent, { size: "lg" });
      articleDialog
        .result
        .then((result: any) => {
            if (result == null) {
              return;
            }
            this.quill.setSelection(selection.index, selection.length);
            this.quill.format("link", result.link, "user");
            this.quill.update();
          },
          () => {});
    } else {
      this.quill.format("link", false);
    }
  };

  showVideoDialog = (value) => {
    if (value) {
      const selection = this.quill.getSelection(false);
      const articleDialog = this.modalService.open(ChooseVideoComponent, { size: "lg" });
      articleDialog.componentInstance.mediaType = "video";
      articleDialog
        .result
        .then((result: any) => {
            if (result == null) {
              return;
            }
            this.quill.setSelection(selection.index, selection.length);
            this.quill.format("video", result.source, "user");
          },
          () => {});
    } else {
      this.quill.format("video", false);
    }
  };
  showImageDialog = (value) => {
    if (value) {
      const selection = this.quill.getSelection(false);
      const articleDialog = this.modalService.open(ChooseVideoComponent, { size: "lg" });
      articleDialog.componentInstance.mediaType = "image";
      articleDialog
        .result
        .then((result: any) => {
            if (result == null) {
              return;
            }
            this.quill.setSelection(selection.index, selection.length);
            this.quill.format("image", result.source, "user");
          },
          () => {});
    } else {
      this.quill.format("image", false);
    }
  };

  static register(modalService, quill) {
    // ReSharper disable once WrongExpressionStatement
    new QuillHelper(modalService, quill);
  }
}
