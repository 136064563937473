import { Component, OnInit } from "@angular/core";
import { Article } from "../models/Article";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChooseVideoComponent } from "../choose-video/choose-video.component";
import { ArticleService } from "../../../shared/Services/article.service";

@Component({
  selector: "create-choose-article-link",
  templateUrl: "./choose-article-link.component.html",
  styleUrls: ["./choose-article-link.component.less"]
})
export class ChooseArticleLinkComponent implements OnInit {
  articles: Article[];
  showAlert = false;
  constructor(public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private articleService: ArticleService) {
  }
  chooseMedia() {
    const articleDialog = this.modalService.open(ChooseVideoComponent, { size: "lg" });
    articleDialog.componentInstance.mediaType = "";
    articleDialog
      .result
      .then((result: any) => {
        if (result == null) {
          return;
        }
        this.link = result.source;
      }, () => { });
  }
  toggleSelected(article: Article) {
    let isSelected = !article.isSelected;
    if (isSelected) {
      this.showAlert = false;
    }
    this.articles.forEach(art => art.isSelected = false);
    article.isSelected = isSelected;
    this.link = `#/content-view/article/${article.id}`;
  }
  ngOnInit() {
    this.articleService.loadArticles().subscribe(articles => {
      this.articles = articles;
    });
  }
  ok() {
    var option = {
      link: this.link,
      target: this.target
  }
    this.activeModal.close(option);
  }

  link: string;
  target = "";
}
