<div class="image-upload-container"
     [class.dragOver]="dragOver"
     (drop)="onDrop($event)"
     (dragover)="onDragOver($event)"
     (mouseout)="onMouseOut()">
  <div class="text-center align-items-center image-container" *ngIf="overlayImage">
    <img [alt]="createService.currentImage.title" [src]="'/uploaded-images/' + createService.currentImage.url"/>
  </div>
  <div class="text-center items-center">
    <div>Drop image here</div>
    <div>or</div>
    <div>
      <div class="btn btn-primary" (click)="addFiles()">
        <span class="fa fa-upload"></span> Click here to upload
      </div>
      <input type="file" #file style="display: none" (change)="onFilesAdded()" multiple/>
    </div>
  </div>
</div>
