<div class="container-fluid" style="padding: 0 15px;">
  <app-create-header *ngIf="!authenticationService.authenticating"  id="header"></app-create-header>
  <div class="host-container">
    <div id="main-content">
      <router-outlet #appOutlet="outlet"></router-outlet>
      <create-help *ngIf="!authenticationService.authenticating"  ></create-help>
    </div>
  </div>
  <app-create-footer id="footer"></app-create-footer>
</div>
