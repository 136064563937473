import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MediaEditComponent } from "../media-edit/media-edit.component";
import { MediaElement } from "./MediaElement";
import * as _ from "underscore";
import { MediaService } from "../../../shared/Services/media.service";

@Component({
  selector: "create-choose-video",
  templateUrl: "./choose-video.component.html",
  styleUrls: ["./choose-video.component.less"]
})
export class ChooseVideoComponent implements OnInit, OnDestroy {
  @ViewChild("file") file;
  @Input() mediaType: "video" | "image" | "" = "video";
  page:number;
  subscription: Subscription;

  constructor(
    private mediaService: MediaService,
    private modalService: NgbModal,
    public activeService: NgbActiveModal) {
  }
  addMedia() {
    const mediaEditDialog = this.modalService.open(MediaEditComponent);
    mediaEditDialog.result.then((media) => {
      this.ngOnInit();
    });
  }
  editMedia(media) {
    const mediaEditDialog = this.modalService.open(MediaEditComponent);
    mediaEditDialog.componentInstance.loadMedia(media);
    mediaEditDialog.result.then((media) => {
      this.ngOnInit();
    });
  }
  ngOnInit() {
    this.subscription = this.mediaService.getMedia().subscribe(medium => {
      switch (this.mediaType) {
      case "video":
        this.medium = medium.filter(m => m.fileType.startsWith("video"));
        break;
      case "image":
        this.medium = medium.filter(m => m.fileType.startsWith("image"));
        break;
      default:
        this.medium = medium;
        break;
      }
    });
  }
  toggleMedia(mediaItem: MediaElement) {
    const isSelected = !mediaItem.isSelected;
    this.medium.forEach((media: MediaElement) => media.isSelected = false);
    mediaItem.isSelected = isSelected;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ok() {
    let media = _.findWhere(this.medium, { isSelected: true });
    if (media == null) {

      return;
    }
    this.activeService.close(media);
  }
  medium: MediaElement[];
}
