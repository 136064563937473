<div class="container mt-2 p-2 border bg-white">
  <div class="container-fluid">
    <div class="row m-1 pb-2 border-bottom">
      <div class="col-6">
        <h4>Articles</h4>
      </div>
      <div class="col-6 text-end">
        <a routerLink="../article/0" class="btn btn-primary mt-1">
          <span class="fa fa-plus"></span> Add Article
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-hover table-striped table-sm">
          <thead class="text-center">
            <tr>
              <th>Title</th>
              <th style="width: 200px">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let article of articles">
              <td>
                <a [routerLink]="['../article', article.id]">{{article.title}}</a>
              </td>
              <td class="text-center">
                <a [routerLink]="['/content-view/article/', article.id]">view</a> |
                <a [routerLink]="['../article', article.id]">edit</a> |
                <a [routerLink]="['../delete-article', article.id]">delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
