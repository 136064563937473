import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, HostBinding } from "@angular/core";
import { Medallion, MedallionSize } from "../../../../Models/index";
import { ImageDialogComponent } from "../../../image-dialog/image-dialog.component";
import { MedallionImage } from "../../../../Models/MedallionImage";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DisplayType } from "../../../../Models/DisplayType";
import { MedallionService } from "../../../shared/Services/medallion.service";
import { CreateService } from "../../../shared/Services/create.service";
import { AuthenticationService } from "../../../shared/Services/authentication.service";

@Component({
  selector: "yw-medallion-properties",
  templateUrl: "./medallion-properties.component.html",
  styleUrls: ["./medallion-properties.component.less"]
})
export class MedallionPropertiesComponent implements OnInit, OnChanges {
  @Input() medallion: Medallion;
  @Output() changed = new EventEmitter();
  @HostBinding("class.open") isOpen: boolean = false;
  @Output() resetOverlay = new EventEmitter();
  presetColors = [
    "#dac89f",
    "#c6dae8",
    "#d7cacc",
    "#f2e5b3",
    "#f8dbe1",
    "#d7d2cb",
    "#040404",
    "#ffffff"];
  constructor(public medallionService: MedallionService,
    private createService: CreateService,
    private authentication: AuthenticationService,
    private modalService: NgbModal) {
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    setTimeout(() => {
        this.resetOverlay.emit();
      },
      300);
  }

  drop(event: CdkDragDrop<Array<MedallionImage>>) {
    moveItemInArray(this.medallion.layout, event.previousIndex, event.currentIndex);
    let z = 1;
    this.medallion.layout.forEach(layer => {
      layer.zIndex = z++;
    });
    this.changed.emit({ action: "Ordering Changed", element: this.medallion });
  }
  setActive(image) {
    var active = image.active;
    this.medallion.layout.forEach(i => i.active = false);
    image.active = !active;
  }
  inputChanged($event) {
    setTimeout(() => {
      this.changed.emit({ action: "PropertyChanged", element: this.medallion });
    }, 10);
  }
  sizeChanged(event) {
    if (this.medallion.size == null) {
      return;
    }
    var medallionSize = this.medallionSize;
    this.medallionSize = this.medallion.size;

    let heightPercentage = this.medallionSize.height / medallionSize.height;
    let widthPercentage = this.medallionSize.width / medallionSize.width;

    this.medallion.layout.forEach(l => {
      l.height = l.height * heightPercentage;
      l.width = l.width * widthPercentage;
      l.x = l.x * widthPercentage;
      l.y = l.y * heightPercentage;
    });

    setTimeout(() => {
      this.changed.emit({ action: "StyleChanged", element: this.medallion });
    }, 10);
  }
  removeLayer(image) {
    this.createService.deleteMedallionImage(image).subscribe(() => {
      this.medallion.layout = _.without(this.medallion.layout, image);
      this.changed.emit({ action: "Remove Layer", element: image });
    });
  } 

  openAddImageModal() {
    this.modalService
      .open(ImageDialogComponent, { windowClass: "fullscreen-modal" })
      .result
      .then((result) => {
          this.createService.newMedallionImage(this.medallion, result).subscribe(medallionImage => {
            medallionImage.imageObject = result;
            medallionImage.zIndex = this.medallion.layout ? this.medallion.layout.length : 0;
            var img = new Image();
            img.onload = () => {
              if (medallionImage.zIndex === 0) {
                var color = this.getColor(img);
                var hex = this.rgbToHex(color[0], color[1], color[2]);
                this.medallion.backgroundColor = hex;
              }
              medallionImage.width = img.width;
              medallionImage.height = img.height;
              medallionImage.image = img;
              this.createService.updateMedallionImage(medallionImage);
              if (this.authentication.user.displayType === DisplayType.Cover) {
                this.medallion.addCover(medallionImage);
              }
              else{
                this.medallion.addContain(medallionImage);
              }
              this.changed.emit({ action: "AddedImage", element: medallionImage });
            }
            img.src = `uploaded-images/${result.url}`;
          });
        },
        (reason) => {
          //do nothing
        });
  }

  ngOnInit() {
    this.medallionSizes = this.medallionService.medallionSizes.filter(s => s.published);
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
        var size = this.medallionService.getSize(this.medallion.size.id);
        if (size == null) {
          return;
        }
        this.medallion.size = size;
        this.medallionSize = this.medallion.size;

        if (this.medallion.layout.length < 1) {
          setTimeout(() => {
              this.openAddImageModal();
            },
            10);
        }
      },
      50);
  }

  imageDialogTimeout: any;
  medallionSizes: MedallionSize[];
  medallionSize: MedallionSize;

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  rgbToHex(r, g, b) {
    return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
  }
  getColor(img: HTMLImageElement, x: number = 0, y: number = 0) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
    return canvas.getContext('2d').getImageData(x, y, 1, 1).data;
  }
}
