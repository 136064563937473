<div class="overlay" *ngIf="project.completed"></div>
<div class="row border-bottom">
  <div class="col-sm p-1 font-weight-bold">Medallion Editor</div>
</div>
<!--<div class="row flex-editor-container"*ngIf="medallion">-->
<div class="flex-editor-container" *ngIf="medallion">
  <div id="editor-container">
    <div class="scrollable-area"
         (scroll)="zoomScroll($event)">
      <yw-medallion-canvas-html #canvasHtml
                                *ngIf="medallion.size"
                                (changed)="medallionChanged($event)"
                                [zoom]="createSettings.zoom"
                                [medallion]="medallion"
                                [size]="medallion.size"
                                [bleeding]="medallion.bleeding"></yw-medallion-canvas-html>
    </div>
    <div class="edit-toolbar">
      <a class="btn btn-link" (click)="resetCanvas()">
        <img src="/dist/assets/CenterCanvas.svg" />
      </a>
      <yw-zoom-controller [zoom]="createSettings.zoom"
                          (zoomChanged)="zoomChanged($event)"></yw-zoom-controller>
    </div>
  </div>
  <yw-medallion-properties (changed)="medallionChanged($event)"
                           (resetOverlay)="resetOverlay()"
                           [medallion]="medallion"></yw-medallion-properties>
</div>
