import { IMedallionSize } from "../Interfaces/index";

export class MedallionSize implements IMedallionSize {
  title: string;
  id: number;
  printWidth: number;
  printHeight: number;
  width: number;
  height: number;
  medallionCount: number;
  published: boolean;
  allowBleed: boolean;
  bleedSize: number;
  multiplier: number = 1;
}
