import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { share } from "rxjs/operators";
import * as _ from "underscore";
import { Router } from "@angular/router";
import { MenuItem } from "../../admin/content/models/MenuItem";
import { Guid } from "../../admin/content/models/Guid";
import { MenuItemType } from "../../admin/content/models/MenuItemType";
const $window: Window = window;

@Injectable({
  providedIn: "root"
})
export class MenuItemsService {
  constructor(private http: HttpClient,
    private router: Router) { }
    menuItems = new BehaviorSubject<MenuItem[]>( new Array<MenuItem>());
  //menuItems = new BehaviorSubject<Array<MenuItem>>(new Array<MenuItem>());
  getMenuItem(id: string): Observable<MenuItem> {
    var result = this.http.get<MenuItem>(`/api/menuItem/get/${id}`).pipe(share());
    return result;
  }

  delete(id: string): Observable<MenuItem> {
    var result = this.http.delete<MenuItem>(`/api/menuItem/delete/${id}`).pipe(share());
    result.subscribe(() => {
     // var currentArticle= _.findWhere(this.menuItems, {id=id}  );
     // var currentArticle:string[]=[];
     var currentArticle= _.findWhere(this.menuItems.value, {id:id}  );
      this.menuItems.next(_.without(this.menuItems.value, currentArticle));
      //var currentArticle = _.findWhere(this.menuItems, { id: id });
      //this.menuItems = _.without(this.menuItems, currentArticle);
    });
    return result;
  }

  add(menuItem: MenuItem): Observable<MenuItem> {
    menuItem.id = Guid.newGuid();
    var result = this.http.put<MenuItem>("/api/menuItem/put", menuItem).pipe(share());
    result.subscribe(resultArticle => {
      let articles: MenuItem[] = this.menuItems.value;
      articles.push(resultArticle);
      this.menuItems.next(articles);
    });
    return result;
  }

  update(menuItem: MenuItem): Observable<MenuItem> {
    var result = this.http.post<MenuItem>("/api/menuItem/post", menuItem).pipe(share());
    result.subscribe(menuItem => {
      let menuItems: MenuItem[] = this.menuItems.value;
      let item: MenuItem = _.findWhere(menuItems, { id: menuItem.id });
      item.published = menuItem.published;
      item.menuPosition = menuItem.menuPosition;
      item.ordering = menuItem.ordering;
      this.menuItems.next(menuItems);

    });
    return result;
  }
  getMenuItems(): Observable<MenuItem[]> {
    var result = this.http.get<MenuItem[]>("/api/menuItem/getAll/").pipe(share());
    result.subscribe(menuItems => {
      this.menuItems.next(menuItems);
    });
    return result;
  }

  saveOrdering(menuItems: MenuItem[]) {
    var orderMenuItems = menuItems.map(i => {
      return {
        id: i.id,
        ordering: i.ordering
      };
    });
    var result = this.http.post<MenuItem[]>("/api/menuItem/updateOrdering/", orderMenuItems).pipe(share());
    result.subscribe(menuItems => {
      let articles: MenuItem[] = this.menuItems.value;
      this.menuItems.next(articles);
    });
    return result;
  }

  toggleDefault(menuItem: MenuItem): Observable<MenuItem> {
    var result = this.http.post<MenuItem>("/api/menuItem/toggleDefault", menuItem).pipe(share());
    result.subscribe(() => {
      let menuItems: MenuItem[] = this.menuItems.value;
      let item: MenuItem = _.findWhere(menuItems, { id: menuItem.id });
      item.default = menuItem.default;
      this.menuItems.next(menuItems);

    });
    return result;
  }

  navigate(menuItem: MenuItem) {
    if (menuItem == null) {
      return;
    }
    switch (menuItem.menuItemType) {
    case MenuItemType.Article:
      this.router.navigate(["content-view", "article", menuItem.articleId]);
      break;
    case MenuItemType.Component:
      this.router.navigate([menuItem.componentName]);
      break;
    case MenuItemType.ExternalLink:
      $window.open(menuItem.url, "_blank");
      break;
    }
  }
}
