import { Component, OnInit } from "@angular/core";
import { CreateImage } from "../../../Models/CreateImage";
import { CategoryImageListBaseComponent } from
  "../../shared/category-image-list-base/category-image-list-base.component";
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { CreateService } from "../../shared/Services/create.service";

@Component({
  selector: "yw-family-supplied-photos",
  templateUrl: "./family-supplied-photos.component.html",
  styleUrls: ["./family-supplied-photos.component.less"]
})
export class FamilySuppliedPhotosComponent extends CategoryImageListBaseComponent implements OnInit {
  images: CreateImage[];

  constructor(private imageManager: ImageManagerService,
    public createService: CreateService) {
    super(createService);
  }
  clicked(image) {
    this.imageUpdated(image);
  }
  imageSet(image) {
    this.images.push(image);
  }
  ngOnInit() {
    this.createService.project$.subscribe(project => {
      this.imageManager.getProjectImages(project.id).subscribe(images => {
        this.images = images;
      });
    });
  }
}
