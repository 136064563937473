import { Component, OnInit, Input, OnChanges, SimpleChanges, HostListener, EventEmitter, Output } from "@angular/core";
import { WorkSpaceElement } from "../WorkSpaceElement";
import { MedallionImage } from "../../../../../Models/MedallionImage";
import { DragAction } from "../../../../../Models/DragAction";
import { Rectangle } from "../../../../../Models/Rectangle";
import { Medallion, Point } from "../../../../../Models/index";

@Component({
  selector: "yw-editor-medallion-image",
  templateUrl: "./editor-medallion-image.component.html",
  styleUrls: ["./editor-medallion-image.component.less"]
})
export class EditorMedallionImageComponent implements OnInit, OnChanges {
  @Input()
  zoom: number = .16;
  @Input()
  layer: MedallionImage;
  @Input()
  imageX: number;
  @Input()
  imageY: number;
  @Input()
  imageHeight: number;
  @Input()
  imageWidth: number;
  @Input()
  workspace: WorkSpaceElement;
  @Input()
  medallion: Medallion;

  @Output() changed = new EventEmitter();
  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  ngOnInit() {
    this.width = this.imageWidth * this.zoom;
    this.height = this.imageHeight * this.zoom;
    this.x = this.imageX * this.zoom;
    this.y = this.imageY * this.zoom;
  }
  mousedown(event: MouseEvent, action: DragAction) {
    if (event.ctrlKey) {
      return;
    }
    if (!this.layer.active) {
      this.medallion.layout.forEach(layer => layer.active = false);
      this.layer.active = true;
    }
    this.currentAction = action;
    this.pageOffset = new Point(event.pageX, event.pageY);
    this.startOffset = new Point(event.pageX, event.pageY);
    this.startRect = new Rectangle(this.x, this.y, this.width, this.height);
    event.preventDefault();
    event.cancelBubble = true;
  }

  @HostListener("document:mousemove", ["$event"])
  mouseMove(event: MouseEvent) {
    switch (this.currentAction) {
      case DragAction.None:
        return;
      case DragAction.Move:
        this.moveEvent(event);
        break;
      case DragAction.ScaleBottom:
      case DragAction.ScaleBottomLeft:
      case DragAction.ScaleBottomRight:
      case DragAction.ScaleTop:
      case DragAction.ScaleTopLeft:
      case DragAction.ScaleTopRight:
      case DragAction.ScaleLeft:
      case DragAction.ScaleRight:
        this.scaleEvent(event);
    }
  }

  @HostListener("document:mouseup", [])
  mouseUp() {
    if (this.currentAction !== DragAction.None) {
      this.changed.emit({ action: this.currentAction, element: this });
      this.currentAction = DragAction.None;
    }
  }

  scaleEvent(event: MouseEvent) {
    let rect = new Rectangle(0, 0, 0, 0);

    var offset = new Point(event.pageX, event.pageY);
    var diff = new Point(offset.x - this.pageOffset.x, offset.y - this.pageOffset.y);

    switch (this.currentAction) {
      case DragAction.ScaleBottom:
      case DragAction.ScaleBottomLeft:
      case DragAction.ScaleBottomRight:
        rect.height = this.startRect.height + diff.y;
        rect.y = this.startRect.y;
        break;
      case DragAction.ScaleTop:
      case DragAction.ScaleTopLeft:
      case DragAction.ScaleTopRight:
        rect.height = this.startRect.height - diff.y;
        rect.y = this.startRect.y + diff.y;
        break;
      default:
        rect.height = this.startRect.height;
        rect.y = this.startRect.y;
    }
    switch (this.currentAction) {
      case DragAction.ScaleBottomRight:
      case DragAction.ScaleTopRight:
      case DragAction.ScaleRight:
        rect.width = this.startRect.width + diff.x;
        rect.x = this.startRect.x;
        break;
      case DragAction.ScaleTopLeft:
      case DragAction.ScaleBottomLeft:
      case DragAction.ScaleLeft:
        rect.width = this.startRect.width - diff.x;
        rect.x = this.startRect.x + diff.x;
        break;
      default:
        rect.width = this.startRect.width;
        rect.x = this.startRect.x;
        break;
    }

    if (event.shiftKey) {
      this.updateLayer(rect);
    }
    else {
      let wPercent = rect.width / this.width;
      let hPercent = rect.height / this.height;
      var minPercent = Math.min(wPercent, hPercent);
      rect.width = this.width * minPercent;
      rect.height = this.height * minPercent;
      this.updateLayer(rect);
    }
  }
  updateLayer(rect: Rectangle) {
    this.layer.x = rect.x / this.zoom;
    this.layer.y = rect.y / this.zoom;
    this.layer.width = rect.width / this.zoom;
    this.layer.height = rect.height / this.zoom;
  }
  moveEvent(event: MouseEvent) {
    let snapSize = 3;
    var offset = new Point(event.pageX, event.pageY);
    var diff = new Point(offset.x - this.pageOffset.x, offset.y - this.pageOffset.y);
    this.pageOffset = offset;
    let x = this.x + diff.x;
    let y = this.y + diff.y;
    //check for snap
    if (x < snapSize && x > -snapSize) {
      x = 0;
    }
    if (y < snapSize && y > -snapSize) {
      y = 0;
    }
    if (this.medallion.bleeding) {
      if (((x + this.width) - ((this.medallion.size.printWidth * 300) * this.zoom)) < snapSize &&
        ((x + this.width) - ((this.medallion.size.printWidth * 300) * this.zoom)) > -snapSize) {
        x = ((this.medallion.size.printWidth * 300) * this.zoom) - this.width;
      }
      if (((y + this.height) - ((this.medallion.size.printHeight * 300) * this.zoom)) < snapSize &&
        ((y + this.height) - ((this.medallion.size.printHeight * 300) * this.zoom)) > -snapSize) {
        y = ((this.medallion.size.printHeight * 300) * this.zoom) - this.height;
      }
    }
    else {
      if (((x + this.width) - ((this.medallion.size.width * 300) * this.zoom)) < snapSize &&
        ((x + this.width) - ((this.medallion.size.width * 300) * this.zoom)) > -snapSize) {
        x = ((this.medallion.size.width * 300) * this.zoom) - this.width;
      }
      if (((y + this.height) - ((this.medallion.size.height * 300) * this.zoom)) < snapSize &&
        ((y + this.height) - ((this.medallion.size.height * 300) * this.zoom)) > -snapSize) {
        y = ((this.medallion.size.height * 300) * this.zoom) - this.height;
      }
    }
    this.layer.x = x / this.zoom;
    this.layer.y = y / this.zoom;
  }


  width: number;
  height: number;
  x: number;
  y: number;
  currentAction: DragAction = DragAction.None;
  offset: Point;
  pageOffset: Point;
  startRect: Rectangle;
  startOffset: Point;
}
