import { Component, OnInit, Input } from "@angular/core";
import * as _ from "underscore";
import { HelpDocument } from "../help/help-docs/HelpDocument";
import { HelpDocsService } from "../Services/help-docs.service";
import { AuthenticationService } from "../Services/authentication.service";

@Component({
  selector: "create-help-docs-button",
  templateUrl: "./help-docs-button.component.html",
  styleUrls: ["./help-docs-button.component.less"]
})
export class HelpDocsButtonComponent implements OnInit {
  @Input() target: string;
  @Input() zIndex: number = 990;
  constructor(private helpDocsService: HelpDocsService,
    public authentication: AuthenticationService) { }

  ngOnInit() {
    this.helpDocsService.helpDocs.subscribe(docs => {
      var doc: HelpDocument = _.findWhere(docs, { id: this.target });
      this.exist = doc != null;
      this.published = doc != null && doc.published;
    });
  }
  clicked() {
    console.log(this.zIndex);
    this.helpDocsService.zIndex = this.zIndex;
    this.helpDocsService.currentTarget.next(this.target);
  }

  exist:boolean;
  published: boolean;
}
