import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CreateImage } from "../../../../Models/index";

@Component({
  selector: "yw-image-display",
  templateUrl: "./image-display.component.html",
  styleUrls: ["./image-display.component.less"]
})
export class ImageDisplayComponent implements OnInit {
  @Input() image: CreateImage;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.imageUrl = this.sanitizer.bypassSecurityTrustStyle(`url(uploaded-images/${this.image.url})`);
  }
  imageUrl;
}
