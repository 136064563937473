<div class="container-fluid p-1" provide-parent-form>
  <div class="row">
    <div class="col-sm">
      <label>
        <input [(ngModel)]="CreateService.currentImage.galleryView"
               name="galleryView"
               type="checkbox"
               id="galleryView" />
        Gallery Image
      </label>
    </div>
  </div>
  <div class="row"
       *ngIf="CreateService.currentImage.galleryView">
    <div class="col-sm">
      <label for="categoryTitle">Title</label>
      <input class="form-control border-bottom"
             autocomplete="off"
             [(ngModel)]="CreateService.currentImage.title"
             name="categoryTitle"
             id="categoryTitle" />
    </div>
  </div>
  <div class="row"
       *ngIf="CreateService.currentImage.galleryView">
    <div class="col-sm">
      <label for="category">Category</label>
      <input class="form-control border-bottom"
             [(ngModel)]="CreateService.currentImage.category"
             name="category"
             id="category" />
    </div>
  </div>
  <div class="row"
       *ngIf="CreateService.currentImage.galleryView">
    <div class="col-sm">
      <label for="keywords">Keywords</label>
      <textarea type="text"
                class="form-control"
                [(ngModel)]="image.keywords"
                id="keywords"></textarea>
    </div>
  </div>
  <input type="hidden"
         [(ngModel)]="CreateService.currentImage.url"
         name="url"
         required
         id="url" />
</div>
