import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
    pageList: number[];

    @Input()
    get page() { return this.pageValue; }
    @Output() pageChange = new EventEmitter();
    set page(value: number) {
        this.pageValue = value; 
        this.pageChange.emit(this.pageValue);
    }

    @Input()
    set pageSize(value: number) {
        this.pageSizeValue = value;
        this.updateNumberOfPages();
    }

    @Input()
    set collectionSize(value: number) {
        this.collectionSizeValue = value;
        this.updateNumberOfPages();
    }
    @Input()
    maxSize: number = 5;

    constructor() {}
    setPage(pageId, event: MouseEvent) {
        this.page = pageId;
        event.stopPropagation();
        event.preventDefault();
    }
    ngOnChanges() {
        
        let pageNumber = Math.floor((this.pageValue) - (this.maxSize / 2));
        
        if (pageNumber < 0) {
            pageNumber = 0;
        }
        if (pageNumber >= (this.numberOfPages - 1) - this.maxSize) {
            pageNumber = this.numberOfPages - this.maxSize;
        }

        this.displayPages = this.pageList.slice(pageNumber, pageNumber + this.maxSize);
       
    }

    pageValue: number;
    numberOfPages: number;
    pageSizeValue: number;
    collectionSizeValue: number;

    updateNumberOfPages() {
        this.pageList = [];
        this.numberOfPages = Math.ceil(this.collectionSizeValue / this.pageSizeValue);
        for (let i = 1; i <= this.numberOfPages; i++) {
            this.pageList.push(i);
        }
        this.ngOnChanges();
    }

    displayPages: number[];
}
