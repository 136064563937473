<div class="d-flex">
  <div class="col-3 align-self-start text-start">
    <create-menu [menuPosition]="'header'" [type]="'dropdown'"></create-menu>
    <a routerLink="/">
      <img id="bv-logo" alt="Batesville Logo" src="/dist/assets/bv-logo-vector.svg" />
    </a>
  </div>
  <div class="col align-self-end text-end" style="text-align:right">
    <div *ngIf="authenticationService.user.id!=null">
      <div class="inline-control" ngbDropdown placement="bottom-right">
        <div id="viewing-actions" ngbDropdownToggle>
          Viewing: {{ selectedLocation.id }} - {{ selectedLocation.name }} <i *ngIf="authenticationService.changeLocationAllowed" class="fa fa-caret-down"></i>
        </div>
        <div ngbDropdownMenu
             aria-labelledby="viewing-actions"
             style="padding-top: 0; width: 430px;"
             *ngIf="authenticationService.changeLocationAllowed">
          <!--<div ngbDropdownItem >-->
            <div class="list-group-item" *ngIf="locationList?.length > 10">
              <input [(ngModel)]="locationSearch" (input)="filterLocations()" (click)="clickInput($event)" placeholder="Search" class="form-control search" />
            </div>
          <a *ngFor="let location of filteredLocations | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize "
             class="dropdown-item"
             href="javascript:void(0)"
             (click)="selectLocation(location)">{{location.id}}-{{location.name}}</a>
          <div style="padding: 10px; text-align: center;"
               *ngIf="!filteredLocations?.length">No locations found!</div>
            <div class="text-center" *ngIf="filteredLocations?.length>10">
              <pagination [(page)]="page"
                          [pageSize]="pageSize"
                          [collectionSize]="filteredLocations?.length"
                          [maxSize]="5"
                          style="padding: 0; display: block; margin: 5px 0 -15px 0; border-top: 1px solid #ccc;"></pagination>
            </div>
          <!--</div>-->
        </div>
      </div>
      <span class="horizontal-divider"></span>
      <div class="inline-control" ngbDropdown placement="bottom-right">
        <div id="user-actions" ngbDropdownToggle>
          {{ authenticationService.user.name }} <i class="fa fa-gear"></i>
        </div>
        <div ngbDropdownMenu aria-labelledby="user-actions">
          <a class="dropdown-item"
             *ngIf="authenticationService.user.role>=100"
             routerLink="/admin/content">Admin</a>

          <!--YW-132- Add return to Batesville.com button-->
          <a class="dropdown-item"
             [href]="returnUrl">Back to Batesville</a>
          <!--YW-132-Add return to Batesville.com button-->

          <a class="dropdown-item"
             href="/loggedOut">Logout</a>
        </div>
      </div>
    </div>
    <div *ngIf="authenticationService.user.id==null">
      <div *ngIf="authenticationService.authenticating">
        <span class="fa fa-spinner fa-spin"></span>
      </div> 
      <div *ngIf="!authenticationService.authenticating">
        <a class="btn btn-primary" href="/">Login</a> 
      </div>
    </div>
  </div>
</div>
<create-menu [menuPosition]="'nav-bar'" [type]="'navbar'"></create-menu>

