import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "create-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.less"]
})
export class ConfirmDialogComponent implements OnInit {

  @Input()
  title: string = "Are you sure?";
  @Input()
  message: string = "This is a permanent change, are you sure?";
  @Input()
  btnOkText: string = "Ok";
  @Input()
  btnCancelText: string= "Cancel";

  constructor(public activeModal: NgbActiveModal,) {
  }

  ngOnInit() {
  }

  decline() {
    this.activeModal.close(false);
  }

  accept() {
    this.activeModal.close(true);
  }
}
