import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MedallionSize } from "../../../Models";
import { MedallionService } from "../../shared/Services/medallion.service";

@Component({
  selector: "create-delete-medallion",
  templateUrl: "./delete-medallion.component.html",
  styleUrls: ["./delete-medallion.component.less"]
})
export class DeleteMedallionComponent implements OnInit {
  medallionSize = new MedallionSize();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private medallionService: MedallionService) {
    const id = +this.route.snapshot.paramMap.get("id");
    this.medallionService.getMedallionSize(id).subscribe(medallionSize => {
      this.medallionSize = medallionSize;
    });
  }
  deleteMedallion() {
    this.medallionService.deleteMedallionSize(this.medallionSize).subscribe(() => {
      this.router.navigate(["admin", "medallions"]);
    });
  }
  ngOnInit() {
  }

}
