import { Component, OnInit, Input } from "@angular/core";
import { HelpDocument } from "./HelpDocument";
import { AuthenticationService } from "../../Services/authentication.service";
import { HelpDocsService } from "../../Services/help-docs.service";

@Component({
  selector: "create-help-docs",
  templateUrl: "./help-docs.component.html",
  styleUrls: ["./help-docs.component.less"]
})
export class HelpDocsComponent implements OnInit {
  @Input() document: HelpDocument;
  @Input() sorting: boolean;
  editDocument: boolean;
  roleOptions = [
    { id: 0, name: "Default" },
    { id: 100, name: "Admin" },
    { id: 1000, name: "System Admin" }
  ];
  constructor(public authentication: AuthenticationService,
    private helpDocsService: HelpDocsService) { }
  changed() {
    this.helpDocsService.updateDoc(this.document).subscribe(docs => {
      //console.log(docs);
    });
  }
  ngOnInit() {
  }

}
