<ul class="category-list">
  <li class="category-container"
      *ngFor="let category of categories">
    <div class="category-header border-bottom p-2 m-2">
      <div class="d-flex">
        <div class="col">{{category.title}}</div>
        <div class="col text-end">
          <div class="btn btn-outline-primary"
               (click)="openSort(category)">
            <span class="fa fa-sort"></span> Ordering
          </div>
        </div>
      </div>
    </div>
    <div class="category-image-list d-flex justify-content-start flex-wrap"> 
      <div class="card-image-stock"
          *ngFor="let image of category.images">
        <div class="inner-card"
             (click)="clickedImage(image)">
          <div class="deleted-image-overlay"
               *ngIf="image.deleted"></div>
          <img [alt]="image.title"
               class="card-img-top"
               [src]="'/uploaded-images/' + image.url"/>
          <div class="card-body">
            {{image.title}}
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
