import {
  Component, OnInit, Input, OnDestroy, HostListener,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { IProject } from "../../../Interfaces/index";
import { MedallionImage } from "../../../Models/MedallionImage";
import { Medallion, Point, Size } from "../../../Models/index";
import { ZoomLevel } from "../../../Models/ZoomLevel";
import { Rectangle } from "../../../Models/Rectangle";
import { MedallionCanvasHtmlComponent } from "./medallion-canvas-html/medallion-canvas-html.component";
import { MedallionService } from "../../shared/Services/medallion.service";
import { CreateService } from "../../shared/Services/create.service";
import { CreateSettingsService } from "../../shared/Services/create-settings.service";

@Component({
  selector: "yw-medallion-editor",
  templateUrl: "./medallion-editor.component.html",
  styleUrls: ["./medallion-editor.component.css"]
})
export class MedallionEditorComponent implements OnInit, OnDestroy {
  @Input() project: IProject;
  @Output() changed = new EventEmitter();

  @ViewChild(MedallionCanvasHtmlComponent) canvasHtmlChild: MedallionCanvasHtmlComponent;
  
  constructor(public medallionService: MedallionService,
    public createService: CreateService,
    public createSettings: CreateSettingsService) {
  }

  resetOverlay() {
    this.canvasHtmlChild.refreshOverlay();
  }
  medallionChanged(event) {
    if (event.action === "StyleChanged") {
      setTimeout(() => {
          this.resetCanvas();
        },
        750);
    }
    this.canvasHtmlChild.updateImage();
    this.createService.updateMedallion(this.medallion);
    this.changed.emit(event);
  }
  @HostListener("wheel", ["$event"])
  zoomScroll($event: WheelEvent) {
    let stepAmount = 1;
    if ($event.shiftKey) {
      stepAmount = 10;
    }
    if ($event.altKey) {
      $event.preventDefault();
      $event.cancelBubble = true;
      if ($event.deltaY > 0) {
        this.createSettings.zoom.current += .0125 * stepAmount;
      }
      else if ($event.deltaY < 0) {
        this.createSettings.zoom.current -= .0125 * stepAmount;
      }

      if (this.createSettings.zoom.current > this.maxZoomLevel) {
        this.createSettings.zoom.current = this.maxZoomLevel;
      }
      else if (this.createSettings.zoom.current < this.createSettings.zoom.min) {
        this.createSettings.zoom.current = this.createSettings.zoom.min;
      }
      this.createSettings.zoom = ({ max: this.createSettings.zoom.max, min: this.createSettings.zoom.min, current: this.createSettings.zoom.current} as ZoomLevel);
    }
  }
  resetCanvas() {
    const padding = 200;
    if (this.canvasHtmlChild == null) {
      return;
    }
    this.canvasHtmlChild.workspace.pan = new Point(0, 0);

    var rect = new Rectangle(0, 0, this.medallion.size.width * this.createSettings.pixelsPerInch, this.medallion.size.height * this.createSettings.pixelsPerInch);
    rect.right = this.medallion.size.width * this.createSettings.pixelsPerInch;
    rect.bottom = this.medallion.size.height * this.createSettings.pixelsPerInch;

    this.medallion.layout.forEach(layer => {
      rect.x = Math.min(layer.x, rect.x);
      rect.y = Math.min(layer.y, rect.y);
      rect.right = Math.max(layer.x + layer.width, rect.right);
      rect.bottom = Math.max(layer.y + layer.height, rect.bottom);
    });

    rect.width = rect.right + (rect.x * -1);
    rect.height = rect.bottom + (rect.y * -1);
    const canvasSize = new Size((this.canvasHtmlChild.workspace as any).workspace.width() - padding, (this.canvasHtmlChild.workspace as any).workspace.height() - padding);
    const newSize = new Size(canvasSize.width / rect.width, canvasSize.height / rect.height);
    const current = Math.min(newSize.width, newSize.height);
    this.createSettings.zoom = ({ max: this.createSettings.zoom.max, min: this.createSettings.zoom.min, current: current } as ZoomLevel);
  }
  ngOnInit() {
    this.medallion = null;
    this.subscribe = this.createService.currentMedallion.subscribe(medallion => {
      this.medallion = medallion;
      if (medallion == null) {
        return;
      }
      if (this.medallion.isNew) {
        //we need to give time for the page to render before updating the thumbnail
        setTimeout(() => {
            this.medallionChanged({ event: "new medallion", option: this.medallion });
            this.medallion.isNew = false;
          },
          100);
      }
      setTimeout(() => {
        this.resetCanvas();
      }, 50);
    });
  }

  zoomChanged(zoomLevel) {
    this.createSettings.zoom.current = zoomLevel;
  }
  setActive(image: MedallionImage) {
    this.medallion.layout.forEach(img => img.active = false);
    image.active = true;
  }
  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
  transformScaleSafe;
  subscribe: any;
  medallion: Medallion;
  maxZoomLevel:number = 4;

}
