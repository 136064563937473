import { Component, OnInit, ViewContainerRef, OnDestroy } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import objectFitImages from "object-fit-images";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SurveyDialogComponent } from "./Components/survey-dialog/survey-dialog.component";
import * as _ from "underscore";
import { MenuItem } from "./Components/admin/content/models/MenuItem";
import { first } from "rxjs/operators";
import { MedallionService } from "./Components/shared/Services/medallion.service";
import { AuthenticationService } from "./Components/shared/Services/authentication.service";
import { ApplicationService } from "./Components/shared/Services/application-service.service";
import { MenuItemsService } from "./Components/shared/Services/menu-items.service";
import { User } from "./Models/index";
import { Gtag } from "angular-gtag";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit {
  constructor(private medallionService: MedallionService,
    public viewContainerRef: ViewContainerRef,
    private router: Router,
    gtag: Gtag,
    private modalService: NgbModal,
    public authenticationService: AuthenticationService,
    private menuItemsService: MenuItemsService,
    private applicationService: ApplicationService) {
    objectFitImages();
  }

  getPage(outlet) {
    return outlet.activatedRouteData["page"] || "projects";
  }
  ngOnInit(): void {

    this.applicationService.getSettings().subscribe(settings => {
      if (settings.isSSO) {
        this.authenticationService.ssoLogin().subscribe(result => {
          if (result.data==true) {
            this.authenticationService.getUser().subscribe(user => {
              this.authenticationService.authenticating = false;
              this.launchSurvey(user);
              this.medallionService.loadMedallionSizes();
            });
          }
          else if (result.data =="location not found") {
            window.location.href = window.location.origin + "/Error/";
          }
          else {
            this.authenticationService.gigyaparam();
          }
        });
      }
      else {
        this.authenticationService.getUser().subscribe(user => {
          this.authenticationService.authenticating = false;
          this.launchSurvey(user);
          this.medallionService.loadMedallionSizes();
        });
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.menuItemsService.menuItems.subscribe(menuItems => {
      var filtered = menuItems.filter(menuItem => menuItem.menuPosition === "nav-bar");
      this.hasNavBar = filtered.length > 0;
      if (this.hasNavBar) {
        document.body.classList.add("has-nav-bar");
      } else {
        document.body.classList.remove("has-nav-bar");
      }
    });
  }

  hasNavBar: boolean = false;

  launchSurvey(user: User) {
    var thirtyDaysAgo = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
    var surveyDate = new Date(user.surveyDate);
    if (surveyDate.getTime() > thirtyDaysAgo || user.optOut) {
      return;
    }
    this.menuItemsService.menuItems.pipe(first()).subscribe(menuItems => {
      var menuItem: MenuItem = _.findWhere(menuItems, { menuPosition: "survey-dialog" });
      if (menuItem == null || !menuItem.published) {
        return;
      }
      let modalInstance = this.modalService.open(SurveyDialogComponent);
      modalInstance.componentInstance.menuItem = menuItem;
      modalInstance.result.then(neverAgain => {
        this.authenticationService.surveyUpdate(neverAgain).subscribe(() => {
        });
        },
        () => {});
    });
  }
}
