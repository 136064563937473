import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

class ApplicationSettings {
  baseOAuthUri: string;
  oAuthClientId: string;
  isSSO: boolean = false;
}

@Injectable({
  providedIn: "root"
})
export class ApplicationService {
  condensedFooter: boolean = false;
  constructor(private http: HttpClient) { }
  started: boolean = false;
  getSettings() {
    if (this.started) {
      return new Observable<ApplicationSettings>();
    }
    this.started = true;
    var observable = this.http.get<ApplicationSettings>("/api/application/GetSettings");
    observable.subscribe(applicationSettings => {
      this.applicationSettings = applicationSettings;
    });
    return observable;
  }

  applicationSettings: ApplicationSettings;
}
