<div class="m-3 p-2 border bg-white">
  <div class="container-fluid">
    <div class="row p-2 border-bottom mb-2">
      <div class="col-12">
        <h4>{{title}}</h4>
      </div>
    </div>
    <div class="row border-bottom p-2">
      <div class="col-9">
        <div class="container-fluid">
          <div class="row p-2">
            <div class="col-12">
              <label for="title">
                Title:
              </label>
              <input id="title" class="form-control" [(ngModel)]="menuItem.title" />
            </div>
          </div>
          <div class="row p-2" *ngIf="menuItem.menuItemType == 1">
            <div class="col-12">
              <label>
                Article:
              </label>
              <div *ngIf="menuItem.article">{{menuItem.article.title}}</div>
              <a href="javascript:void(0)" (click)="getArticle()">Choose article</a>
            </div>
          </div>
          <div class="row p-2" *ngIf="menuItem.menuItemType == 2">
            <div class="col-12">
              <label for="component">
                Component:
              </label>
              <select id="component"
                      class="form-control"
                      [(ngModel)]="menuItem.componentName">
                <option value="projects">Projects</option>
                <option value="images">Images</option>
              </select>
            </div>
          </div>
          <div class="row p-2" *ngIf="menuItem.menuItemType == 3">
            <div class="col-12">
              <label for="url">
                External Link:
              </label>
              <input id="url" class="form-control" [(ngModel)]="menuItem.url" />
            </div>
          </div>
          <div class="row p-2" *ngIf="menuItem.menuPosition == 'survey-dialog'">
            <div class="col-12">
              <a  class="btn btn-link" (click)="menuItem.allowOptOut = !menuItem.allowOptOut">
                <span class="mr-1"><create-toggle-check style="display: inline-block" [value]="menuItem.allowOptOut"></create-toggle-check></span>
                <span>Allow Opt Out</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 border-left">
        <div class="container-fluid">
          <div class="row border-bottom pt-1 pb-1 mb-1">
            <div class="col-12">
              <h5>Properties</h5>
            </div>
          </div>
          <div class="row mb-1">
            <label for="published" class="col-6">
              Published:
            </label>
            <div class="col-6 text-end">
              <input id="published" class="form-check-input" type="checkbox" [(ngModel)]="menuItem.published" />
            </div>
          </div>
          <div class="row mb-1">
            <label for="menuPosition" class="col-6">
              Menu Position:
            </label>
            <div class="col-6 text-end">
              <select id="menuPosition"
                      class="form-control"
                      (change)="updated()"
                      [(ngModel)]="menuItem.menuPosition">
                <option [value]="menuPosition.value"
                        *ngFor="let menuPosition of templateService.menuPositions">
                  {{menuPosition.title}}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-1" *ngIf="!menuTypeDisabled">
            <label for="type" class="col-6">
              Type:
            </label>
            <div class="col-6 text-end">
              <select id="type"
                      class="form-control"
                      [(ngModel)]="menuItem.menuItemType">
                <option value="1">Article</option>
                <option value="2">Component</option>
                <option value="3">External Link</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-12 text-end">
        <a routerLink="/admin/content/menu-items" class=" btn btn-outline-danger">Cancel</a>
        <a class="btn btn-primary" (click)="save()">
          <span class="fa fa-save"></span> Save
        </a>
      </div>
    </div>
  </div>
</div>
