<div (click)="toggleOpen()"
     class="handle">
  <span class="fa"
        [class.fa-chevron-left]="isOpen"
        [class.fa-chevron-right]="!isOpen"></span>
</div>
<div class="medallion-properties">
  <div class="container-fluid" style="height: 100%;">
    <div class="row border-bottom">
      <div class="col-sm p-1 font-weight-bold">Image Editing Tools</div>
      <div class="col-1 text-end">
        <create-help-docs-button [target]="'4DC0780E-20C8-415A-AF62-0E593B7B1F18'"></create-help-docs-button>
      </div>
    </div>
    <div class="properties">
      <div class="container-fluid">
        <div class="d-flex row height-30px align-items-end">
          <label class="col">Type</label>
          <div class="col text-end">
            <select class="form-control border-bottom"
                    (change)="sizeChanged($event)"
                    [(ngModel)]="medallion.size">
              <option [ngValue]="size"
                      *ngFor="let size of medallionSizes">
                {{size.title}}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex row height-30px align-items-end">
          <label class="col">Quantity</label>
          <div class="col text-end">
            <input class="form-control border-bottom"
                   (change)="inputChanged($event)"
                   min="1"
                   type="number"
                   [(ngModel)]="medallion.quantity" />
          </div>
        </div>
        <div class="d-flex row height-30px align-items-end">
          <label class="col">Background Color</label>
          <div class="col text-end">
            <div class="color-sample-container">
              <div id="colorSample"
                   [(colorPicker)]="medallion.backgroundColor"
                   (colorPickerChange)="inputChanged($event)"
                   [cpOutputFormat]="'hex'"
                   [cpPosition]="'left'"
                   [cpPresetColors]="presetColors"
                   [style.background-color]="medallion.backgroundColor"></div>
              <input class="form-control border-bottom"
                     (change)="inputChanged($event)"
                     [(ngModel)]="medallion.backgroundColor" />
            </div>
          </div>
        </div>
        <div class="d-flex row height-30px align-items-end" *ngIf="medallion.size.allowBleed">
          <label class="col">Bleed</label>
          <div class="col text-end">
            <ui-switch [(ngModel)]="medallion.bleeding"
                       (change)="inputChanged($event)"></ui-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid image-container">
      <div class="row border-bottom pt-5">
        <div class="col-sm p-1">
          <div class="float-left font-weight-bold">Images</div>
          <div class="float-right">
            <create-help-docs-button [target]="'947B9BB6-0932-46B5-BDE7-E355BCBFDFD0'"></create-help-docs-button>
          </div>
        </div>
      </div>
      <div>
        <ul cdkDropList
            class="container-fluid example-list"
            (cdkDropListDropped)="drop($event)">
          <li class="example-box row"
              *ngFor="let image of medallion.layout"
              cdkDragLockAxis="y"
              cdkDrag>
            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
            <div class="col-sm p-1 image-button"
                 (click)="setActive(image)"
                 [class.active]="image.active">
              <div class="float-left image-title" [title]="image.imageObject.title">
                <div class="image-thumbnail"
                     [style.background-image]="'url(uploaded-images/'+image.imageObject.url+')'"></div>
                {{image.imageObject.title}}
              </div>
              <div class="btn btn-primary float-right"
                   (click)="removeLayer(image)">
                <span class="fa fa-trash"></span>
              </div>
            </div>
          </li>
          <li class="row">
            <div class="col new-image-button"
                 (click)="openAddImageModal()">
              <span class="fa fa-plus-circle"></span> Add Image
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
