import { Component, OnInit } from "@angular/core";

@Component({
  selector: "create-content-view",
  templateUrl: "./content-view.component.html",
  styleUrls: ["./content-view.component.less"]
})
export class ContentViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
