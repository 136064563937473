import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateImage } from "../../../Models/CreateImage";
import { Location } from "../../../Models/index";
import { CreateRole } from "../../../Models/CreateRole";
import { AuthenticationService } from "../../shared/Services/authentication.service";
import { ImageManagerService } from "../../shared/Services/image-manager.service";

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import * as _ from "underscore";
@Component({
  selector: "create-image",
  templateUrl: "./fh-image.component.html",
  styleUrls: ["./fh-image.component.less"]
})
export class FHImageComponent implements OnInit {
  title: string;
  categories: string[] = [];
  keywords: string[] = [];
  keywordList: string[] = [];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private imageManagerService: ImageManagerService) {
  }

  ngOnInit() {
    this.subscription = this.authenticationService.selectedLocation.subscribe(location => {
      this.location = location;
    });
    const id = +this.route.snapshot.paramMap.get("id");
    this.imageManagerService.selectedTab = this.route.snapshot.paramMap.get("owner");
    this.title = "Edit Image";
    this.image = new CreateImage();
    this.isNew = false;
    if (this.imageManagerService.selectedTab === "batesville") {
        this.imageManagerService.getBatesvilleCategories().subscribe(categories => {
          this.categories = categories;
        });
        this.imageManagerService.getBatesvilleKeywords().subscribe(keywords => {
          this.keywords = keywords;
        });
    } else {
        this.imageManagerService.getLocationCategories(this.authenticationService.selectedLocation.value.id).subscribe(categories => {
          this.categories = categories;
        });
        this.imageManagerService.getLocationKeywords(this.authenticationService.selectedLocation.value.id).subscribe(keywords => {
          this.keywords = keywords;
        });
    }
    if (id === 0) {
      this.isNew = true;
      this.title = "New Image";
      this.image.galleryView = true;
      this.image.isBatesville = false;
      return;
    }
    this.imageManagerService.getImage(id).subscribe(image => {
      this.image = image;
    });

  }
  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 2
        ? []
        : this.categories.filter(v => v != null && v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );
  }
  currentSelectionEnd:number = 0;
  keywordSearch($event: KeyboardEvent) {
    const input: HTMLTextAreaElement = ($event.target) as HTMLTextAreaElement;
    this.currentSelectionEnd = input.selectionEnd;
    if (this.image.keywords.slice(this.currentSelectionEnd - 1, this.currentSelectionEnd).trim() === "") {
      this.keywordList = [];
      return;
    }
    const chunk = this.image.keywords.slice(0, this.currentSelectionEnd);
    const match = chunk.match(/([\s\r\,])\w+/g);
    const lastIndex = match ? chunk.lastIndexOf(match[match.length - 1]) + 1 : 0;
    var word = chunk.slice(lastIndex, this.currentSelectionEnd).trim().toLowerCase();
    if (word.length < 1) {
      this.keywordList = [];
      return;
    }
    this.keywordList = _.uniq(this.keywords.filter(v => v.toLowerCase().indexOf(word) > -1)).slice(0, 10);
  }
  setWord(word) {
    const chunk = this.image.keywords.slice(0, this.currentSelectionEnd);
    const match = chunk.match(/([\s\r\,])\w+/g);
    const lastIndex = match ? chunk.lastIndexOf(match[match.length - 1]) + 1 : 0;
    const startString = this.image.keywords.slice(0, lastIndex);
    const endString = this.image.keywords.slice(this.currentSelectionEnd, this.image.keywords.length);
    this.image.keywords = `${startString}${word}${endString}`;
    this.keywordList = [];

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  imageSet(event: CreateImage) {
    this.image.url = event.url;
  }

  close() {
    switch (this.imageManagerService.selectedTab) {
    case "batesville":
      this.router.navigate(["/images", "images", "batesville"]);
      break;
    case "search":
      this.router.navigate(["/images", "images", "search", this.imageManagerService.searchValue]);
      break;
    default:
      this.router.navigate(["/images", "images", "default"]);
      break;
    }
  }

  save() {
    this.image.locationId = this.location.id;
    if (this.imageManagerService.selectedTab === "batesville" &&
      this.authenticationService.user.role > CreateRole.Default) {
      this.image.locationId = null;
      this.image.isBatesville = true;
    }

    let action;
    if (this.isNew) {
      action = this.imageManagerService.add(this.image);
    } else {
      action = this.imageManagerService.update(this.image);
    }
    action.subscribe(() => {
      this.close();
    });
  }

  isNew: boolean;
  image: CreateImage;
  location: Location;
  subscription;
}
