import { Injectable } from '@angular/core';
import { ZoomLevel } from '../../../Models/ZoomLevel';

@Injectable({
  providedIn: 'root'
})
export class CreateSettingsService {
  zoom: ZoomLevel = { min: .16, max: 4, current: .25 };
  pixelsPerInch = 300;
  constructor() { }
}
