<div class="d-flex m-3" *ngIf="user != null">
  <div class="card card-lg">
    <div class="card-header">
      <h3>Edit User</h3>
    </div>
    <div class="card-body">
      <div class="row p-1">
        <div class="col-3">Id</div>
        <div class="col">{{user.id}}</div>
      </div>
      <div class="row p-1">
        <div class="col-3">Name</div>
        <div class="col">{{user.name}}</div>
      </div>
      <div class="row p-1">
        <div class="col-3">Email</div>
        <div class="col">{{user.email}}</div>
      </div>
      <div class="row p-1">
        <div class="col-3">Role</div>
        <div class="col">
          <select [(ngModel)]="user.role"
                  class="form-control form-select">
            <option *ngFor="let role of userRoles" [value]="role.id">{{role.name}}</option>
          </select>
        </div>
      </div>
      <div class="row p-1" (click)="user.optOut = !user.optOut">
        <div class="col-3">Opt Out</div>
        <div class="col">
          <create-toggle-check [value]="user.optOut"></create-toggle-check>
        </div>
      </div>
    </div>
    <div class="card-footer text-end">
      <div class="btn btn-primary"
           (click)="saveUser()">
        <span class="fa fa-save"></span> Save
      </div>
    </div>
  </div>
</div>
