<div class="card-overlay d-flex text-center"
     *ngIf="selectedImage == image && image.width!=image.height">
  <div class="col m-1 p-1 border background-overlay"
       [class.active]="authentication.user.displayType == 1"
       (click)="setDisplayType(1)">
    <div class="display-overlay">
      <div class="display-sample contain"
           [style.background-image]="'url(/uploaded-images/' + image.url + ')'"></div>
    </div>
    Fit
    <create-help-docs-button [zIndex]="1060" [target]="'A4F66C55-41F3-4ECA-B572-C2AD0E971A84'"></create-help-docs-button>
  </div>
  <div class="col m-1 p-1 border background-overlay"
       [class.active]="authentication.user.displayType == 2"
       (click)="setDisplayType(2)">
    <div class="display-overlay">
      <div class="display-sample cover"
           [style.background-image]="'url(/uploaded-images/' + image.url + ')'"></div>
    </div>
    Maximize
    <create-help-docs-button [zIndex]="1060" [target]="'8F360227-532F-4C92-82BD-CC8C055604B4'"></create-help-docs-button>
  </div>
</div>
<div class="inner-card">
  <div class="deleted-image-overlay" *ngIf="image.deleted"></div>
  <img [alt]="image.title"
       class="card-img-top"
       [src]="'/uploaded-images/' + image.url"/>
  <div class="card-body">
    {{image.title}}
  </div>
</div>
