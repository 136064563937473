import { Component, OnInit } from "@angular/core";
import { CreateImage } from "../../../Models/CreateImage";
import { CreateService } from "../Services/create.service";

@Component({
  selector: "yw-category-image-list-base",
  templateUrl: "./category-image-list-base.component.html",
  styleUrls: ["./category-image-list-base.component.less"]
})
export class CategoryImageListBaseComponent implements OnInit {
  imageUpdated: (image: CreateImage) => void;

  constructor(public createService: CreateService) { }

  ngOnInit() {
  }

  search: string;
}
