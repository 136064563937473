<div class="d-flex justify-content-start flex-wrap">
  <div class="card p-2 m-2 image-upload-container position-relative">
    <div class="card-body">
      <yw-image-upload-container (imageSet)="imageSet($event)"></yw-image-upload-container>
    </div>
  </div>
  <yw-shared-image class="card p-2 m-2 card-image position-relative"
                   (click)="clicked(image)"
                   [class.active]="createService.currentImage == image"
                   *ngFor="let image of images"
                   [image]="image">
  </yw-shared-image>
</div>
