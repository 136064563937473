import { Component, OnInit } from "@angular/core";
import { MenuItem } from "../models/MenuItem";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
import { MenuItemsService } from "../../../shared/Services/menu-items.service";

@Component({
  selector: "create-choose-menu",
  templateUrl: "./choose-menu.component.html",
  styleUrls: ["./choose-menu.component.less"]
})
export class ChooseMenuComponent implements OnInit {

  articles: MenuItem[];
  showAlert = false;
  constructor(public activeModal: NgbActiveModal,
    private menuItemsService: MenuItemsService) {
  }
  toggleSelected(article: MenuItem) {
    let isSelected = !article.isSelected;
    if (isSelected) {
      this.showAlert = false;
    }
    this.articles.forEach(art => art.isSelected = false);
    article.isSelected = isSelected;
  }
  ngOnInit() {
    this.menuItemsService.getMenuItems().subscribe(articles => {
      this.articles = articles;
    });
  }
  ok() {
    let selectedArticle = _.findWhere(this.articles, { isSelected: true });
    if (!selectedArticle) {
      this.showAlert = true;
      return;
    }
    this.activeModal.close(selectedArticle);
  }
}
