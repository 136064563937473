import { Component, OnInit } from "@angular/core";
import { MedallionSize } from "../../../Models/index";
import { ActivatedRoute, Router } from "@angular/router";
import { MedallionService } from "../../shared/Services/medallion.service";

@Component({
  selector: "yw-edit-medallions",
  templateUrl: "./edit-medallions.component.html",
  styleUrls: ["./edit-medallions.component.less"]
})
export class AdminEditMedallionsComponent implements OnInit {
  medallion: MedallionSize = new MedallionSize();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private medallionService: MedallionService) {
    const id = +this.route.snapshot.paramMap.get("id");
    if (id === 0) {
      this.medallionService.addMedallionSize().subscribe(image => {
        this.medallion = image;
      });
      return;
    }
    this.medallionService.getMedallionSize(id).subscribe(image => {
      this.medallion = image;
    });
   
  }
  onSubmit() {
    this.medallionService.updateMedallionSize(this.medallion).subscribe(() => {
      this.router.navigate(["/admin/medallions"]);
    });
  }
  ngOnInit() {
  }

}
