import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Article } from "../models/Article";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { QuillHelper } from "./QuillHelper";
import * as QuillNamespace from "quill";
import BlotFormatter from "quill-blot-formatter";
import { ArticleService } from "../../../shared/Services/article.service";

let Quill: any = QuillNamespace;
Quill.register("modules/blotFormatter", BlotFormatter);

@Component({
  selector: "create-article",
  templateUrl: "./article.component.html",
  styleUrls: ["./article.component.less"]
})
export class ArticleComponent implements OnInit {
  title: string;
  article;
  isNew: boolean = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService,
    private modalService: NgbModal) {
    
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    this.title = "Edit Article";
    this.article = new Article();
    this.isNew = false;
    if (id === "0") {
      this.isNew = true;
      this.title = "New Article";
      return;
    }
    this.articleService.getArticle(id).subscribe(article => {
      this.article = article;
    });
  }
  save() {
    var post;
    if (this.isNew) {
      post = this.articleService.add(this.article);
    } else {
      post = this.articleService.update(this.article);
    }
    post.subscribe(() => {
      this.router.navigate(["admin", "content", "articles"]);
    });
  }
  defaultModules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], 
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], 
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] },{ background: [] }], 
      [{ font: ["Arial","Open Sans"] }],
      [{ align: [] }], 
      ["link", "image", "video"], 
      ["clean"]
    ],
    blotFormatter: {}
  };
  created(quill) {
    QuillHelper.register(this.modalService, quill);
  }

}
