import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { ImageManagerService } from "../../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../../shared/Services/authentication.service";
import { CreateImageCategory } from "../../../shared/Services/CreateImageCategory";

@Component({
  selector: "create-search-image-list",
  templateUrl: "./search-image-list.component.html",
  styleUrls: ["./search-image-list.component.less"]
})
export class SearchImageListComponent implements OnInit {
  searchValue;

  constructor(private route: ActivatedRoute,
    private imageManagerService: ImageManagerService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    setTimeout(() => {
      this.imageManagerService.selectedTab = "search";
    });
    this.routeSubscription = this.route.params.subscribe(params => {
      setTimeout(() => {
        let searchValue = params["searchValue"];
        if (searchValue == null) {
          searchValue = "";
        }
        this.imageManagerService.searchValue = searchValue;
        this.imageManagerService.searchText = searchValue;

        this.authenticationService.selectedLocation.subscribe(location => {
          this.imageManagerService.getImagesSearch(searchValue, location.id)
            .subscribe(categories => {
              this.images = categories;
            });
        });
      });
    });
  }

  searchText;
  routeSubscription: Subscription;
  images: Array<CreateImageCategory> = [];
}
