import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CreateRole } from '../../Models/CreateRole';
import { Router } from '@angular/router';
import { AuthenticationService } from '../shared/Services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService,
  private router: Router) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authenticationService.authenticating) {
      this.router.navigate(['authenticating'], { queryParams: { returnUrl: state.url } });
      return false;
    } else {
      if (this.authenticationService.user.role < CreateRole.Admin) {
        this.router.navigate(['projects']);
        return false;
      }
      return true;
    }
  }
}
