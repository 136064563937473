<div class="modal-header">
  <div class="col p-0">
    <h5 class="modal-title">Select an image</h5>
  </div>
  <div class="col-sm text-center">
    <div class="search">
      <div class="input-group">
        <input class="form-control" [(ngModel)]="search" placeholder="Search" (keydown.enter)="searchClick()"/>
        <div class="input-group-append">
          <button class="btn btn-primary" type="button" (click)="searchClick()"><span class="fa fa-search"></span></button>
        </div>
      </div>
      <create-help-docs-button class="search-help"
                               [target]="'C795A848-FD96-4DB9-9E70-C1970E588303'"
                               [zIndex]="1060"></create-help-docs-button>
    </div>
  </div>
  <div class="col p-0 text-end">
    <button type="button" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss('Close')">
      <span class="fa fa-close"></span>
    </button>
  </div>
</div>
<form #imageForm="ngForm"
      (ngSubmit)="ok()">
  <div class="modal-body">
    <div class="Create-toolbar">
      <div class="Create-toolbar-item"
           *ngFor="let tab of tabs"
           (click)="setActive(tab)">
        <a class="Create-toolbar-link"
           [class.active]="tab.active"
           href="javascript:void(0)">{{tab.title}}</a>
        <create-help-docs-button [target]="tab.helpDoc"
                                 [zIndex]="1060"></create-help-docs-button>
      </div>
    </div>
    <div class="flex-container">
      <div class="image-list-container"
           [class.propertiesOpen]="createService.currentImage.url!=null && !createService.currentImage.isBatesville">
        <ng-template ywInsertion></ng-template>
      </div>
      <div class="property-container"
           [class.propertiesOpen]="createService.currentImage.url!=null && !createService.currentImage.isBatesville">
        <div class="form-group">
          <input id="galleryView"
                 name="galleryView"
                 (change)="imageChanged()"
                 type="checkbox"
                 [(ngModel)]="createService.currentImage.galleryView" />
          <label for="galleryView">Stock Image</label>
          <create-help-docs-button [zIndex]="1060" [target]="'D0DCBC61-4186-4F35-A623-B50B691AD1CD'"></create-help-docs-button>
        </div>
        <div class="form-group" *ngIf="createService.currentImage.galleryView">
          <label for="title">Title</label>
          <input id="title"
                 name="title"
                 autocomplete="off"
                 (change)="imageChanged()"
                 class="form-control border-bottom"
                 [(ngModel)]="createService.currentImage.title" required />
        </div>
        <div class="form-group" *ngIf="createService.currentImage.galleryView">
          <label for="category">Category</label>
          <input id="category"
                 name="category"
                 (change)="imageChanged()"
                 [ngbTypeahead]="searchOptions"
                 class="form-control border-bottom"
                 [(ngModel)]="createService.currentImage.category" required />
        </div>
        <div class="form-group" *ngIf="createService.currentImage.galleryView">
          <label for="keywords">Keywords</label>
          <textarea type="text"
                    class="form-control"
                    (keyup)="keywordSearch($event)"
                    (change)="imageChanged()"
                    [(ngModel)]="createService.currentImage.keywords"
                    id="keywords"
                    name="keywords" ></textarea>
          
          <div class="list-group">
            <a class="list-group-item" (click)="setWord(keyword)" *ngFor="let keyword of keywordList">{{keyword}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <input name="url" type="hidden" [(ngModel)]="createService.currentImage.url" required/>
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close')">Cancel</button>
    <button [disabled]="imageForm.invalid"
            type="submit"
            ngbAutofocus
            class="btn btn-primary">
      Ok
    </button>
  </div>
</form>
