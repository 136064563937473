import { Component, OnInit, Input, OnChanges, SimpleChanges, HostListener, EventEmitter, Output, ViewChild } from "@angular/core";
import * as _ from "underscore";
import * as $ from "jquery";
import { WorkSpaceElement } from "./WorkSpaceElement";
import { WorkspaceOverlayComponent } from "./workspace-overlay/workspace-overlay.component";
import { ZoomLevel } from "../../../../Models/ZoomLevel";
import { MedallionImage } from "../../../../Models/MedallionImage";
import { Rectangle } from "../../../../Models/Rectangle";
import { DragAction } from "../../../../Models/DragAction";
import { CreateCanvasEvent } from "../../../../Models/CreateCanvasEvent";
import { Medallion, MedallionSize, Point } from "../../../../Models/index";
import { CreateSettingsService } from "../../../shared/Services/create-settings.service";
import { CreateService } from "../../../shared/Services/create.service";

@Component({
  selector: "yw-medallion-canvas-html",
  templateUrl: "./medallion-canvas-html.component.html",
  styleUrls: ["./medallion-canvas-html.component.less"]
})
export class MedallionCanvasHtmlComponent implements OnInit, OnChanges {
  @ViewChild(WorkspaceOverlayComponent) workspaceOverlayChild: WorkspaceOverlayComponent;
  @Input() zoom: ZoomLevel;
  @Input() medallion = new Medallion(this.createSettings);
  @Input() size = new MedallionSize();
  @Input() bleeding = false;
  @Output() changed = new EventEmitter();

  @HostListener("window:resize", [])
  resize() {
    this.refresh();
    this.workspace.resetPan();
  }
  constructor(private createSettings: CreateSettingsService,
    private createService: CreateService) {
  }

  refreshOverlay() {
    setTimeout(() => {
        this.refresh();
        this.workspace.resetPan();
        this.workspaceOverlayChild.setElementSize();
      },
      5);
  }

  updateImage() {
    this.workspace.capture(this.medallion);
  }
  imageChanged(event) {
    this.changed.emit(event);
  }

  deleteActiveLayer() {
    var activeLayer: MedallionImage = _.findWhere(this.medallion.layout, { active: true });
    if (activeLayer) {
      this.createService.deleteMedallionImage(activeLayer).subscribe(() => {
        this.medallion.layout = _.without(this.medallion.layout, activeLayer);
        this.changed.emit({ action: "Remove Layer", element: activeLayer });
      });
    }
  }

  ngOnInit() {
    var canvas = $(".outer-container");
    this.workspace = new WorkSpaceElement(canvas, this.createSettings);
  }
  refresh() {
    if (this.size) {
      let canvasWidth: number;
      let canvasHeight: number;
      if (this.bleeding) {
        canvasWidth = this.size.printWidth * this.createSettings.pixelsPerInch;
        canvasHeight = this.size.printHeight * this.createSettings.pixelsPerInch;
      }
      else {
        canvasWidth = this.size.width * this.createSettings.pixelsPerInch;
        canvasHeight = this.size.height * this.createSettings.pixelsPerInch;
      }
      this.canvas = new Rectangle(
        canvasWidth / 2 * -1,
        canvasHeight / 2 * -1,
        canvasWidth,
        canvasHeight);
      var printWidth = this.size.width * this.createSettings.pixelsPerInch;
      var printHeight = this.size.height * this.createSettings.pixelsPerInch;
      this.printContainer = new Rectangle(
        (canvasWidth - printWidth) / 2,
        (canvasHeight - printHeight) / 2,
        printWidth,
        printHeight);
      if (this.workspace) {
        this.workspace.setOffset(new Point(canvasWidth / 2, canvasWidth / 2));
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }
  waitEmit:any;
  updateActive(x: number, y: number) {
    var activeLayer: MedallionImage = _.findWhere(this.medallion.layout, { active: true });
    if (activeLayer) {
      activeLayer.x += (x / this.zoom.current);
      activeLayer.y += (y / this.zoom.current);
      if (this.waitEmit) {
        clearTimeout(this.waitEmit);
      }
      this.waitEmit = setTimeout(() => {
      this.changed.emit({ action:  DragAction.Move, element: activeLayer });
        },
        500);
    }
    else {
      this.workspace.pan.x += (x / this.zoom.current);
      this.workspace.pan.y += (y / this.zoom.current);
    }
  }

  setActive(image) {
    this.medallion.layout.forEach(img => img.active = false);
    if (image) {
      image.active = true;
    }
  }

  getActiveLayer(event: CreateCanvasEvent): MedallionImage {
    var layers = _.sortBy(this.medallion.layout, layer => layer.zIndex).reverse();
    var mousePoint = new Point(event.pageX, event.pageY);
    var activeLayer = _.first(layers.filter(layer => {
      var rect = new Rectangle(layer.x, layer.y, layer.width, layer.height);
      return rect.pointInside(mousePoint);
    }));
    this.setActive(activeLayer);
    return activeLayer;
  }

  @HostListener("mousedown", ["$event"])
  mouseDown(event: MouseEvent) {
    this.workspace.mouseDown(event);
    this.medallion.setActive(null);
  }

  @HostListener("document:mouseup", ["$event"])
  mouseUp(event: MouseEvent) {
    this.workspace.mouseUp();
  }
   
  mouseMove(event: MouseEvent) {
    this.workspace.mouseMove(event);
  }

  @HostListener("wheel", ["$event"])
  panScroll(event: WheelEvent) {
    let stepAmount = 1;
    if (event.shiftKey) {
      stepAmount = 10;
    }
    if (event.ctrlKey) {
      event.preventDefault();
      if (event.deltaY > 0) {
        this.workspace.pan.x -= this.panAmount * stepAmount;
      }
      else if (event.deltaY < 0) {
        this.workspace.pan.x += this.panAmount * stepAmount;
      }
    }
    else if (!event.altKey) {
      if (event.deltaY > 0) {
        this.workspace.pan.y += this.panAmount * stepAmount;
      }
      else if (event.deltaY < 0) {
        this.workspace.pan.y -= this.panAmount * stepAmount;
      }
    }
  }

  @HostListener("document:keydown", ["$event"])
  keyDown(event: KeyboardEvent) {
    let stepAmount = 1;
    if (event.shiftKey) {
      stepAmount = 10;
    }
    switch (event.key) {
      case "ArrowUp":
        this.updateActive(0, -stepAmount);
        break;
      case "ArrowDown":
        this.updateActive(0, stepAmount);
        break;
      case "ArrowLeft":
        this.updateActive(-stepAmount, 0);
        break;
      case "ArrowRight":
        this.updateActive(stepAmount, 0);
        break;
      case "Delete":
        this.deleteActiveLayer();
        break;
    }
  }

  canvas: Rectangle;
  workspace: WorkSpaceElement;
  printContainer: Rectangle;
  panAmount = 53;
}
