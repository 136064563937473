import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ArticleViewComponent } from "../article/article-view.component";
import { ContentViewComponent } from "../content-view.component";
const routes: Routes = [
  {
    path: "content-view",
    component: ContentViewComponent,
    children: [
      { path: "", redirectTo: "article", pathMatch: "full" },
      { path: "article", component: ArticleViewComponent },
      { path: "article/:id", component: ArticleViewComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ContentRouterModule { }
