<div class="medallion-image"
     [style.background-image]="'url(uploaded-images/'+layer.imageObject.url+')'"
     [style.width.px]="width"
     [style.height.px]="height"
     [style.left.px]="x"
     [style.top.px]="y"
     [class.active]="layer.active">
  <div class="mouse-handler"
       (mousedown)="mousedown($event, 1)"></div>
  <div class="handle top-left"
       (mousedown)="mousedown($event, 2)"></div>
  <div class="border-handle top"
       (mousedown)="mousedown($event, 3)"></div>
  <div class="handle top-right"
       (mousedown)="mousedown($event, 4)"></div>
  <div class="border-handle right"
       (mousedown)="mousedown($event, 5)"></div>
  <div class="handle bottom-right"
       (mousedown)="mousedown($event, 6)"></div>
  <div class="border-handle bottom"
       (mousedown)="mousedown($event, 7)"></div>
  <div class="handle bottom-left"
       (mousedown)="mousedown($event, 8)"></div>
  <div class="border-handle left"
       (mousedown)="mousedown($event, 9)"></div>
</div>
