import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { User } from "../../../Models/index";

@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private http: HttpClient) { }

  getUsers(): Observable<User[]> {
    var userRequest = this.http.get<User[]>("/api/users/getAll").pipe(share());
    return userRequest;
  }

  getUser(id: number): Observable<User> {
    var userRequest = this.http.get<User>(`/api/users/getUserById/${id}`).pipe(share());
    return userRequest;
  }

  saveUser(user: User): Observable<User> {
    var userRequest = this.http.post<User>("/api/users/updateUser", user).pipe(share());
    return userRequest;
  }
}
