import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { share } from "rxjs/operators";
import { CreateVideo } from "./CreateVideo";

@Injectable({
  providedIn: "root"
})
export class VideoManagerService {
  videos = new BehaviorSubject<CreateVideo[]>(null);

  constructor(private http: HttpClient) {
  }
  getVideos(): Observable<Array<CreateVideo>> {
    if (this.videos.value == null) {
      this.loadVideos();
    }
    return this.videos;
  }
  private loadVideos(): Observable<Array<CreateVideo>> {
    var request = this.http.get<Array<CreateVideo>>("api/video/getAll").pipe(share());
    request.subscribe(videos => this.videos.next(videos), error => console.log(error));
    return request;
  }
}
