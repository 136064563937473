import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Article } from "../../admin/content/models/Article";
import { ArticleService } from "../../shared/Services/article.service";

@Component({
  selector: "create-article-view",
  templateUrl: "./article-view.component.html",
  styleUrls: ["./article-view.component.less"]
})
export class ArticleViewComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute,
    private articleService: ArticleService) { }


  ngOnInit() {
    this.subscribe = this.route.params.subscribe(params => {
      const id = params["id"];
      this.article = new Article();
      if (id === "0") {
        return;
      }
      this.articleService.getArticle(id).subscribe(article => {
        this.article = article;
      });
    });
  }
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
  subscribe;
  article: Article;
}
