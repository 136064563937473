import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ProjectsComponent, DesignerComponent, NotFoundComponent } from "./Components";
import { AuthenticatingComponent } from "./Components/authenticating/authenticating.component";
import { DefaultComponent } from "./Components/default/default.component";

const routes: Routes = [
  { path: "designer/:id", component: DesignerComponent, data: { page: "designer" } },
  { path: "projects", component: ProjectsComponent, data: { page: "projects" } },
  { path: "authenticating", component: AuthenticatingComponent, data: { page: "authenticating" } },
  { path: "default", component: DefaultComponent, data: { page: "default" } },
  { path: "", redirectTo: "/default", pathMatch: "full" },
  { path: "**", component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
    enableTracing: false,
    useHash: true,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})

export class AppRoutingModule {}

