import { Component, OnInit } from "@angular/core";
import { User } from "../../../Models/index";
import * as _ from "underscore";
import { UserService } from "../../shared/Services/user.service";

@Component({
  selector: "create-user-search",
  templateUrl: "./user-search.component.html",
  styleUrls: ["./user-search.component.less"]
})
export class UserSearchComponent implements OnInit {
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.getUsers().subscribe(users => {
      this.users = users;
      this.filterUsers();
    });
  }
  sort(event: string) {
    this.sortProperty = event;
  }
  filterUsers() {
    let filteredUsers = this.users;
    if (this.search.length > 0) {
      let search = this.search.toLowerCase();
      filteredUsers = _.filter(filteredUsers, user => this.searchUser(search, user));
    }
    this.filteredUsers = filteredUsers;
  }
  searchClicked() {
    this.filterUsers();
  }
  resetSurvey(user: User) {
    user.surveyDate = new Date("1900-01-01T01:00:00");
    this.userService.saveUser(user).subscribe();
    console.log(user);
  }
  searchUser(search: string, user: User) {
    return user.name.indexOf(search) > -1 ||
      user.email.indexOf(search) > -1 ||
      user.id.toString().indexOf(search) > -1;
  }
  filteredUsers: User[];
  users: User[];
  search: string = "";
  sortProperty: string;
  page: number = 0;
}
