<div [id]="document.id">
  <div *ngIf="!document.edit">
    <div class="row border-bottom p-1 m-1 ">
      <div class=" col-10 title font-weight-bold">{{document.title}}</div>
      <div class="col-2 text-end"
           *ngIf="authentication.user.role>=100 && !sorting">
        <div class="btn btn-primary" (click)="document.edit = !document.edit"><span class="fa fa-edit"></span> Edit</div>
      </div>
    </div>
    <div *ngIf="!sorting" class="content p-2" [innerHTML]="document.contents"></div>
  </div>
  <div *ngIf="document.edit && !sorting">
    <div class="row border-bottom p-1 m-1 ">
      <input class="form-control col-9" (change)="changed()" [(ngModel)]="document.title" />
      <div class="col-3 text-end">
        <label>
          Published: <input type="checkbox" (change)="changed()" [(ngModel)]="document.published" />
        </label>
      </div>
    </div>
    <div class="row p-1">
      <div class="col">
        <label for="role">Role</label>
        <select class="form-control" id="role" (change)="changed()" [(ngModel)]="document.role">
          <option *ngFor="let roleOption of roleOptions" [value]="roleOption.id">{{roleOption.name}}</option>
        </select>
      </div>
    </div>
    <div class="p-1">
      <quill-editor (onContentChanged)="changed()" [(ngModel)]="document.contents"></quill-editor>
    </div>
    <div class="btn btn-primary" (click)="document.edit = !document.edit"><span class="fa fa-close"></span> Done Editing</div>
  </div>
</div>
