import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortOptionService {
  currentProperty = 'dateCreated';

  constructor() { }

  ascending = false;
}
