import { Component, OnInit, Input, HostBinding, Output, EventEmitter } from "@angular/core";
import { IProject } from "../../../Interfaces/index";
import { CreateImage, Medallion } from "../../../Models/index";
import { CreateService } from "../../shared/Services/create.service";
import { ImageManagerService } from "../../shared/Services/image-manager.service";

@Component({
  selector: "yw-image-list",
  templateUrl: "./image-list.component.html",
  styleUrls: ["./image-list.component.css"]
})
export class ImageListComponent implements OnInit {
  @Input() project: IProject;
  @HostBinding("class.collapsed") collapsed: boolean = false;

  @Output() doubleClicked = new EventEmitter<CreateImage>();
  constructor(private CreateService: CreateService,
    public imageManager: ImageManagerService) {
  }
  ok(image: CreateImage) {
    this.doubleClicked.emit(image);
  }
  imageClick(image: CreateImage) {
    this.imageManager.setActive(image);
    this.CreateService.currentImage = image;
  }
  ngOnInit() {
    this.imageManager.getImages();
    this.CreateService.currentMedallion.subscribe(medallion => {
      this.medallion = medallion;
    });
  }

  closeResult: string;
  medallion: Medallion;
}
