<form #articleForm="ngForm"
      (ngSubmit)="ok()">
  <div class="modal-header">
    <div class="col p-0">
      <h5 class="modal-title">Choose Media</h5>
    </div>
    <div class="col p-0 text-end">
      <button type="button" class="btn btn-primary" aria-label="Close" (click)="modalService.dismiss('Close')">
        <span class="fa fa-close"></span>
      </button>
    </div>
  </div>
  <div class="modal-body p-2 m-0">
    <div class="form-group border-bottom-light pt-2">
      <label>Media:</label>
      <div>
        <div (click)="addSource()" class="btn btn-primary">
          <span class="fa fa-upload"></span> Add Media
        </div>
        <video #videoPlayer height="200" width="300" class="video-preview" controls="controls" *ngIf="media.fileType != null && media.fileType.startsWith('video')">
          <source [src]="videoUrl" id="video_here" type="video/mp4">
        </video>
        <img *ngIf="media.fileType != null && media.fileType.startsWith('image')"
             style="width: 300px; vertical-align: top;"
             [src]="imageData" />
      </div>
      <input #sourceFile style="display: none" type="file" class="form-control-file" (change)="onChangeSource($event)" name="source" />
    </div>
    <!--<div class="form-group border-bottom-light pt-2" *ngIf="media.fileType != null && media.fileType.startsWith('video')">
      <label>Thumbnail:</label>
      <div>
        <div (click)="addThumbnail()" class="btn btn-primary">
          <span class="fa fa-upload"></span> Add Thumbnail
        </div>
        <img *ngIf="thumbnailData" style="width: 300px; vertical-align: top;"
             [src]="thumbnailData" />
      </div>
      <input #thumbnailFile style="display: none" type="file" class="form-control-file" (change)="onChangeThumbnail($event)" name="thumb" accept="image/*" />
    </div>-->
    <div class="form-group">
      <label>Title:</label>
      <input type="text" class="form-control" [(ngModel)]="media.title" name="title" />
    </div>
    <div class="form-group">
      <label>Subtitle:</label>
      <input type="text" class="form-control" [(ngModel)]="media.subtitle" name="subtitle" />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary"
            (click)="modalService.close('Close')">Cancel</button>
    <button [disabled]="articleForm.invalid"
            type="submit"
            ngbAutofocus
            class="btn btn-primary">Ok</button>
  </div>
</form>
