import { Component, OnInit, Input } from "@angular/core";
import { DisplayType } from "../../../Models/DisplayType";
import { CreateImage } from "../../../Models/index";
import { CreateService } from "../Services/create.service";
import { AuthenticationService } from "../Services/authentication.service";
import { UserService } from "../Services/user.service";

@Component({
  selector: "yw-shared-image",
  templateUrl: "./shared-image.component.html",
  styleUrls: ["./shared-image.component.less"]
})
export class SharedImageComponent implements OnInit {
  @Input() image: CreateImage;
  constructor(public createService: CreateService,
    public authentication: AuthenticationService,
    private userService: UserService) {
  }
  setDisplayType(displayType: DisplayType) {
    this.authentication.user.displayType = displayType;
    this.userService.saveUser(this.authentication.user).subscribe(user => user);
  }
  ngOnInit() {
    var image = new Image();
    image.onload = (img) => {
      this.image.width = image.naturalWidth;
      this.image.height = image.naturalHeight;
    }
    image.src = `/uploaded-images/${this.image.url}`;
    this.createService.selectedImage.subscribe(image => {
      this.selectedImage = image;
    });
  }

  selectedImage: CreateImage;
}
