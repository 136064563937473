import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CreateImage } from "../../../Models/index";
import { Subscription } from "rxjs";
import { ILocation } from "../../../Interfaces/ILocation";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertDialogComponent } from "../../alert-dialog/alert-dialog.component";
import { CreateService } from "../../shared/Services/create.service";
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../shared/Services/authentication.service";

@Component({
  selector: "create-image-upload-container",
  templateUrl: "./fh-image-upload-container.component.html",
  styleUrls: ["./fh-image-upload-container.component.less"]
})
export class FHImageUploadContainerComponent implements OnInit, OnDestroy {
  @ViewChild("file") file;
  @Output() imageSet = new EventEmitter();

  constructor(private createService: CreateService,
    private imageManager: ImageManagerService,
    public authenticationService: AuthenticationService,
    private http: HttpClient,
    private modalService: NgbModal) {
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  clickedImage(currentImage) {
    this.createService.currentImage = currentImage;
  }

  onFilesAdded() {
    this.updateFile(this.file.nativeElement.files[0]);
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
    this.updateFile(event.dataTransfer.files[0]);
    return false;
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.dragOver = true;
  }

  onMouseOut() {
    this.dragOver = false;
  }

  updateFile(file: File) {
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      this.modalService.open(AlertDialogComponent, { size: "sm" });
      return;
    }
    const formData = new FormData();
    formData.append("Image", file, file.name);
    formData.append("locationId", this.location.id.toString());
    this.http.post<CreateImage>("/api/Image/UploadFile", formData)
      .subscribe(data => {
          if (this.imageManager.images) {
            this.imageManager.images.push(data);
          }
          this.imageSet.emit(data);
          this.currentImage = data;
          this.imageUrl = `url(/uploaded-images/${data.url})`;
        },
        error => {
          console.log(error);
        });
  }

  ngOnInit() {
    this.subscription = this.authenticationService.selectedLocation.subscribe(location => this.location = location);
    this.currentImage = this.createService.currentImage;
    console.log(this.currentImage);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscription: Subscription;
  location: ILocation;
  currentImage: CreateImage;
  dragOver: boolean;
  imageUrl: any = null;
}
