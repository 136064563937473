<div class="image-upload-container"
     [class.dragOver]="dragOver"
     (drop)="onDrop($event)"
     (dragover)="onDragOver($event)"
     (mouseout)="onMouseOut()">
  <div class="text-center align-items-center">
    <div>Drop image here</div>
    <div>or</div>
    <div>
      <div class="btn btn-primary" (click)="addFiles()">
        <span class="fa fa-upload"></span> Click here to upload
      </div>
      <input type="file" #file style="display: none" (change)="onFilesAdded()"/>
    </div>
  </div>
</div>
