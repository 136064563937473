import { Component, OnInit } from "@angular/core";

import Quill from "quill"

const parchment = Quill.import("parchment");
const block = parchment.query("block");
block.tagName = "DIV";
Quill.register(block, true);

@Component({
  selector: "create-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.less"]
})
export class ContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
