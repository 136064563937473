import { Component, OnInit } from "@angular/core";
import { Article } from "../models/Article";
import { ArticleService } from "../../../shared/Services/article.service";

@Component({
  selector: "create-articles",
  templateUrl: "./articles.component.html",
  styleUrls: ["./articles.component.less"]
})
export class ArticlesComponent implements OnInit {

  constructor(
    private articleService: ArticleService) {
  }

  articles: Article[] = [];

  ngOnInit() {
    this.articleService.loadArticles().subscribe(articles => {
      this.articles = articles;
    });
  }
  toggleMainPage(article: Article) {
    article.mainPage = !article.mainPage;
    this.articleService.update(article);
  }
  togglePublished(article: Article) {
    article.published = !article.published;
    this.articleService.update(article);
  }
}
