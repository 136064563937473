import { Point } from "./Point";

export class CreateCanvasEvent {
  pageX:number;
  pageY:number;
  shiftKey: boolean;
  ctrlKey: boolean;
  altKey: boolean;
  offset: Point;
  page: Point;
}
