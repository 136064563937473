import { IUser } from "../Interfaces/user";
import { ILocation } from "../Interfaces/ILocation";
import { CreateRole } from "./CreateRole";
import { DisplayType } from "./DisplayType";

export class User implements IUser {
  defaultLocationId: number;
  locations: ILocation[];
  id: number;
  name: string;
  email: string;
  locationId: number;
  role: CreateRole = CreateRole.Default;
  displayType: DisplayType = 1;
  optOut: boolean = false;
  surveyDate: Date = new Date("1900-01-01T01:00:00");
}
