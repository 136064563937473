import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "create-alert-dialog",
  templateUrl: "./alert-dialog.component.html",
  styleUrls: ["./alert-dialog.component.less"]
})
export class AlertDialogComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, ) {
  }


  ngOnInit() {
  }

}
