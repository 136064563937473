<ul class="pagination" 
    *ngIf="numberOfPages>1">
    <li class="page-item"
        (click)="setPage(page-1, $event)"
        *ngIf="page > 1">
        <span class="fa fa-angle-left"></span>
    </li>
    <li class="page-item"
        *ngIf="displayPages[0]>1"
        (click)="setPage(1, $event)">
        <a href="javascript:void(0)" class="page-link">1</a>
    </li>
    <li class="page-item"
        *ngIf="displayPages[0]>2">
        <a href="javascript:void(0)" class="page-link">...</a>
    </li>
    <li class="page-item"
        (click)="setPage(pageId, $event)"
        [class.active]="pageId == page"
        *ngFor="let pageId of displayPages">
        <a href="javascript:void(0)" class="page-link">{{pageId}}</a>
    </li>
    <li class="page-item"
        *ngIf="numberOfPages - displayPages[displayPages.length - 1] > 0">
        <a href="javascript:void(0)" class="page-link">...</a>
    </li>
    <li class="page-item"
        *ngIf="numberOfPages - displayPages[displayPages.length - 1] > 0"
        (click)="setPage(numberOfPages, $event)">
        <a href="javascript:void(0)" class="page-link">{{numberOfPages}}</a>
    </li>
    <li class="page-item"
        (click)="setPage(page+1, $event)"
        *ngIf="page < numberOfPages">
        <span class="fa fa-angle-right"></span>
    </li>
</ul>

