import { Component, OnInit, ViewChild, Output, EventEmitter, Input, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CreateImage } from "../../../Models/index";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertDialogComponent } from "../../alert-dialog/alert-dialog.component";
import { CreateService } from "../../shared/Services/create.service";
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../shared/Services/authentication.service";
import { Location } from "../../../Models/Location";
@Component({
  selector: "yw-image-upload-container",
  templateUrl: "./image-upload-container.component.html",
  styleUrls: ["./image-upload-container.component.less"]
})
export class ImageUploadContainerComponent implements OnInit, OnDestroy {
  @ViewChild("file") file;
  @Output() imageSet = new EventEmitter();
  @Input() isBatesville = false;
  @Input() overlayImage = false;
  constructor(private createService: CreateService,
    private imageManager: ImageManagerService,
    public authenticationService: AuthenticationService,
    private http: HttpClient,
    private modalService: NgbModal) {
  }

  addFiles() {
    this.file.nativeElement.click();
  }
  clickedImage(currentImage) {
    this.createService.currentImage = currentImage;
  }
  onFilesAdded() {
    for (var i = 0; i < this.file.nativeElement.files.length; i++) {
      let file = this.file.nativeElement.files[i];
      this.updateFile(file);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = false;
    for (var i = 0; i < event.dataTransfer.files.length; i++) {
      let file = event.dataTransfer.files[i];
      this.updateFile(file);
    }
    return false;
  }

  onDragOver(event) {
    event.stopPropagation();
    event.preventDefault();
    this.dragOver = true;
  }

  onMouseOut() {
    this.dragOver = false;
  }

  updateFile(file: File) {
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      this.modalService.open(AlertDialogComponent, { size: "sm" });
      return;
    }
    const formData = new FormData();
    formData.append("Image", file, file.name);
    formData.append("isBatesville", this.isBatesville ? "true" : "false");
    formData.append("projectId", this.createService.currentProject.id);
    if (!this.isBatesville) {
      formData.append("locationId", this.location.id.toString());
    }
    this.http.post<CreateImage>("/api/Image/Upload", formData)
      .subscribe(data => {
          if (this.imageManager.images) {
            this.imageManager.images.push(data);
          }
          this.imageSet.emit(data);
          this.currentImage = data;
          this.createService.currentImage = data;
          this.createService.selectedImage.next(data);
          this.imageUrl = `url(/uploaded-images/${data.url})`;
        },
        error => {
          console.log(error);
        });
  }

  ngOnInit() {
    this.subscription = this.authenticationService.selectedLocation.subscribe(location => this.location = location);
    this.currentImage = this.createService.currentImage;
    console.log(this.currentImage);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  subscription:Subscription;
  location:Location;
  currentImage: CreateImage;
  dragOver: boolean;
  imageUrl: any = null;
}
