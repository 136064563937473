import { Component, OnInit, Input, ElementRef, HostListener, EventEmitter, Output, OnChanges, SimpleChanges
} from "@angular/core";
import * as $ from "jquery";
import { ZoomLevel } from "../../../../Models/ZoomLevel";
import { CreateSettingsService } from "../../../shared/Services/create-settings.service";

@Component({
  selector: "yw-zoom-controller",
  templateUrl: "./zoom-controller.component.html",
  styleUrls: ["./zoom-controller.component.css"]
})
export class ZoomControllerComponent implements OnInit, OnChanges {
  @Input() zoom: ZoomLevel;
  @Output() zoomChanged = new EventEmitter();

  constructor(private element: ElementRef,
    private createSettings: CreateSettingsService) { }
  @HostListener("body:mouseup", ["$event"])
  mouseUp() {
    this.dragZoom = false;
  }

  zoomHandleMouseDown() {
    this.dragZoom = true;
  }

  updateZoom() {
    if (this.createSettings.zoom.current !== this.zoom.current ||
      this.createSettings.zoom.min !== this.zoom.min ||
      this.createSettings.zoom.max !== this.zoom.max) {
      this.createSettings.zoom = ({ max: this.zoom.max, min: this.zoom.min, current: this.zoom.current } as ZoomLevel);
    }
  }

  updatePercentageDisplay(percentage) {
    this.zoomLevelPercentage = ((percentage * 100) * this.zoom.max).toFixed(1) + "%";
  }
  mousemove(event) {
    if (this.dragZoom) {
      let element = $(this.element.nativeElement);
      let zoomSlideBar = element.find("#zoomSlideBar");
      let totalWidth = zoomSlideBar.width();
      let newX = event.pageX - zoomSlideBar.offset().left;
      if (newX < 0 ) {
        newX = 0;
      }
      else if (newX > totalWidth) {
        newX = totalWidth;
      }
      let percentage = newX / totalWidth;
      percentage = Math.max(percentage, this.zoom.min * 100 / (100 * this.zoom.max));
      this.updatePercentageDisplay(percentage);
      this.zoom.current = percentage * this.zoom.max;
      this.setHandle(newX);
      event.preventDefault();
    }
  }

  setHandle(position: number) {
    let element = $(this.element.nativeElement);
    let zoomHandle = element.find("#zoomSlideHandle");
    let offsetAmount = (zoomHandle.width() / 2);
    this.sliderLeft = position - offsetAmount;

    this.updateZoom();
  }
  setZoomLevel(changeAmount: number) {
    this.zoom.current += changeAmount;
    if (this.zoom.current > this.zoom.max) {
      this.zoom.current = this.zoom.max;
    }
    else if (this.zoom.current < this.zoom.min) {
      this.zoom.current = this.zoom.min;
    }

    let percentage = this.zoom.current * 100 / (100 * this.zoom.max);
    this.setByPercentage(percentage);
  }
  setByPercentage(percentage) {
    let element = $(this.element.nativeElement);
    let zoomSlideBar = element.find("#zoomSlideBar");
    let totalWidth = zoomSlideBar.width();
    this.updatePercentageDisplay(percentage);
    this.setHandle(totalWidth * percentage);

  }
  changePercentage() {
    this.zoom.current = parseFloat(this.zoomLevelPercentage) / 100;
    var percentage = this.zoom.current / this.zoom.max;
    this.setByPercentage(percentage);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setZoomLevel(0);
  }
  ngOnInit() {
    this.setZoomLevel(0);
  }
  sliderLeft: number;
  dragZoom = false;
  zoomLevelPercentage: string;
}
