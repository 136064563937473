import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { CreateImageCategory } from "../../../shared/Services/CreateImageCategory";
import { ImageManagerService } from "../../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../../shared/Services/authentication.service";
import { CreateRole } from "../../../../Models/CreateRole";

@Component({
  selector: "create-batesville-image-list",
  templateUrl: "./batesville-image-list.component.html",
  styleUrls: ["./batesville-image-list.component.less"]
})
export class BatesvilleImageListComponent implements OnInit, OnDestroy {
  images: Array<CreateImageCategory> = [];

  constructor(
    private imageManagerService: ImageManagerService,
    public authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.imageManagerService.selectedTab = "batesville";
      this.imageManagerService.searchValue = "";
      this.imageManagerService.searchText = "";
    });
    this.subscriptions.push(this.imageManagerService.showDeleted.subscribe(deleted => {
      if (this.authenticationService.user.role < CreateRole.Admin) {
        deleted = false;
      }
      this.subscriptions.push(this.imageManagerService.getBatesvilleImages(deleted).subscribe(images => {
        this.images = images;
      }));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscriptions = new Array<Subscription>();
}
