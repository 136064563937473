import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ILocation, IUser} from "../../Interfaces/index";
import { CreateService } from "../shared/Services/create.service";
import { AuthenticationService } from "../shared/Services/authentication.service";
import { Observable } from "rxjs";
import * as _ from 'underscore';

@Component({
  selector: "app-create-header",
  templateUrl: "./create-header.component.html",
  styleUrls: ["./create-header.component.less"]
})
export class CreateHeaderComponent implements OnInit, OnDestroy {
  locationList: any[] = [];
  locationSearch: string = '';
  filteredLocations: any[];
  page = 1;
  pageSize = 10;
  subscription;
  selectedLocation: ILocation;
  ngOnInit(): void {

    //  this.authenticationService.getUser().subscribe(data => {
        this.filteredLocations = this.authenticationService.user.locations;
    this.locationList = this.authenticationService.user.locations;
     // });
 
    this.filterLocations();
    this.subscription = this.authenticationService.selectedLocation.subscribe(location => {
      this.selectedLocation = location;
    });
  //YW - 132 - Add return to Batesville.com button
    this.subscription2 = this.createService.appSettings.subscribe(Url => {
      if (Url == null)
        return
      this.returnUrl = Url.url; console.log(Url);
  //YW - 132 - Add return to Batesville.com button
    })
  }
  userSearch = "";
    //YW - 132 - Add return to Batesville.com button
  subscription2: any;
  returnUrl = "";
  updateSearch() {

  }
  cancelBubble($event: Event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
      //YW - 132 - Add return to Batesville.com button
    this.subscription2.unsubscribe();
  }

  constructor(private router: Router,
    public createService: CreateService,
    public authenticationService: AuthenticationService) {
    console.log("abc",authenticationService.user.locations)
  }

  selectLocation(location) {
    this.authenticationService.selectedLocation.next(location);
  }

  clickInput($event:MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }
  filterLocations() {
    var search = this.locationSearch.toLowerCase();
    this.page = 1;
    this.filteredLocations = _.filter(this.locationList,
      location => location.name.toLowerCase().indexOf(search) > -1 ||
        location.id.toString().indexOf(search) > -1);
  } 
}
