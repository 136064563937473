import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import * as _ from "underscore";
import { MenuItemsService } from "../shared/Services/menu-items.service";

@Component({
  selector: "create-default",
  templateUrl: "./default.component.html",
  styleUrls: ["./default.component.less"]
})
export class DefaultComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(private menuItemsService: MenuItemsService) {}

  ngOnInit() {
    this.subscription = this.menuItemsService.menuItems.subscribe(menuItems => {
      if (menuItems == null) return;
      const defaultMenuItem = _.findWhere(menuItems, { default: true });
      this.menuItemsService.navigate(defaultMenuItem);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
