export enum DragAction {
  None = 0,
  Move,
  ScaleTopLeft,
  ScaleTop,
  ScaleTopRight,
  ScaleRight,
  ScaleBottomRight,
  ScaleBottom,
  ScaleBottomLeft,
  ScaleLeft
}
