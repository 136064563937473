import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { DisplayType } from "../../../Models/DisplayType";
import { CreateImageCategory } from "../Services/CreateImageCategory";
import { CreateService } from "../Services/create.service";
import { AuthenticationService } from "../Services/authentication.service";
import { UserService } from "../Services/user.service";

@Component({
  selector: "yw-category-image-list",
  templateUrl: "./category-image-list.component.html",
  styleUrls: ["./category-image-list.component.less"]
})
export class CategoryImageListComponent implements OnInit {
  @Input() categories: CreateImageCategory;
  @Output() clicked = new EventEmitter();
  constructor(public createService: CreateService,
    public authentication: AuthenticationService,
    private userService: UserService) { }
  clickedImage(image) {
    this.clicked.emit(image);
  }
  setDisplayType(displayType: DisplayType) {
    this.authentication.user.displayType = displayType;
    this.userService.saveUser(this.authentication.user).subscribe(user => user);
  }
  ngOnInit() {
  }

}
