import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as _ from "underscore";
import { CreateImageCategory } from "./CreateImageCategory";
import { Observable, BehaviorSubject } from "rxjs";
import { share } from "rxjs/operators";
import { CreateImage } from "../../../Models/index";

@Injectable({
  providedIn: "root"
})

export class ImageManagerService {  
 
 deleted = false;
  images:  CreateImage[];
  categories: Set<string> = new Set<string>();
  
  showDeleted = new BehaviorSubject<boolean>(false);
  searchValue = "";
  searchText = "";
  selectedTab = "default";
  batesvilleImages: CreateImageCategory[];

  constructor(private http: HttpClient) {
  }

  updateImageCategories() {
    this.images.forEach(image => {
      this.categories.add(image.category);
    });
  }

  add(createImage: CreateImage): BehaviorSubject<CreateImage> {
    var result = new BehaviorSubject(new CreateImage());
    this.http.post<CreateImage>("api/image/post", createImage).subscribe(image => {
        if (_.any(this.images, i => i.id === image.id)) {
          let img: CreateImage = _.findWhere(this.images, {id:image.id});
          img.url = image.url;
          img.category = image.category;
          img.title = image.title;
          img.isBatesville = image.isBatesville;
          img.locationId = image.locationId;
          img.galleryView = image.galleryView;
        }
        else {
          if (!this.images) {
            this.images = [];
          }
          this.images.push(image);
        }
        this.updateImageCategories();
        result.next(image);
      },
      error => {
        result.error(error);
      });
    return result;
  }
  put(createImage: CreateImage): Observable<CreateImage> {
    let result = this.http.post<CreateImage>("api/image/put", createImage).pipe(share());
    result.subscribe(image => {},error => {});
    return result;
  }

  update(createImage: CreateImage): Observable<CreateImage> {
    var result = this.http.post<CreateImage>("api/image/update", createImage).pipe(share());
    return result;
  }
  getImages() {
    this.http.get<Array<CreateImage>>("api/image").subscribe(images => {
      this.images = images;
      this.updateImageCategories();
    });
  }

  setActive(image: CreateImage) {
    if (this.images) {
      this.images.forEach(i => i.active = false);
    }
    if (image) {
      image.active = true;
    }
  }

  getBatesvilleImages(deleted: boolean):Observable<Array<CreateImageCategory>> {
    var request = this.http.get<Array<CreateImageCategory>>(`api/image/getBatesville?showDeleted=${deleted}`).pipe(share());
    request.subscribe(images => this.batesvilleImages = images, error => console.log(error));
    return request;
  }

  getImage(id: number): Observable<CreateImage> {
    var request = this.http.get<CreateImage>(`api/image/getImage/${id}`).pipe(share());
    request.subscribe(image => console.log(image), error => console.log(error));
    return request;
  }

  getImagesSearch(search: string, id: number): Observable<Array<CreateImageCategory>> {
    var request = this.http.get<Array<CreateImageCategory>>(`api/image/getSearchImages?locationId=${id}&search=${search}`).pipe(share());
    request.subscribe(images => this.batesvilleImages = images, error => console.log(error));
    return request;
  }
  delete(id: number): Observable<Array<CreateImageCategory>> {
    var request = this.http.delete<Array<CreateImageCategory>>(`api/image/delete/${id}`).pipe(share());
    request.subscribe(() => {}, error => console.log(error));
    return request;
  }
  getLocationImages(id: number, showDelete: boolean = false) {
    if (id == null) return new Observable<Array<CreateImageCategory>>();
    var request = this.http.get<Array<CreateImageCategory>>(`api/image/getLocationImages/${id}?showDeleted=${showDelete}`).pipe(share());
    request.subscribe(images => this.batesvilleImages = images, error => console.log(error));
    return request;
  }

  getProjectImages(id: string): Observable<Array<CreateImage>> {
    var request = this.http.get<Array<CreateImage>>(`api/image/getProjectImages/${id}`).pipe(share());
    return request;
  }

  restore(id: number): Observable<CreateImage> {
    var request = this.http.get<CreateImage>(`api/image/restore/${id}`).pipe(share());
    request.subscribe(() => { }, error => console.log(error));
    return request;
  }

  saveOrdering(items) {
    var request = this.http.post("/api/image/saveOrdering", items).pipe(share());
    request.subscribe(() => { }, error => console.log(error));
    return request;
  }

  getBatesvilleCategories(): Observable<string[]> {
    var request = this.http.get<string[]>(`api/image/batesvilleCategories`).pipe(share());
    request.subscribe(() => { }, error => console.log(error));
    return request;
  }

  getLocationCategories(id: number): Observable<string[]> {
    var request = this.http.get<string[]>(`api/image/locationCategories/${id}`).pipe(share());
    request.subscribe(() => { }, error => console.log(error));
    return request;
  }

  getBatesvilleKeywords(): Observable<string[]> {
    var request = this.http.get<string[]>(`api/image/getBatesvilleKeywords`).pipe(share());
    request.subscribe(() => { }, error => console.log(error));
    return request;
  }

  getLocationKeywords(id: number): Observable<string[]> {
    var request = this.http.get<string[]>(`api/image/getLocationKeywords/${id}`).pipe(share());
    request.subscribe(() => { }, error => console.log(error));
    return request;
  }
}
