import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "create-toggle-check",
  templateUrl: "./toggle-check.component.html",
  styleUrls: ["./toggle-check.component.less"]
})
export class ToggleCheckComponent implements OnInit {
  @Input() value: boolean;
  @Input() displayType: "check"|"star"|"radio" = "check";
  constructor() { }

  ngOnInit() {
  }

}
