import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../../../Models/index";
import * as _ from "underscore";
import { UserService } from "../../shared/Services/user.service";
import { AuthenticationService } from "../../shared/Services/authentication.service";

@Component({
  selector: "create-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.less"]
})
export class UserEditComponent implements OnInit {
  user: User;
  userRoles = [{ name: "Basic User", id: 0 }, { name: "Admin", id: 100 }, { name: "User Admin", id: 150 }, { name: "QA Admin", id: 150 }, { name: "System Admin", id: 1000 }];
  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get("id");
    if (id === 0) {
      this.user = new User();
      return;
    }
    this.userService.getUser(id).subscribe(user => {
      this.user = user;
    });
    this.userRoles = _.filter(this.userRoles, role => this.valueRole(role));
  }

  valueRole(role):boolean {
    if (this.authenticationService.user.role < 1000) {
      return role.id < this.authenticationService.user.role;
    }
    return true;
  }
  saveUser() {
    this.userService.saveUser(this.user).subscribe(() => {
      this.router.navigate(["admin", "userSearch"]);
    });
  }
}
