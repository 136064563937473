import { Component, OnInit, OnDestroy } from "@angular/core";
import { IProject } from "../../Interfaces/index";
import { Location, Project } from "../../Models/Index";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CreateService } from "../shared/Services/create.service";
import { AuthenticationService } from "../shared/Services/authentication.service";
import { ApplicationService } from "../shared/Services/application-service.service";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html", 
  styleUrls: ["./projects.component.css"],
  host: { "id": "project-container" }
})
export class ProjectsComponent implements OnInit, OnDestroy {
  subscription;
  unfilteredProjects: IProject[] = [];
  projects: IProject[] = [];
  loading = true;
  constructor(private createService: CreateService,
    public authenticationService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private applicationService: ApplicationService) {
  }

  ngOnInit() {
    this.locationSubscription = this.authenticationService.selectedLocation.subscribe(location => {
      if (location == null) {
        return;
      }
      this.createService.getProjects(location);
      this.currentLocation = location;
    });
    this.subscription = this.createService.projects$
      .subscribe(projects => {
        this.loading = true;
        this.unfilteredProjects = projects;
        this.loadFilteredProjects();
      });
    this.applicationService.condensedFooter = false;
    this.authenticationService.changeLocationAllowed = true;
  }
  copy(id: string) {
    this.createService.copyProject(id, this.currentLocation).subscribe(project => {
      this.router.navigate(["designer", project.id]);
    });
  }
  onSelect(project: Project) {
    const isSelected = !project.isSelected;
    this.projects.forEach((pro: Project) => pro.isSelected = false);
    project.isSelected = isSelected;
  }
  delete(id: string) {
    const modalRef = this.modalService.open(ConfirmDialogComponent, { size: "sm" });
    modalRef.componentInstance.title = "Delete Order?";
    modalRef.componentInstance.message = "Do you want to delete this order?";
    modalRef.componentInstance.btnOkText = "Yes";
    modalRef.componentInstance.btnCancelText = "No";
    modalRef.result.then(confirm => {
      if (confirm) {
        this.createService.deleteProject(id).subscribe((project) => {
          if (project == null) {
            return;
          }
          this.projects = _.without(this.projects, project);
        });
      }
    },() => {});
    
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.locationSubscription.unsubscribe();
  }
  sort(sortEvent) {
    this.sortOption = sortEvent;
    this.loadFilteredProjects();
  }
  newProject() {
    this.createService.getNewProject(this.currentLocation).subscribe(project => {
      this.authenticationService.changeLocationAllowed = false;
      this.router.navigate(["designer", project.id]);
    });
  }
  searchClick(search: string) {
    this.searchValue = search.toLowerCase();
    this.loadFilteredProjects();
  }
  keyDownFunction(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.searchClick(this.search);
    }
  }
  searchProject(project: IProject):boolean {
    if (this.searchValue === "") {
      return true;
    }
    return project.title.toLowerCase().indexOf(this.searchValue) > -1 ||
      project.user.name.toLowerCase().indexOf(this.searchValue) > -1;
  }
  loadFilteredProjects() {
    var searchedProjects = _.filter(this.unfilteredProjects, project => this.searchProject(project));
    this.projects = _.sortBy(searchedProjects, [this.sortOption.property]);
    let asc = this.sortOption.ascending;
    if (this.sortOption.property === "completed") {
      asc = !asc;
    }
    if (!asc) {
      this.projects = this.projects.reverse();
    }

    this.loading = false;
  }
  search="";
  locationSubscription;
  currentLocation: Location;
  sortOption: any = { property: "dateCreated", ascending: false };
  searchValue: string = "";

}
