<div class="card">
  <div class="card-header">
    <div class="row p-0 m-2">
      <div class="col text-center">
        <div class="input-group search">
          <input class="form-control" [(ngModel)]="search" placeholder="Search" (keydown.enter)="searchClicked()"/>
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" (click)="searchClicked()" style="height:30px"><span class="fa fa-search"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <table class="table table-bordered table-hover table-striped">
      <thead>
        <tr>
          <td style="width: 100px">Id <yw-sort-option (sort)="sort($event)" [sortProperty]="'id'"></yw-sort-option></td>
          <td>Name <yw-sort-option (sort)="sort($event)" [sortProperty]="'name'"></yw-sort-option></td>
          <td>Email <yw-sort-option (sort)="sort($event)" [sortProperty]="'email'"></yw-sort-option></td>
          <td>Locations</td>
          <td style="width: 200px">Action</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of filteredUsers | paginate: { itemsPerPage: 10, currentPage: page }">
          <td>
            <a [routerLink]="['../edit-user/', user.id]">{{user.id}}</a>
          </td>
          <td>
            <a [routerLink]="['../edit-user/', user.id]">{{user.name}}</a>
          </td>
          <td>
            <a [routerLink]="['../edit-user/', user.id]">{{user.email}}</a>
          </td>
          <td>
            <div class="max-height-50">
              <div *ngFor="let location of user.locations">
                {{location.id}} - {{location.name}}
              </div>
            </div>
          </td>
          <td>
            <a class="btn btn-primary" (click)="resetSurvey(user)">
              <span class="fa fa-refresh"></span> Reset Survey
            </a>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5" class="text-center">
            <pagination-controls (pageChange)="page = $event"></pagination-controls>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
