import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { MediaElement } from "../choose-video/MediaElement";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
import { DomSanitizer } from "@angular/platform-browser";
import { MediaService } from "../../../shared/Services/media.service";

@Component({
  selector: "create-media-edit",
  templateUrl: "./media-edit.component.html",
  styleUrls: ["./media-edit.component.less"]
})
export class MediaEditComponent implements OnInit {
  @Input() media: MediaElement;
  @ViewChild("sourceFile") sourceFile;
  @ViewChild("thumbnailFile") thumbnailFile;
  @ViewChild("videoPlayer") videoPlayer;
  constructor(
    public modalService: NgbActiveModal,
    private mediaService: MediaService,
    private domSanitizer: DomSanitizer) { }

  addSource() {
    this.sourceFile.nativeElement.click();
  }
  onChangeSource($event) {
    var file: File = _.first(this.sourceFile.nativeElement.files);
    console.log(file);
    this.media.fileType = file.type;
    if (file.type.startsWith("image")) {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith("video")) {
      this.videoUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
      if (this.videoPlayer != null) {
        this.videoPlayer.nativeElement.load();
      }
    }
    if (this.media.title === "" || this.media.title == null) {
      this.media.title = file.name;
    }
  }

  addThumbnail() {
    this.thumbnailFile.nativeElement.click();
  }
  onChangeThumbnail($event) {
    var file: File = _.first(this.thumbnailFile.nativeElement.files);
    if (file.type.startsWith("image")) {
      var reader = new FileReader();
      reader.onload = (e: any) => {
        this.thumbnailData = e.target.result;
      };
      reader.readAsDataURL(file);
    } 
  }
  ngOnInit() {
    if (this.media == null) {
      this.media = new MediaElement();
    }
  }
  loadMedia(media: MediaElement) {
    this.media = media;
    console.log(this.media);
    this.thumbnailData = this.domSanitizer.bypassSecurityTrustUrl(media.thumb);
    this.videoUrl = this.domSanitizer.bypassSecurityTrustUrl(media.source);
    if (this.videoPlayer != null) {
      this.videoPlayer.nativeElement.load();
    }
  }
  post(mediaElement: MediaElement) {
    if (mediaElement.id === 0 || mediaElement.id == null) {
      this.mediaService.putMediaElement(mediaElement).subscribe(() => {
        this.modalService.close(mediaElement);
      });
    } else {
      this.mediaService.postMediaElement(mediaElement).subscribe(() => {
        this.modalService.close(mediaElement);
      });
    }
  }
  postThumb(mediaElement: MediaElement) {
    if (this.thumbnailFile!=null && _.first(this.thumbnailFile.nativeElement.files) != null && this.media.fileType.startsWith("video")) {
      this.mediaService.putMedia(_.first(this.thumbnailFile.nativeElement.files)).subscribe(thumbnail => {
        mediaElement.thumb = thumbnail;
        this.post(mediaElement);
      });

    } else {
      mediaElement.thumb = this.media.thumb;
      this.post(mediaElement);
    }
  }
  ok() {
    var newMediaElement = new MediaElement();
    newMediaElement.id = this.media.id;
    newMediaElement.fileType = this.media.fileType;
    newMediaElement.title = this.media.title;
    newMediaElement.subtitle = this.media.subtitle;
    if (_.first(this.sourceFile.nativeElement.files) != null) {
      this.mediaService.putMedia(_.first(this.sourceFile.nativeElement.files)).subscribe(path => {
        newMediaElement.source = path;
        this.postThumb(newMediaElement);
      });
    } else {
      newMediaElement.source = this.media.source;
      this.postThumb(newMediaElement);
    }
  }
  imageData = "";
  videoUrl;
  thumbnailData;

}
