import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SortOptionService } from "../Services/sort-option.service";

@Component({
  selector: "yw-sort-option",
  templateUrl: "./sort-option.component.html",
  styleUrls: ["./sort-option.component.less"]
})
export class SortOptionComponent implements OnInit {

  @Output() sort = new EventEmitter();
  @Input() sortProperty: string;
  constructor(public sortOptionService: SortOptionService) { }

  ngOnInit() {
  }
  clicked() {
    if (this.sortOptionService.currentProperty === this.sortProperty) {
      this.sortOptionService.ascending = !this.sortOptionService.ascending;
    }
    else {
      this.sortOptionService.currentProperty = this.sortProperty;
      this.sortOptionService.ascending = false;
    }
    this.sort.emit({ property: this.sortOptionService.currentProperty, ascending: this.sortOptionService.ascending});
  }
}
