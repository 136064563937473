<form #articleForm="ngForm"
      (ngSubmit)="ok()">
  <div class="modal-header">
    <div class="col p-0">
      <h5 class="modal-title">Choose Media</h5>
    </div>
    <div class="col p-0 text-end">
      <button type="button"
              class="btn btn-primary"
              aria-label="Close"
              (click)="activeService.dismiss('Close')">
        <span class="fa fa-close"></span>
      </button>
    </div>
  </div>
  <div class="modal-body p-2 m-0">
    <ul class="video-options">
      <li class="add-button" (click)="addMedia()">
        <div class="icon">
          <span class="fa fa-plus-circle"></span>
        </div>
        <div>Add Media</div>
      </li>
      <li class="video-option {{media.fileType.replace('/', '-')}}"
          [class.active]="media.isSelected"
          (click)="toggleMedia(media)"
          *ngFor="let media of medium | paginate: { itemsPerPage: 8, currentPage: page }">
        <div class="edit-button" (click)="editMedia(media)">
          <span class="fa fa-edit"></span> edit
        </div>
        <div *ngIf="media.fileType.startsWith('video')"
             class="thumbnail">
          <video style="width: 100%">
            <source [src]="media.source + '#t=150'"  [type]="media.fileType"/>
          </video>
        </div>
        <div *ngIf="media.fileType.startsWith('image')"
             class="thumbnail"
             [style.background-image]="'url('+media.source+')'"></div>

        <div *ngIf="!media.fileType.startsWith('image') && !media.fileType.startsWith('video')"
             class="thumbnail">
          <span class="fa fa-file-o"></span>
        </div>

        <div class="title">{{media.title}}</div>
        <div class="subtitle">{{media.subtitle}}</div>
      </li>
    </ul>
    <div class="text-center">
      <pagination-controls (pageChange)="page = $event"></pagination-controls>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-primary"
            (click)="activeService.close('Close')">Cancel</button>
    <button [disabled]="articleForm.invalid"
            type="submit"
            ngbAutofocus
            class="btn btn-primary">Ok</button>
  </div>
</form>
