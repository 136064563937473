import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContentRouterModule } from "./router/content-view-router.module";
import { ArticleViewComponent } from "./article/article-view.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ContentViewComponent } from "./content-view.component";
import { SharedModule } from "../shared/shared.module";
import { MenuComponent } from "../../Components/content/menu/menu.component";
import { MenuViewItemComponent } from "../../Components/content/menu-view-item/menu-view-item.component";

@NgModule({
    declarations: [
        ContentViewComponent,
        ArticleViewComponent,
        MenuComponent,
        MenuViewItemComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        NgbModule,
        ContentRouterModule
    ],
    exports: [MenuComponent]
})
export class ContentViewModule { }
