import { Component, OnInit } from "@angular/core";
import { CreateImage } from "../../../Models/CreateImage";
import { ActivatedRoute, Router } from "@angular/router";
import { ImageManagerService } from "../../shared/Services/image-manager.service";

@Component({
  selector: "create-restore",
  templateUrl: "./restore.component.html",
  styleUrls: ["./restore.component.less"]
})
export class RestoreComponent implements OnInit {
  constructor(private route: ActivatedRoute,
    private router: Router,
    private imageManagerService: ImageManagerService) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get("id");
    this.imageManagerService.selectedTab = this.route.snapshot.paramMap.get("owner");
    this.imageManagerService.getImage(id).subscribe(image => {
      this.image = image;
    });
  }
  restore() {
    this.imageManagerService.restore(this.image.id).subscribe(() => {
      this.router.navigate(["/images", "images", this.imageManagerService.selectedTab]);
    });
  }
  isNew: boolean;
  image: CreateImage = new CreateImage();

}
