<form #articleForm="ngForm"
      (ngSubmit)="ok()">
  <div class="modal-header">
    <div class="col p-0">
      <h5 class="modal-title">Articles</h5>
    </div>
    <div class="col p-0 text-end">
      <button type="button" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss('Close')">
        <span class="fa fa-close"></span>
      </button>
    </div>
  </div>
  <div class="modal-body p-0 m-0">
    <div class="alert alert-danger" *ngIf="showAlert">
      Please choose an article before continuing.
    </div>
    <table class="table table-bordered table-hover table-striped table-sm m-0">
      <tbody>
      <tr *ngFor="let article of articles" (click)="toggleSelected(article)">
        <td style="width: 26px">
          <a href="javascript:void(0)"><create-toggle-check [value]="article.isSelected"></create-toggle-check></a>
        </td>
        <td>
          <a href="javascript:void(0)">{{article.title}}</a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close('Close')">Cancel</button>
    <button [disabled]="articleForm.invalid"
            type="submit"
            ngbAutofocus
            class="btn btn-primary">
      Ok
    </button>
  </div>
</form>
