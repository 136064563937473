import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, } from "rxjs";
import { HttpClient } from "@angular/common/http";

import * as _ from "underscore";
import { share } from "rxjs/operators";
import { IUser } from "../../../Interfaces/index";
import { User } from "../../../Models/index";
import { Location } from "../../../Models/Location";
//import { stringify } from "querystring";

declare let gigya: any;
@Injectable({
  providedIn: "root"
})

export class AuthenticationService {
  user = new User();
  selectedLocation = new BehaviorSubject(new Location());
  initTimer: any;
  authenticating: boolean = true;
  changeLocationAllowed: boolean = true;
  constructor(private http: HttpClient) { }
  
  getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return "";
  }

  getUser(): Observable<IUser> {

   
    var observable = this.http.get<IUser>(`/api/users/GetUser`).pipe(share());
    observable.subscribe((user) => {
     
      this.user = user;
     
      let selectedLocation = _.findWhere(user.locations, { id: user.defaultLocationId });
      if (selectedLocation == null) {
        selectedLocation = _.first(user.locations);
      }
      this.selectedLocation.next(selectedLocation);
    });
    return observable;
  }

  surveyUpdate(neverAgain): Observable<IUser> {
    var observable = this.http.post<IUser>(`/api/users/updateSurvey/`, { optOut: neverAgain}).pipe(share());
    return observable;
  }
  
  ssoLogin() {

    const gigyaData = JSON.parse(window.sessionStorage.getItem("UID"));
    const userData = JSON.parse(window.sessionStorage.getItem("userProfile"));
    if (gigyaData != null) {
      var data = {
        UID: gigyaData,
        email: userData.email,
        lastName: userData.lastName,
        firstName: userData.firstName,
        defaultAccount: JSON.parse(window.sessionStorage.getItem("defaultAccount"))
      }
     
     return this.http.post<any>(`/Home/storeCookie`, data);
     
     
    }
    var result = new BehaviorSubject<any>({ data: false });
    return result;
    

  }

  gigyaparam() {

    var idsParams = {
      include: 'profile, data',

      callback: function (res) {
       
        if (res.errorCode == 0) {
          var isOnIOS = navigator.userAgent.match(/Macintosh/i) || navigator.userAgent.match(/iPhone/i);
          if (isOnIOS != null) {
            window.onunload = function () {
              console.log("after windows unload");
              sessionStorage.setItem("UID", JSON.stringify(res.UID));
              sessionStorage.setItem("userProfile", JSON.stringify(res.profile));
              sessionStorage.setItem("defaultAccount", JSON.stringify(res.data.accountNumber));

            }
          }
          else {
            window.onbeforeunload = function () {
              sessionStorage.setItem("UID", JSON.stringify(res.UID));
              sessionStorage.setItem("userProfile", JSON.stringify(res.profile));
              sessionStorage.setItem("defaultAccount", JSON.stringify(res.data.accountNumber));
            }
          }
          window.location.reload();
        }
        else {
          window.status = 'Error :' + res.errorMessage;
          var returnUrl = window.location.origin;
          gigya.sso.login(
            {
              authFlow: 'redirect',
              redirectURL: returnUrl,
              context: {
                myCustomLang: 'ar',
                myCustomSessionExpiration: 60 * 60 * 24 * 3
              }
            });


        }
      }

    };
    gigya.accounts.getAccountInfo(idsParams);
  }
}
