import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminMedallionsComponent } from "./medallions/medallions.component";
import { AdminEditMedallionsComponent } from "./edit-medallions/edit-medallions.component";
import { AdminComponent } from "./admin.component";
import { AuthGuard } from "./auth.guard";
import { UserEditComponent } from "./user-edit/user-edit.component";
import { UserSearchComponent } from "./user-search/user-search.component";
import { DeleteMedallionComponent } from "./delete-medallion/delete-medallion.component";

const routes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "content", pathMatch: "full" },
      { path: "medallions", component: AdminMedallionsComponent },
      { path: "edit-medallion/:id", component: AdminEditMedallionsComponent },
      { path: "delete-medallion/:id", component: DeleteMedallionComponent },
      { path: "edit-user/:id", component: UserEditComponent },
      { path: "userSearch", component: UserSearchComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AdminRoutingModule { }
