<div class="container mt-2 p-2 border bg-white">
  <div class="container-fluid">
    <div class="row m-1 pb-2 border-bottom">
      <div class="col-6">
        <h4>Menu Items</h4>
      </div>
      <div class="col-6 text-end">
        <a routerLink="../menu-item/0" class="btn btn-primary mt-1">
          <span class="fa fa-plus"></span> Add Menu Item
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="flex-table hover striped">
          <div class="text-center header">
            <div class="d-flex border flex-table-row">
              <div class="col cell">Title</div>
              <div class="cell" style="width: 160px">Menu Position</div>
              <div class="cell" style="width: 100px">Default</div>
              <div class="cell" style="width: 150px">Menu Type</div>
              <div class="cell" style="width: 80px">Active</div>
              <div class="cell" style="width: 120px">Actions</div>
            </div>
          </div>
          <div cdkDropList
               (cdkDropListDropped)="drop($event)"
               class="body">
            <div *ngFor="let menuItem of menuItems"
                 cdkDragLockAxis="y"
                 cdkDrag
                 class="d-flex border flex-table-row">
              <div class="col cell">
                <a [routerLink]="['../menu-item', menuItem.id]">{{menuItem.title}}</a>
              </div>
              <div style="width: 160px" class="cell text-center">
                <a [routerLink]="['../menu-item', menuItem.id]">
                  {{menuItem.menuPosition}}
                </a>
              </div>
              <div style="width: 100px" class="cell text-center">
                <a (click)="toggleDefault(menuItem)">
                  <create-toggle-check displayType="star" [value]="menuItem.default"></create-toggle-check>
                </a>
              </div>
              <div style="width: 150px" class="cell">
                <a [routerLink]="['../menu-item', menuItem.id]">
                  <span *ngIf="menuItem.menuItemType==1">Article</span>
                  <span *ngIf="menuItem.menuItemType==2">Component</span>
                  <span *ngIf="menuItem.menuItemType==3">External Link</span>
                </a>
              </div>
              <div style="width: 80px" class="cell text-center">
                <a (click)="togglePublished(menuItem)">
                  <create-toggle-check [value]="menuItem.published"></create-toggle-check>
                </a>
              </div>
              <div class="text-center cell" style="width: 120px">
                <a [routerLink]="['../menu-item', menuItem.id]">edit</a> |
                <a [routerLink]="['../delete-menu-item', menuItem.id]">delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
