<a *ngIf="menuItem.menuItemType == 1"
   class="menu_nav"
   routerLinkActive="active"
   [routerLink]="['content-view', 'article', menuItem.articleId]">
  {{menuItem.title}}
</a>
<a *ngIf="menuItem.menuItemType == 2"
   (click)="activateLink()"
   class="menu_nav"
   routerLinkActive="active"
   [routerLink]="[menuItem.componentName]">
  {{menuItem.title}}
</a>
<a *ngIf="menuItem.menuItemType == 3"
   class="menu_nav"
   target="_blank"
   [href]="menuItem.url">
  {{menuItem.title}}
</a>
