import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class TemplateService {

  constructor() {}

  menuPositions = [
    {
      title: "Header",
      value: "header"
    }, {
      title: "Navigation Bar",
      value: "nav-bar"
    }, {
      title: "Survey Dialog",
      value: "survey-dialog"
    }
  ];
}
