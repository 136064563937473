import { Component, OnChanges, Input } from "@angular/core";
import { Router } from "@angular/router";
import { CreateImage } from "../../../Models/CreateImage";
import { CreateRole } from "../../../Models/CreateRole";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { AuthenticationService } from "../../shared/Services/authentication.service";
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderingComponent } from "../ordering/ordering.component";

@Component({
  selector: "create-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.less"]
})
export class FHGroupComponent implements OnChanges {
  @Input() categories;
  @Input() allowSort;
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private imageManagerService: ImageManagerService,
    private modalService: NgbModal) {
  }
  clickedImage(image: CreateImage) {
    if (this.imageManagerService.selectedTab !== "default" && this.authenticationService.user.role < CreateRole.Admin) {
       return;
    }
    this.router.navigate(["images", "image", image.id, this.imageManagerService.selectedTab]);
  }
  ngOnChanges() {
  }
  openSort(category) {
    var ref = this.modalService.open(OrderingComponent);
    ref.componentInstance.category = category;
    ref.result.then(result => {
      console.log(result);
    });
  }
}
