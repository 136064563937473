import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "../shared/Services/authentication.service";

@Component({
  selector: "create-authenticating",
  templateUrl: "./authenticating.component.html",
  styleUrls: ["./authenticating.component.less"]
})
export class AuthenticatingComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
    const returnUrl = this.route.snapshot.queryParamMap.get("returnUrl");
    this.authentication.getUser().subscribe(user => {
      if(!this.authentication.authenticating) {
        this.router.navigate([returnUrl]);
      }
    });
  }

}
