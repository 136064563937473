import { Article } from "./Article";
import { MenuItemType } from "./MenuItemType";

export class MenuItem {
  id: string = "";
  title = "";
  articleId: string = null;
  article: Article;
  componentName = "";
  url = "";
  published = true;
  menuItemType = MenuItemType.Article;
  parent: MenuItem = null;
  isSelected: boolean;
  ordering: number;
  menuPosition = "header";
  default: boolean = false;
  allowOptOut: boolean = false;
}
