import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "../models/MenuItem";
import { MenuItemsService } from "../../../shared/Services/menu-items.service";

@Component({
  selector: "create-delete-menu-item",
  templateUrl: "./delete-menu-item.component.html",
  styleUrls: ["./delete-menu-item.component.less"]
})
export class DeleteMenuItemComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private menuItemsService: MenuItemsService) { }


  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    this.menuItem = new MenuItem();
    if (id === "0") {
      return;
    }
    this.menuItemsService.getMenuItem(id).subscribe(menuItem => {
      this.menuItem = menuItem;
    });
  }
  delete() {
    this.menuItemsService.delete(this.menuItem.id).subscribe(() => {
      this.router.navigate(["admin", "content", "menu-items"]);
    });
  }
  menuItem: MenuItem;
}
