<form #articleForm="ngForm"
      (ngSubmit)="ok()">
  <div class="modal-header">
    <div class="col p-0">
      <h5 class="modal-title">Link Builder</h5>
    </div>
    <div class="col p-0 text-end">
      <button type="button" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss()">
        <span class="fa fa-close"></span>
      </button>
    </div>
  </div>
  <div class="modal-body p-0 m-0">
    <h5 class="header border-bottom-light p-2 mb-1">
      Link to an article.
    </h5>
    <table class="table table-bordered table-hover table-striped table-sm m-0">
      <tbody>
      <tr *ngFor="let article of articles" (click)="toggleSelected(article)">
        <td style="width: 26px">
          <a href="javascript:void(0)"><create-toggle-check displayType="radio" [value]="article.isSelected"></create-toggle-check></a>
        </td>
        <td>
          <a href="javascript:void(0)">{{article.title}}</a>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <div class="btn btn-primary" (click)="chooseMedia()">Choose Media</div>
    </div>
    <div class="d-flex form-group p-2 mb-0">
      <label class="col-1 pt-1" for="link">Link</label>
      <div class="col">
        <input id="link" class="form-control" name="link" type="text" [(ngModel)]="link" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">Cancel</button>
    <button [disabled]="articleForm.invalid"
            type="submit"
            ngbAutofocus
            class="btn btn-primary">
      Ok
    </button>
  </div>
</form>
