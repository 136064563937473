import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { CreateImageCategory } from "../../../shared/Services/CreateImageCategory";
import { ImageManagerService } from "../../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../../shared/Services/authentication.service";

@Component({
  selector: "create-default-image-list",
  templateUrl: "./default-image-list.component.html",
  styleUrls: ["./default-image-list.component.less"]
})
export class DefaultImageListComponent implements OnInit, OnDestroy {
  subscriptions = new Array<Subscription>();
  images: Array<CreateImageCategory> = [];

  constructor(private route: ActivatedRoute,
    private imageManagerService: ImageManagerService,
    private router: Router,
    public authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.imageManagerService.selectedTab = "default";
      this.imageManagerService.searchValue = "";
      this.imageManagerService.searchText = "";
    });
    this.authenticationService.selectedLocation.subscribe(location => {
      if (location == null) {
        return;
      }
      this.subscriptions.push(this.imageManagerService.showDeleted.subscribe(deleted => {
        this.subscriptions.push(this.imageManagerService
          .getLocationImages(location.id, deleted).subscribe(images => {
            this.images = images;
          }));
      }));
    });
  }
  ngOnDestroy(): void {
      this.subscriptions.forEach(sub=>sub.unsubscribe());
  }

  filterCategory(category: CreateImageCategory): CreateImageCategory {
    var cat = new CreateImageCategory();
    cat.title = category.title;
    return cat;
  }
}
