<div class="row m-0">
  <div *ngIf="!applicationService.condensedFooter" class="col mt-2 p-0 text-center">
    <div><img alt="Batesville Logo" src="../../../../dist/assets/bv-logo-vector-footer.svg" /></div>
    <div>&copy; 2023 Batesville Services,LLC</div>
    <div>One Batesville Boulevard</div>
    <div>Batesville, IN 47006</div>
    <div>812.934.7500</div>
    <div><a href="mailto:marketing.info@batesville.com">marketing.info@batesville.com</a></div>
  </div>
  <div *ngIf="applicationService.condensedFooter" class="col mt-2 p-0 text-center">
    <div>&copy; 2023 Batesville Services, LLC</div>
  </div>
</div>
