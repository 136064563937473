<div class="outer-container"
     (mousemove)="mouseMove($event)">
  <create-help-docs-button [target]="'5D42998A-DC01-42AF-BD30-B4E5E8BE36B3'"></create-help-docs-button>
  <div class="center-offset">
    <div class="workspace-offset"
         [style.left.px]="workspace.pan.x * zoom.current"
         [style.top.px]="workspace.pan.y * zoom.current">
      <div class="canvas-container"
           [style.background-color]="medallion.backgroundColor"
           [style.width.px]="canvas.width * zoom.current"
           [style.height.px]="canvas.height * zoom.current"
           [style.left.px]="canvas.x * zoom.current"
           [style.top.px]="canvas.y * zoom.current">
        <yw-editor-medallion-image *ngFor="let layer of medallion.layout"
                                   (changed)="imageChanged($event)"
                                   [medallion]="medallion"
                                   [zoom]="zoom.current"
                                   [workspace]="workspace"
                                   [layer]="layer"
                                   [imageX]="layer.x"
                                   [imageY]="layer.y"
                                   [imageWidth]="layer.width"
                                   [imageHeight]="layer.height"></yw-editor-medallion-image>

        <div class="border-overlay"></div>
        <div class="print-area-overlay"
             *ngIf="medallion.bleeding"
             [style.left.px]="printContainer.x * zoom.current"
             [style.top.px]="printContainer.y * zoom.current"
             [style.width.px]="printContainer.width * zoom.current"
             [style.height.px]="printContainer.height * zoom.current"></div>
      </div>
    </div>
  </div>
  <yw-workspace-overlay [panX]="workspace.pan.x"
                        [panY]="workspace.pan.y"
                        [canvasX]="canvas.x"
                        [canvasY]="canvas.y"
                        [canvasWidth]="canvas.width"
                        [canvasHeight]="canvas.height"
                        [zoom]="zoom.current">
  </yw-workspace-overlay>
</div>
