<div class="modal-header">
  <div class="col-10 p-0">
    <h4 class="modal-title">
      Invalid Image Type
    </h4>
  </div>
  <div class="col p-0 text-end">
    <button type="button" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss('Close')">
      <span class="fa fa-close"></span>
    </button>
  </div>
</div>
<div class="modal-body">
  Only valid types are jpeg or png.
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('Ok')">Ok</button>
</div>
