import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { share } from "rxjs/operators";
import * as _ from "underscore";
import { Article } from "../../admin/content/models/Article";
import { Guid } from "../../admin/content/models/Guid";

@Injectable({
  providedIn: "root"
})
export class ArticleService {
  constructor(private http: HttpClient) { } 
  articles: Article[] = [];
  getArticle(id: string): Observable<Article> {
    var result = this.http.get<Article>(`/api/article/get/${id}`).pipe(share());
    return result;
  }

  delete(id: string): Observable<Article> {
    var result = this.http.delete<Article>(`/api/article/delete/${id}`).pipe(share());
    result.subscribe(() => {
      var currentArticle = _.findWhere(this.articles, { id: id });
      this.articles = _.without(this.articles, currentArticle);
    });
    return result;
  }

  add(article): Observable<Article> {
    article.id = Guid.newGuid();
    var result = this.http.put<Article>("/api/article/", article).pipe(share());
    result.subscribe(resultArticle => {
      this.articles.push(resultArticle);
    });
    return result;
  }

  update(article: Article): Observable<Article> {
    var result = this.http.post<Article>("/api/article/", article).pipe(share());

    return result;
  }
  loadArticles(): Observable<Article[]> {
    var result = this.http.get<Article[]>("/api/article/getAll/").pipe(share());
    result.subscribe(articles => {
      this.articles = articles;
    }); 
    return result;
  }
  getArticles(): Article[] {
    return this.articles;
  }
}
