<div class="modal-header">
  <h5 class="modal-title">Medallion Options</h5>
  <button type="button" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss('Close')">
    <span aria-hidden="true" class="fa fa-close"></span>
  </button>
</div>
<div class="modal-body">
  <div class="properties">
    <div class="container-fluid">
      <div class="row">
        <label>Type</label>
        <select class="form-control border-bottom"
                [(ngModel)]="medallion.size">
          <option [ngValue]="size"
                  *ngFor="let size of medallionSizes">
            {{size.title}}
          </option>
        </select>
      </div>
      <div class="row">
        <label>Quantity</label>
        <input class="form-control border-bottom"
               type="number"
               min="1"
               [(ngModel)]="medallion.quantity" />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-end">
  <button class="btn btn-primary" 
          type="button"
          (click)="activeModal.dismiss('Close')">Close</button>
  <button class="btn btn-primary"
          type="button"
          ngbAutofocus
          (click)="activeModal.close('Ok')">Ok</button>
</div>
