import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { MediaElement } from "../../admin/content/choose-video/MediaElement";

@Injectable({
  providedIn: "root"
})
export class MediaService {
  constructor(private http: HttpClient) { }

  putMedia(media: File): Observable<string> {
    const formData = new FormData();
    formData.append("Image", media, media.name);
    const result = this.http.put<string>("/api/media/upload/", formData).pipe(share());
    return result;
  }

  getMedia(): Observable<MediaElement[]> {
    const result = this.http.get<MediaElement[]>("/api/media/getAll/").pipe(share());
    result.subscribe(media => { console.log(media); });
    return result;
  }

  putMediaElement(media): Observable<boolean> {
    const result = this.http.put<boolean>("/api/media/put/", media).pipe(share());
    return result;
  }

  postMediaElement(media: MediaElement): Observable<boolean> {
    const result = this.http.post<boolean>("/api/media/post/", media).pipe(share());
    return result;
  }
}
