import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { ImageProperties } from "../../../Models/index";
import { CreateService } from "../../shared/Services/create.service";

@Component({
  selector: "yw-image-properties",
  templateUrl: "./image-properties.component.html",
  styleUrls: ["./image-properties.component.less"]
})
export class ImagePropertiesComponent implements OnInit {
  category: string;
  title: string;

  @Output() propertiesSet = new EventEmitter();

  constructor(public CreateService: CreateService) {

  }
  changed() {
    var imageProperties = new ImageProperties();
    imageProperties.category = this.category;
    imageProperties.title = this.title;
    this.propertiesSet.emit(imageProperties);
  }
  ngOnInit() {
  }
}
