<div class="overlay" *ngIf="project.completed"></div>
<div class="toggle-handle-right" (click)="toggleOpen()">
  <span class="fa"
        [class.fa-chevron-right]="collapsed"
        [class.fa-chevron-left]="!collapsed"></span>
</div>
<div class="row border-bottom">
  <div class="col-sm p-1 font-weight-bold">Medallions</div>
  <div class="col-1 text-end">
    <create-help-docs-button [target]="'43C31B84-648C-4BDC-AABB-AE5686304128'"></create-help-docs-button>
  </div>
</div>
<div class="row">
  <select class="image-filter" [(ngModel)]="filterMedallions" (change)="filterMedallionsChanged()">
    <option [ngValue]="null">All</option>
    <option [ngValue]="size" *ngFor="let size of medallionService.medallionSizes">{{size.title}}</option>
  </select>
</div>
<div class="row image-container">
  <div class="image-option">
    <div class="medallion-option">
      <a class="new-button" (click)="newMedallion()">
        <span class="fa fa-plus-circle"></span>
      </a>
    </div>
  </div>
  <div class="image-option"
       [class.active]="medallion == currentMedallion"
       (click)="setActive(medallion)"
       *ngFor="let medallion of project.medallions"
       [hidden]="medallion.filtered">
    <div class="medallion-option"
         *ngIf="medallion.thumbnailUrl != null"
         [style.background-image]="'url(/uploaded-images/' + medallion.thumbnailUrl + ')'"></div>
    <div class="clone-medallion" (click)="cloneMedallion(medallion, $event)">
      <span title="Clone Medallion" class="fa fa-clone"></span>
    </div>
    <div class="remove-medallion" (click)="removeMedallion(medallion, $event)">
      <span title="Delete Medallion" class="fa fa-remove"></span>
    </div>
  </div>
</div>
