import { Component, OnInit, ElementRef, HostBinding } from "@angular/core";
import { HelpDocument } from "./help-docs/HelpDocument";
import * as _ from "underscore";
import * as $ from "jquery";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CreateRole } from "../../../Models/CreateRole";

import Quill from "quill"
import { HelpDocsService } from "../Services/help-docs.service";
import { AuthenticationService } from "../Services/authentication.service";

const parchment = Quill.import("parchment");
const block = parchment.query("block");
block.tagName = "DIV";
Quill.register(block /* or NewBlock */, true);

@Component({
  selector: "create-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.less"],
  host: { '[class.open]': "helpOpen" }
})
export class HelpComponent implements OnInit {
  helpOpen: boolean = false;
  allowOrdering: boolean = false;
  sorting = false;
  @HostBinding("style.z-index") zIndex: number = 990;
  toggleSorting() {
    this.sorting = !this.sorting;
  }
  constructor(private helpDocsService: HelpDocsService,
    private authentication: AuthenticationService,
    private element: ElementRef) { }
  drop(event: CdkDragDrop<Array<HelpDocument>>) {
    moveItemInArray(this.helpDocs, event.previousIndex, event.currentIndex);
    let z = 0;
    this.helpDocs.forEach(layer => {
      layer.ordering = z++;
    });
    this.helpDocsService.updateOrdering(this.helpDocs).subscribe(docs => console.log(docs));
  }
  ngOnInit() {
    this.helpDocsService.currentTarget.subscribe(guid => {
      this.zIndex = this.helpDocsService.zIndex;
      if (guid == null) {
        return;
      }
      this.moveToHelp(guid);
    });
    this.authentication.getUser().subscribe(user => {
      this.helpDocsService.helpDocs.subscribe(docs => {
        if (docs == null) {
          return;
        }
        this.allowOrdering = user.role >= CreateRole.Admin;
        this.helpDocs = docs.filter(doc => doc.role <= user.role);
        if (this.authentication.user.role < 100) {
          this.helpDocs = this.helpDocs.filter(doc => doc.published);
        }
        this.helpDocs = _.sortBy(this.helpDocs, doc => doc.ordering);
      });
    });
  }
  toggleOpen() {
    if (this.helpOpen === false) {
      this.helpOpen = true;
      return;
    }
    this.close();
  }
  close() {
    this.zIndex = this.helpDocsService.zIndex = 990;
    this.helpOpen = false;
    this.helpDocs.forEach(doc => {
      doc.edit = false;
    });
  }
  moveToHelp(guid) {
    this.helpDocs.forEach(h => h.active = false);
    var helpDoc: HelpDocument = _.findWhere(this.helpDocs, { id: guid });
    if (helpDoc == null) {
      this.createHelpDoc(guid);
      return;
    }

    helpDoc.active = true;
    this.scrollTo(helpDoc);
  }


  createHelpDoc(guid) {
    this.helpDocsService.createHelpDoc(guid).subscribe(helpDoc => {
      this.helpDocs.push(helpDoc);
      helpDoc.active = true;
      this.scrollTo(helpDoc);
    });
  }

  scrollTo(helpDocument: HelpDocument) {
    this.helpOpen = true;
    var padding = 10;
    let helpDocTag = $(this.element.nativeElement).find(`#${helpDocument.id}`);
    let helpContent = $(this.element.nativeElement).find(".help-content");
    if (helpDocTag.length > 0) {
      helpContent.animate({
        scrollTop: helpContent[0].scrollTop + (helpDocTag.offset().top - helpContent.offset().top - padding)
        },
        2000);
    }
  }
  helpDocs:HelpDocument[];
}
