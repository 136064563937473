<div id="controlContainer"
     (mousemove)="mousemove($event)">
  <input class="border-bottom percentage-input"
         length="3"
         (change)="changePercentage()"
         [(ngModel)]="zoomLevelPercentage"/>
  <span (click)="setZoomLevel(-.25)" id="zoomOut" class="fa fa-search-minus"></span>
  <div id="zoomSlideBar">
    <div id="zoomSlideHandle"
         (mousedown)="zoomHandleMouseDown()"
         [style.left.px]="sliderLeft"></div>
  </div>
  <span (click)="setZoomLevel(.25)" id="zoomIn" class="fa fa-search-plus"></span>
</div>
