import Quill from "quill";

const QuillLinkFormat: any = Quill.import("formats/link");

export default class QuillLinkExtended extends QuillLinkFormat {
  static create(value): any {
    //ts-ignore
// ReSharper disable once TsResolvedFromInaccessibleModule
      return super.create(value);
  }

  static sanitize(value) {
    //ts-ignore
// ReSharper disable once TsResolvedFromInaccessibleModule
    return super.sanitize(value);
  }
}
