import { Component, OnInit, Input } from "@angular/core";
import { MenuItem } from "../../admin/content/models/MenuItem";
import { MenuItemsService } from "../../shared/Services/menu-items.service";

@Component({
  selector: "create-menu-view-item",
  templateUrl: "./menu-view-item.component.html",
  styleUrls: ["./menu-view-item.component.less"]
})
export class MenuViewItemComponent implements OnInit {
  @Input() menuItem: MenuItem = new MenuItem();
  constructor(private menuItemsService: MenuItemsService) { }

  ngOnInit() {
  }
  activateLink() {
    this.menuItemsService.navigate(this.menuItem);
  }
}
