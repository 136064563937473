import { Component, OnInit } from "@angular/core";
import { MenuItem } from "../models/MenuItem";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import * as _ from "underscore";
import { MenuItemsService } from "../../../shared/Services/menu-items.service";

@Component({
  selector: "create-menu-items",
  templateUrl: "./menu-items.component.html",
  styleUrls: ["./menu-items.component.less"]
})
export class MenuItemsComponent implements OnInit {
  menuItems: MenuItem[];

  constructor(
    private menuItemsService: MenuItemsService) {
  }

  drop(event: CdkDragDrop<Array<MenuItem>>) {
    moveItemInArray(this.menuItems, event.previousIndex, event.currentIndex);
    let z = 1;
    this.menuItems.forEach(layer => {
      layer.ordering = z++;
    });
    this.menuItemsService.saveOrdering(this.menuItems);
  }
  togglePublished(menuItem: MenuItem) {
    menuItem.published = !menuItem.published;
    this.menuItemsService.update(menuItem);
  }
  toggleDefault(menuItem: MenuItem) {
    this.menuItems.forEach(item => item.default = false);
    menuItem.default = true;
    this.menuItemsService.toggleDefault(menuItem).subscribe(item => {

    });
  }
  ngOnInit() {
    this.menuItemsService.getMenuItems().subscribe(menuItems => {
      this.menuItems = _.sortBy(menuItems, menuItem=>menuItem.ordering);
    });
  }
}
