import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FHImagesComponent } from "../fh-images.component";
import { FHImageListComponent } from "../images/image-list.component";
import { FHImageComponent } from "../image/fh-image.component";
import { DeleteComponent } from "../delete/delete.component";
import { RestoreComponent } from "../restore/restore.component";
import { DefaultImageListComponent } from "../images/default-image-list/default-image-list.component";
import { BatesvilleImageListComponent } from "../images/batesville-image-list/batesville-image-list.component";
import { SearchImageListComponent } from "../images/search-image-list/search-image-list.component";
const routes: Routes = [
  {
    path: "images",
    component: FHImagesComponent,
    children: [
      { path: "", redirectTo: "images", pathMatch: "full" },
      {
        path: "images",
        component: FHImageListComponent,
        children: [
          { path: "", redirectTo: "default", pathMatch: "full" },
          { path: "default", component: DefaultImageListComponent },
          { path: "batesville", component: BatesvilleImageListComponent },
          { path: "search/:searchValue", component: SearchImageListComponent }
          ]
      },
      { path: "image/:id", component: FHImageComponent },
      { path: "image/:id/:owner", component: FHImageComponent },
      { path: "delete/:id/:owner", component: DeleteComponent },
      { path: "restore/:id/:owner", component: RestoreComponent }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FHImagesRouterModule { }
