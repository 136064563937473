<div *ngIf="displayType == 'check'">
  <span class="fa"
        [class.fa-check-square-o]="value"
        [class.fa-square-o]="!value"></span>
</div>
<div *ngIf="displayType == 'star'">
  <span class="fa"
        [class.fa-star]="value"
        [class.fa-star-o]="!value"></span>
</div>

<div *ngIf="displayType == 'radio'">
  <span class="fa"
        [class.fa-circle]="value"
        [class.fa-circle-o]="!value"></span>
</div>
