import { Component, Input, HostBinding, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from "@angular/core";
import { Medallion, Project, MedallionSize } from "../../../Models/index";
import { IMedallion } from "../../../Interfaces/index";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
import { MedallionOptionsDialogComponent } from "../../medallion-options-dialog/medallion-options-dialog.component";
import { MedallionService } from "../../shared/Services/medallion.service";
import { CreateService } from "../../shared/Services/create.service";
import { CreateSettingsService } from "../../shared/Services/create-settings.service";

@Component({
  selector: "yw-medallion-list",
  templateUrl: "./medallion-list.component.html",
  styleUrls: ["./medallion-list.component.css"]
})
export class MedallionListComponent implements OnInit, OnChanges {
  subscribe: any;
  currentMedallion: IMedallion;
  @HostBinding("class.collapsed")
  collapsed: boolean = false;
  @Input()
  project: Project;
  filterMedallions: MedallionSize = null;

  @Output() openedFull = new EventEmitter();

  constructor(public medallionService: MedallionService,
    public createService: CreateService,
    private createSettings: CreateSettingsService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.subscribe = this.createService.currentMedallion.subscribe(medallion => {
      this.currentMedallion = medallion;
    });
  }
  toggleOpen() {
    this.collapsed = !this.collapsed;
    this.openedFull.emit(this.collapsed);
  }
  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
  filterMedallionsChanged() {
    this.project.medallions.forEach(medallion => medallion.filtered = true);
    var activeMedallions = this.project.medallions.filter(medallion => this.filterMedallions == null ||
      medallion.size.id === this.filterMedallions.id);
    activeMedallions.forEach(medallion => medallion.filtered = false);
    this.setActive(_.first(activeMedallions));
  }
  setActive(medallion) {
    this.createService.setMedallion(medallion);
  }

  newMedallion() {
    this.createService.newMedallion(this.project).subscribe(med => {
      var medallion = new Medallion(this.createSettings);
      medallion.id = med.id;
      medallion.quantity = med.quantity;
      medallion.backgroundColor = med.backgroundColor;
      medallion.size = this.medallionService.getSize( med.size.id);
      this.newMedallionDialog = this.modalService.open(MedallionOptionsDialogComponent, { size: "sm" });
      this.newMedallionDialog.componentInstance.medallion = medallion;
      this.newMedallionDialog
        .result
        .then((result) => {
            if (this.project.medallions == null) {
              this.project.medallions = new Array<Medallion>();
            }
            medallion.layout = [];
            medallion.isNew = true;
            this.project.medallions.push(medallion);
            this.newMedallionDialogOpen = false;
            this.createService.setMedallion(medallion);
          },
          () => {
            this.newMedallionDialogOpen = false;
            this.createService.deleteMedallion(medallion);
          });
    });
  }
  removeMedallion(medallion: Medallion, event: MouseEvent) {
    this.createService.deleteMedallion(medallion);
    this.project.medallions = _.without(this.project.medallions, medallion);
    event.preventDefault();
  }
  cloneMedallion(medallion: Medallion, event: MouseEvent) {
    this.createService.cloneMedallion(medallion).subscribe(currentMedallion => {
      let newMedallion = Medallion.bindMedallion(currentMedallion, this.createSettings);
      this.createService.setMedallion(newMedallion);
      newMedallion.size = this.medallionService.getSize(currentMedallion.sizeId);
      this.project.medallions.push(newMedallion);
    });
    event.preventDefault();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.project != null) {
      if (this.project.medallions.length < 1 && !this.newMedallionDialogOpen) {
        this.newMedallionDialogOpen = true;
        if (this.newMedallionTimeout) {
          clearTimeout(this.newMedallionTimeout);
        }
        this.newMedallionTimeout = setTimeout(() => {
            this.newMedallion();
          },
          250);
      }
      else {
        this.createService.setMedallion(_.first(this.project.medallions));
      }
    }
  }
  newMedallionDialogOpen: boolean = false;
  newMedallionDialog: NgbModalRef;
  newMedallionTimeout: any;
}
