import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../shared/Services/authentication.service";
import { CreateImageCategory } from "../../shared/Services/CreateImageCategory";

@Component({
  selector: "create-images",
  templateUrl: "./image-list.component.html",
  styleUrls: ["./image-list.component.less"]
})
export class FHImageListComponent implements OnInit, OnDestroy {
  search() {
    this.router.navigate(["/images", "images", "search", this.imageManagerService.searchText]);
  }
  constructor(
    public imageManagerService: ImageManagerService,
    private router: Router,
    public authenticationService: AuthenticationService) {
  }
  ngOnInit() {
    this.authenticationService.selectedLocation.subscribe(location => {
      if (location == null) {
        return;
      }
      this.subscription = this.imageManagerService.getLocationImages(location.id, this.imageManagerService.deleted).subscribe(images => {
        this.unfilteredImageCategories = images;
      });
    });
  }
  inputChanged() {
    setTimeout(() => {
        this.imageManagerService.showDeleted.next(this.imageManagerService.deleted);
        this.ngOnInit();
      },
      10);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  filterCategory(category: CreateImageCategory): CreateImageCategory {
    var cat = new CreateImageCategory();
    cat.title = category.title;
    return cat;
  }

  subscription: Subscription;
  images: Array<CreateImageCategory> = [];
  unfilteredImageCategories: CreateImageCategory[];
  
}
