import { Component, OnInit, Input } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'create-ordering',
  templateUrl: './ordering.component.html',
  styleUrls: ['./ordering.component.less']
})
export class OrderingComponent implements OnInit {
  @Input() category;
  constructor(private imageManagerService: ImageManagerService,
    public activeModal: NgbActiveModal, ) {
  }

  drop(event, items) {
    moveItemInArray(items, event.previousIndex, event.currentIndex);
    var ordering = 1;
    items.forEach(item => {
      item.ordering = ordering++;
    });
    this.imageManagerService.saveOrdering(items);
  }

  ngOnInit() {
  }

}
