<div class="container-fluid">
  <div class="row border-bottom-light">
    <div class="col">
      <h4 class="mt-2">Stock Images</h4>
    </div>
    <div class="col text-center">
      <div class="input-group m-1">
        <input class="form-control"
               (keydown.enter)="search()"
               placeholder="search" [(ngModel)]="imageManagerService.searchText"/>
        <div class="input-group-append">
          <button type="button" style="height:39px; padding: 2px 10px; font-weight: 700; font-size: 14px;" class="btn btn-outline-primary" (click)="search()">
            <span class="fa fa-search"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col text-end">
      <div *ngIf="imageManagerService.selectedTab != 'batesville' || authenticationService.user.role > 99">
        <label class="inline-control mt-3 mr-1" style="vertical-align: sub;">Show Deleted</label>
        <ui-switch [(ngModel)]="imageManagerService.deleted"
                   (change)="inputChanged()"></ui-switch>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col m-0 p-0 pt-2 pl-2">
      <ul class="nav nav-tabs m-0 p-0">
        <li class="nav-item"><a class="nav-link" routerLinkActive="active" [routerLink]="['/images','images', 'default']">Funeral Home</a></li>
        <li class="nav-item"><a class="nav-link" routerLinkActive="active" [routerLink]="['/images','images','batesville']">Batesville</a></li>
        <li class="nav-item"><a class="nav-link" routerLinkActive="active" *ngIf="imageManagerService.searchValue!=''" [routerLink]="['/images','images','search', imageManagerService.searchValue]">Search</a></li>
      </ul>
    </div>
    <div class="col m-0 p-0 pt-2 pr-2 text-end border-bottom-light">
      <a *ngIf="imageManagerService.selectedTab == 'default' || (imageManagerService.selectedTab == 'batesville' && authenticationService.user.role>99)" class="btn btn-primary m-1" [routerLink]="['/images','image', 0, imageManagerService.selectedTab]">Add</a>
    </div>
  </div>
  <div>
    <router-outlet></router-outlet>
  </div>
</div>

