import { Component, OnChanges, Input, OnInit, OnDestroy } from "@angular/core";
import { MenuItem } from "../admin/content/models/MenuItem";
import { ArticleService } from "../shared/Services/article.service";
import { Subscription } from "rxjs";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MenuItemType } from "../admin/content/models/MenuItemType";

@Component({
  selector: "create-survey-dialog",
  templateUrl: "./survey-dialog.component.html",
  styleUrls: ["./survey-dialog.component.less"]
})
export class SurveyDialogComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  menuItem: MenuItem;
  trustedHtml: string;
  subscription: Subscription;
  neverAgain: boolean = false;

  constructor(private articleService: ArticleService,
    private activeModal: NgbActiveModal) {
    this.load();
  }

  load() {
    if (this.menuItem == null) {
      return;
    }
    if (this.menuItem.menuItemType === MenuItemType.Article) {

      this.subscription = this.articleService.getArticle(this.menuItem.articleId).subscribe(article => {
        this.trustedHtml = article.document;
      });
    }

  }

  close() {
    this.activeModal.close(this.neverAgain);
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges() {
    this.load();
  }
}
