import { Component, OnInit, OnDestroy,HostListener, Input } from "@angular/core";
import { MenuItem } from "../../admin/content/models/MenuItem";
import * as _ from "underscore";
import { MenuItemsService } from "../../shared/Services/menu-items.service";

@Component({
  selector: "create-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.less"]
})
export class MenuComponent implements OnInit, OnDestroy {
  menuItems: MenuItem[];
  isOpen: boolean = false;
  @Input() menuPosition = "header";
  @Input() type: "dropdown" | "navbar" = "dropdown";
  @HostListener("document:click", ["$event"])
  outerClick(event: MouseEvent) {
    var target = (event.target as HTMLElement);
    if (target.id !== "dropdownMenuButton" && target.id !== "dropdownMenuButtonIcon") {
      this.isOpen = false;
    }
  }
  constructor(private menuItemsService: MenuItemsService) {}

  closeMenu() {
    setTimeout(() => {
      this.isOpen = false;
    }, 10);
  }

  ngOnInit() {   
    this.menuItemsService.getMenuItems();
    this.subscribe = this.menuItemsService.menuItems.subscribe(menuItems => {
      if (menuItems == null) {
        return;
      }
      let filtered = menuItems.filter(menuItem => menuItem.menuPosition === this.menuPosition);
      this.menuItems = _.sortBy(filtered.filter(m => m.published), menuItem => menuItem.ordering);
    });
  }
  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  subscribe;
}
