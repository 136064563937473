import { MedallionSize } from "./MedallionSize";
import { IMedallion } from "../Interfaces/index";
import { MedallionImage } from "./MedallionImage";
import { CreateSettingsService } from "../Components/shared/Services/create-settings.service";

export class Medallion implements IMedallion {
  id: number;
  size: MedallionSize;
  backgroundColor: string;
  quantity: number;
  layout: Array<MedallionImage>;
  thumbnail: string;
  url:string;
  bleeding: boolean = true;
  static bindMedallion(medallion: Medallion, createSettingsService: CreateSettingsService): Medallion {
    const result = new Medallion(createSettingsService);
    result.id = medallion.id;
    result.backgroundColor = medallion.backgroundColor;
    result.quantity = medallion.quantity;
    result.layout = medallion.layout;
    result.bleeding = medallion.bleeding;
    result.url = medallion.url;
    result.thumbnailUrl = medallion.thumbnailUrl;
    result.layout.forEach(layer => {
      var image = new Image();
      image.onload = ev => {
        layer.image = image;
      }
      image.src = `uploaded-images/${layer.imageObject.url}`;
    });
    return result;
  }
  constructor(private createSettings: CreateSettingsService) {
  }

  addCover(image: MedallionImage) {
    const multiplier = this.size.multiplier || 1;
    const medallionWidth = this.size.printWidth * this.createSettings.pixelsPerInch * multiplier;
    const medallionHeight = this.size.printHeight * this.createSettings.pixelsPerInch * multiplier;
    if (image.width === image.height) {
      image.width = this.size.printWidth * this.createSettings.pixelsPerInch;
      image.height = this.size.printHeight * this.createSettings.pixelsPerInch;
      image.y = 0;
      image.x = 0;
    } else {
      if (image.width > image.height) {
        const percentageHeight = medallionHeight / image.height;
        image.width = image.width * percentageHeight;
        image.height = medallionHeight;

      } else {
        const percentageWidth = medallionWidth / image.width;
        image.height = image.height * percentageWidth;
        image.width = medallionWidth;
      }
      image.y = ((medallionHeight / multiplier) - image.height) / 2;
      image.x = ((medallionWidth / multiplier) - image.width) / 2;
    }
    if (this.layout == null) {
      this.layout = new Array<MedallionImage>();
    }
    this.layout.push(image);
  }

  addContain(image: MedallionImage) {
    if (image.width > image.height) {
      const medallionWidth = this.size.printWidth * this.createSettings.pixelsPerInch;
      const ph = medallionWidth / image.width;

      image.width = medallionWidth;
      image.height = image.height * ph;
      image.y = ((this.size.printHeight * this.createSettings.pixelsPerInch) - image.height) / 2;
    }
    else {
      const medallionHeight = this.size.printHeight * this.createSettings.pixelsPerInch;
      const pw = medallionHeight / image.height;

      image.height = medallionHeight;
      image.width = image.width * pw;
      image.x = ((this.size.printWidth * this.createSettings.pixelsPerInch) - image.width) / 2;
    }
    if (this.layout == null) {
      this.layout = new Array<MedallionImage>();
    }
    console.log(this.layout, image);
    this.layout.push(image);
  }


  setActive(medallionImage: MedallionImage) {
    this.layout.forEach(l => l.active = false);
    if (medallionImage) {
      medallionImage.active = true;
    }
  }

  filtered: boolean = false;

  thumbnailUrl: string;
  isNew: boolean = false;
  sizeId: number;
}
