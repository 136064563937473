import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { share } from "rxjs/operators";
import { HelpDocument } from "../help/help-docs/HelpDocument";

@Injectable({
  providedIn: "root"
})
export class HelpDocsService {
  currentTarget = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient) {
    this.loadHelpDocs().subscribe(helpDocs => this.helpDocs.next(helpDocs));
  }

  loadHelpDocs(): Observable<HelpDocument[]> {
    return this.httpClient.get<HelpDocument[]>("api/help");
  }

  createHelpDoc(guid): Observable<HelpDocument> {
    return this.httpClient.post<HelpDocument>("api/help/create", { id: guid });
  }

  updateDoc(document: HelpDocument): Observable<HelpDocument> {
    var result = this.httpClient.post<HelpDocument>("api/help/update", document).pipe(share());
    return result;
  }

  helpDocs = new BehaviorSubject<HelpDocument[]>(null);

  updateOrdering(helpDocs: HelpDocument[]): Observable<HelpDocument[]> {
    var documents = helpDocs.map(doc => {
      return { id: doc.id, ordering: doc.ordering }
    });
    var result = this.httpClient.post<HelpDocument[]>("api/help/updateOrdering", documents).pipe(share());
    return result;
  }

  zIndex: number = 990;
}
