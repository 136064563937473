import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges, HostListener } from "@angular/core";
import * as $ from "jquery";
import { Point } from "../../../../../Models/index";

@Component({
  selector: "yw-workspace-overlay",
  templateUrl: "./workspace-overlay.component.html",
  styleUrls: ["./workspace-overlay.component.less"]
})
export class WorkspaceOverlayComponent implements OnInit, OnChanges {
  @Input()
  panX: number;
  @Input()
  panY: number;
  @Input()
  canvasX: number;
  @Input()
  canvasY: number;
  @Input()
  canvasWidth: number;
  @Input()
  canvasHeight: number;
  @Input()
  zoom: number;

  constructor(private el: ElementRef) {
  }
  setElementSize() {
    var elem = ($(this.el.nativeElement).find("canvas")[0] as HTMLCanvasElement);
    elem.height = $(elem).height();
    elem.width = $(elem).width();
    this.drawOverlay();
  }

  @HostListener("window:resize", [])
  resize() {
    this.setElementSize();
  }
  ngOnInit() {
    var elem = ($(this.el.nativeElement).find("canvas")[0] as HTMLCanvasElement);
    this.context = elem.getContext("2d");
    this.setElementSize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setElementSize();
  }

  drawOverlay() {
    if (this.context == null) {
      return;
    }
    this.offset = new Point(this.context.canvas.width / 2, this.context.canvas.height / 2);
    this.context.clearRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    this.context.fillStyle = "rgba(0, 0, 0, 0.5)";

    this.context.beginPath();
    this.context.moveTo(0, 0);
    this.context.lineTo(0, this.context.canvas.height);
    this.context.lineTo(this.context.canvas.width, this.context.canvas.height);
    this.context.lineTo(this.context.canvas.width, 0);

    this.context.rect(this.getCanvasX(), this.getCanvasY(), this.getCanvasWidth(), this.getCanvasHeight());
    this.context.fill();
  }
  getCanvasX(): number {
    return (this.canvasX + this.panX) * this.zoom + this.offset.x;
  }
  getCanvasY(): number {
    return (this.canvasY + this.panY) * this.zoom + this.offset.y;
  }
  getCanvasWidth(): number {
    return this.canvasWidth * this.zoom;
  }
  getCanvasHeight(): number {
    return this.canvasHeight * this.zoom;
  }

  context: CanvasRenderingContext2D;
  offset: Point;
}
