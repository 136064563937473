import { Component, OnInit, ComponentFactoryResolver, ViewChild, Type, OnDestroy, ChangeDetectorRef, AfterViewInit, ComponentRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageProperties } from "../../Models/ImageProperties";
import { CreateImage } from "../../Models/CreateImage";
import { InsertionDirective } from "../../Directives/insertion.directive";
import { StockImagesComponent } from "./stock-images/stock-images.component";
import { MyStockImagesComponent } from "./my-stock-images/my-stock-images.component";
import * as _ from "underscore";
import { CreateTab } from "./CreateTab";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { SearchComponent } from "./search/search.component";
import { FamilySuppliedPhotosComponent } from "./family-supplied-photos/family-supplied-photos.component";
import { CategoryImageListBaseComponent } from "../shared/category-image-list-base/category-image-list-base.component";
import { HttpClient } from "@angular/common/http";
import { CreateService } from "../shared/Services/create.service";
import { ImageManagerService } from "../shared/Services/image-manager.service";
import { AuthenticationService } from "../shared/Services/authentication.service";

@Component({
  selector: "yw-image-dialog",
  templateUrl: "./image-dialog.component.html",
  styleUrls: ["./image-dialog.component.css"]
})
export class ImageDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(InsertionDirective) insertionPoint: InsertionDirective;

  constructor(public activeModal: NgbActiveModal,
    public createService: CreateService,
    private imageManager: ImageManagerService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private authentication: AuthenticationService,
    private httpClient: HttpClient) {
    this.authentication.selectedLocation.subscribe(location => {
      httpClient.get<Array<string>>("api/image/getCategoriesLocation/" + location.id).subscribe(categories => {
        this.categories = categories;
        console.log(this.categories);
      });
    });
  }

  searchClick() {
    this.tabs.forEach(t => t.active = false);
    this.tabs = _.without(this.tabs, this.searchTab);
    this.tabs.push(this.searchTab);
    this.searchTab.active = true;
    this.loadChildComponent();
    this.componentRef.instance.search = this.search;
  }
  setImage(base64Url: string) {
    this.base64Url = base64Url;
  }

  setActive(tab: CreateTab) {
    this.tabs.forEach(t => t.active = false);
    this.tabs = _.without(this.tabs, this.searchTab);
    tab.active = true;
    this.search = "";
    this.imageManager.setActive(null);
    this.createService.currentImage = new CreateImage();
    this.loadChildComponent();
  }

  setProperties(imageProperties: ImageProperties) {
    this.imageProperties = imageProperties;
  }

  doubleClicked(image: CreateImage) {
    this.imageManager.setActive(image);
    this.createService.currentImage = image;
    this.ok();
  }

  ok() {
    if (this.createService.currentImage.galleryView) {
      if (this.createService.currentImage.title === "" || this.createService.currentImage.title === "") {
        return true;
      }
    }
    this.createService.currentImage.projectId = this.createService.currentProject.id;
    this.imageManager.add(this.createService.currentImage).subscribe(() => {
      this.activeModal.close(this.createService.currentImage);
    });
    return false;
  }
  ngAfterViewInit() {
    this.loadChildComponent();
    this.cd.detectChanges();
  }
  ngOnInit() {
    this.createService.currentImage = new CreateImage();
    this.imageManager.setActive(null);
    this.imageManager.getLocationKeywords(this.authentication.selectedLocation.value.id).subscribe(keywords => {
      this.keywords = keywords;
    });
  }
  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
  loadChildComponent() {
    var activeTab = _.findWhere(this.tabs, { active: true });
    var componentType = activeTab.component;
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory<CategoryImageListBaseComponent>(componentType);

    let viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent<CategoryImageListBaseComponent>(componentFactory);
    this.componentRef.instance.imageUpdated = this.imageUpdated;
  }
  imageUpdated(image: CreateImage) {
    this.createService.selectedImage.next(image);
    this.createService.currentImage = image;
  }
  
  imageChanged() {
    this.createService.currentImage.projectId = this.createService.currentProject.id;
    this.imageManager.add(this.createService.currentImage);
  }

  searchTab = { title: "Search", component: SearchComponent, active: false } as CreateTab;
  tabs = [
    {
      title: "Batesville Stock Images",
      component: StockImagesComponent,
      active: true,
      helpDoc: "2ED23E70-78BA-4330-BFC0-01964AE15B96"
    },
    {
      title: "Funeral Home Stock Images",
      component: MyStockImagesComponent,
      active: false,
      helpDoc: "9972CB9D-6B0B-404A-88DD-E852791D847F"
    },
    {
      title: "Family Supplied Photos",
      component: FamilySuppliedPhotosComponent,
      active: false,
      helpDoc: "EC8D6B3F-7B79-4F5F-860B-64F713B18822"
    }
  ] as CreateTab[];

  searchOptions = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 2
        ? []
        : this.categories.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );
  }

  currentSelectionEnd: number = 0;
  keywordSearch($event: KeyboardEvent) {
    const input: HTMLTextAreaElement = ($event.target) as HTMLTextAreaElement;
    this.currentSelectionEnd = input.selectionEnd;
    if (this.createService.currentImage.keywords.slice(this.currentSelectionEnd - 1, this.currentSelectionEnd).trim() === "") {
      this.keywordList = [];
      return;
    }
    const chunk = this.createService.currentImage.keywords.slice(0, this.currentSelectionEnd);
    const match = chunk.match(/([\s\r\,])\w+/g);
    const lastIndex = match ? chunk.lastIndexOf(match[match.length - 1]) + 1 : 0;
    var word = chunk.slice(lastIndex, this.currentSelectionEnd).trim().toLowerCase();
    if (word.length < 1) {
      this.keywordList = [];
      return;
    }
    console.log(word);
    this.keywordList = _.uniq(this.keywords.filter(v => v.toLowerCase().indexOf(word) > -1)).slice(0, 10);
  }
  setWord(word) {
    const chunk = this.createService.currentImage.keywords.slice(0, this.currentSelectionEnd);
    const match = chunk.match(/([\s\r\,])\w+/g);
    const lastIndex = match ? chunk.lastIndexOf(match[match.length - 1]) + 1 : 0;
    const startString = this.createService.currentImage.keywords.slice(0, lastIndex);
    const endString = this.createService.currentImage.keywords.slice(this.currentSelectionEnd, this.createService.currentImage.keywords.length);
    this.createService.currentImage.keywords = `${startString}${word}${endString}`;
    this.keywordList = [];

  }
  childComponentType: Type<any>;
  search:string;
  base64Url: string;
  imageProperties: ImageProperties;
  componentRef: ComponentRef<CategoryImageListBaseComponent>;
  categories: string[];
  keywords: string[];
  keywordList: string[];
}
