import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ColorPickerModule } from "ngx-color-picker";
import { QuillModule } from "ngx-quill";
import { ApplicationService } from "./Services/application-service.service";
import { AuthenticationService } from "./Services/authentication.service";
import { ImageManagerService } from "./Services/image-manager.service";
import { MedallionService } from "./Services/medallion.service";
import { SortOptionService } from "./Services/sort-option.service";
import { CreateService } from "./Services/create.service";
import { ImageUploadContainerComponent } from "../image-dialog/image-upload-container/image-upload-container.component";
import { CategoryImageListComponent } from "../../Components/shared/category-image-list/category-image-list.component";
import { CategoryImageListBaseComponent } from "../../Components/shared/category-image-list-base/category-image-list-base.component";
import { CreateSettingsService } from "./Services/create-settings.service";
import { HelpDocsComponent } from "./help/help-docs/help-docs.component";
import { HelpDocsButtonComponent } from "./help-docs-button/help-docs-button.component";
import { HelpDocsService } from "./Services/help-docs.service";
import { HelpComponent } from "./help/help.component";
import { UserService } from "./Services/user.service";
import { SortOptionComponent } from "./sort-option/sort-option.component";
import { NgxPaginationModule } from "ngx-pagination";
import { SharedImageComponent } from "./shared-image/shared-image.component";
import { SharedImageListComponent } from "./shared-image-list/shared-image-list.component";
import { ToggleCheckComponent } from "./toggle-check/toggle-check.component";
import { FormInputTextComponent } from "../form-input-text/form-input-text.component";
import { TemplateService } from "./Services/template.service";
import { ArticleService } from "./Services/article.service";
import { MenuItemsService } from "./Services/menu-items.service";
import { MediaService } from "./Services/media.service";
import { VideoManagerService } from "./Services/video-manager.service";
import { HtmlTrustPipe } from "../../pipes/html-trust.pipe";
import { PaginationComponent } from "./pagination/pagination.component";


@NgModule({
  declarations: [
    ImageUploadContainerComponent,
    CategoryImageListComponent,
    CategoryImageListBaseComponent,
    HelpComponent,
    HelpDocsComponent,
    HelpDocsButtonComponent,
    SortOptionComponent,
    SharedImageListComponent,
    SharedImageComponent,
    ToggleCheckComponent,
    FormInputTextComponent,
    HtmlTrustPipe,
    PaginationComponent 
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    DragDropModule,
    NgxPaginationModule,
    QuillModule.forRoot(({
      theme: "snow"
    }) as Object),
    ColorPickerModule
  ],
  providers: [
    ApplicationService,
    ArticleService,
    AuthenticationService,
    CreateSettingsService,
    CreateService,
    HelpDocsService,
    ImageManagerService,
    MedallionService,
    MediaService,
    MenuItemsService,
    SortOptionService,
    NgxPaginationModule,
    TemplateService,
    UserService,
    VideoManagerService
  ],
  exports: [
    ImageUploadContainerComponent,
    CategoryImageListComponent,
    HelpComponent,
    HelpDocsComponent,
    HelpDocsButtonComponent,
    SortOptionComponent,
    SharedImageListComponent,
    SharedImageComponent,
    ColorPickerModule,
    ToggleCheckComponent,
    FormInputTextComponent,
    HtmlTrustPipe,
    PaginationComponent 
  ]
})
export class SharedModule {}
