<div class="col-sm p-0 mx-5">
  <div *ngIf="authenticationService.authenticating || loading">
    <span class="fa fa-spinner fa-spin"></span>
  </div>
  <div class="card" *ngIf="!authenticationService.authenticating && !loading">
    <div class="card-header">
      <div class="row p-0">
        <div class="col-sm">
          <h4>Projects</h4>
        </div>
        <div class="col-sm text-center" *ngIf="projects.length || searchValue.length>0">
          <div class="search">
            <div class="input-group">
              <input class="form-control" [(ngModel)]="search" placeholder="Search" (keydown)="keyDownFunction($event)" />
              <div class="input-group-append">
                <button class="btn btn-primary" style="height:30px" type="button" (click)="searchClick(search)"><span class="fa fa-search"></span></button>
              </div>
            </div>
            <create-help-docs-button class="search-help" [target]="'4AD71EBE-919F-42E1-8F89-B306E684B5B5'" visibility="false"></create-help-docs-button>
          </div>
        </div>
        <div class="col-sm text-end">
          <button type="button" class="btn btn-primary" (click)="newProject()">
            <span class="fa fa-plus-circle"></span> New Project
          </button>
          <create-help-docs-button [target]="'69FEF6CD-7755-4CB5-B2EA-210FE17B1D7E'"></create-help-docs-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <table *ngIf="projects.length" class="table table-hover table-striped text-center">
        <thead>
          <tr>
            <th scope="col" class="text-start">Name <yw-sort-option (sort)="sort($event)" [sortProperty]="'title'"></yw-sort-option></th>
            <th scope="col">Status <yw-sort-option (sort)="sort($event)" [sortProperty]="'completed'"></yw-sort-option></th>
            <th scope="col">Created By <yw-sort-option (sort)="sort($event)" [sortProperty]="'user.name'"></yw-sort-option></th>
            <th scope="col">Created Date <yw-sort-option (sort)="sort($event)" [sortProperty]="'dateCreated'"></yw-sort-option></th>
            <th scope="col">Actions
              <create-help-docs-button [target]="'80FC82CD-AF06-4B12-A59B-00BBD26EE59F'"></create-help-docs-button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let project of projects | paginate: { itemsPerPage: 10, currentPage: page }"
              [class.selected]="project.isSelected"
              (click)="onSelect(project)">
            <td class="text-start"><a [routerLink]="['/designer', project.id]">{{project.title}}</a></td>
            <td>{{project.completed?"Completed":"Open"}}</td>
            <td><div *ngIf="project.user != null">{{project.user.name}}</div></td>
            <td>{{project.dateCreated | date:'short'}}</td>
            <td>
              <a class="btn btn-link ml-1" *ngIf="!project.completed" [routerLink]="['/designer', project.id]"><span class="fa fa-edit"></span> Edit</a>
              <a class="btn btn-link ml-1" *ngIf="project.completed" [routerLink]="['/designer', project.id]"><span class="fa fa-binoculars"></span> View</a>
              <a class="btn btn-link ml-1" href="javascript:void(0)" (click)="copy(project.id)"><span class="fa fa-copy"></span> Copy</a>
              <a class="btn btn-link ml-1" *ngIf="!project.completed" href="javascript:void(0)" (click)="delete(project.id)"><span class="fa fa-trash"></span> Delete</a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <pagination-controls (pageChange)="page = $event"></pagination-controls>
            </td>
          </tr>
        </tfoot>
      </table>
      <div *ngIf="!projects.length && searchValue.length>0" class="text-center">
        <h2>No results found for your search criteria, <a href="javascript:void(0)" (click)="search=''; searchClick('')">go back</a></h2>
      </div>
      <div *ngIf="!projects.length && searchValue.length<1" class="text-end no-projects">
        <h2>Let's get started <span class="up-arrow"><span class="fa fa-reply"></span></span></h2>
      </div>
    </div>
  </div>
</div>
