<div class="menu-container dropdown" *ngIf="type=='dropdown' && menuItems.length">
  <div class="dropdown-toggle stack-menu"
       id="dropdownMenuButton"
       (click)="isOpen = !isOpen"
       aria-controls="dropdown-basic">
    <span id="dropdownMenuButtonIcon" class="fa fa-bars"></span>
  </div>
  <ul id="dropdown-basic"
      class="dropdown-menu"
      [class.show]="isOpen"
      (click)="closeMenu()"
      role="menu"
      aria-labelledby="dropdownMenuButton">
    <li role="menuitem" *ngFor="let menuItem of menuItems">
      <create-menu-view-item [menuItem]="menuItem"></create-menu-view-item>
    </li>
  </ul>
</div>
<nav class="navbar create-sticky-top navbar-expand-lg navbar-light bg-light" *ngIf="type=='navbar' && menuItems.length">
  <button class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>


    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">

        <li class="nav-item" *ngFor="let menuItem of menuItems">
          <create-menu-view-item [menuItem]="menuItem"></create-menu-view-item>
        </li>
      </ul>
    </div>
</nav>
