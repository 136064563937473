import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContentComponent } from "../../../Components/admin/content/content.component";
import { ArticleComponent } from "../../../Components/admin/content/article/article.component";
import { ArticlesComponent } from "./articles/articles.component";
import { ContentRoutingModule } from "./content-routing.module";
import { FormsModule } from "@angular/forms";
import { QuillModule } from "ngx-quill";
import { DeleteArticleComponent } from "../../../Components/admin/content/delete-article/delete-article.component";
import { SharedModule } from "../../shared/shared.module";
import { MenuItemsComponent } from "../../../Components/admin/content/menu-items/menu-items.component";
import { MenuItemComponent } from "../../../Components/admin/content/menu-item/menu-item.component";
import { DeleteMenuItemComponent } from "../../../Components/admin/content/delete-menu-item/delete-menu-item.component";
import { ChooseArticleComponent } from "../../../Components/admin/content/choose-article/choose-article.component";
import { ChooseMenuComponent } from "../../../Components/admin/content/choose-menu/choose-menu.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ChooseArticleLinkComponent } from "./choose-article-link/choose-article-link.component";
import { ChooseVideoComponent } from "./choose-video/choose-video.component";
import { NgxPaginationModule } from "ngx-pagination";
import { MediaEditComponent } from "./media-edit/media-edit.component";



@NgModule({
    declarations: [
        ContentComponent,
        ArticleComponent,
        ArticlesComponent,
        DeleteArticleComponent,
        MenuItemsComponent,
        MenuItemComponent,
        DeleteMenuItemComponent,
        ChooseArticleComponent,
        ChooseMenuComponent,
        ChooseArticleLinkComponent,
        ChooseVideoComponent,
        MediaEditComponent
    ],
    imports: [
        ContentRoutingModule,
        SharedModule,
        CommonModule,
        FormsModule,
        DragDropModule,
        NgxPaginationModule,
        QuillModule.forRoot({
            modules: {},
            theme: "snow"
        })
    ]
})
export class ContentModule { }
