<div class="m-3 p-2 border bg-white">
  <div class="container-fluid">
    <div class="row p-2 border-bottom mb-2">
      <div class="col-12">
        <h4>{{title}}</h4>
      </div>
    </div>
    <div class="row border-bottom p-2">
      <div class="col-12">
        <div class="container-fluid">
          <div class="row p-2">
            <div class="col-12">
              <label for="title">
                Title
              </label>
              <input id="title" class="form-control" [(ngModel)]="article.title" />
            </div>
          </div>
          <div class="row p-2">
            <div class="col-12">
              <label for="content">
                Content
              </label>
              <quill-editor [modules]="defaultModules"  id="content" [(ngModel)]="article.document" (onEditorCreated)="created($event)"></quill-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row p-2">
      <div class="col-12 text-end">
        <a routerLink="/admin/content/articles" class=" btn btn-outline-danger">Cancel</a>
        <a class="btn btn-primary" (click)="save()">
          <span class="fa fa-save"></span> Save
        </a>
      </div>
    </div>
  </div>
</div>
