<div class="container-fluid">
  <h4 class="pb-2 mb-2 border-bottom">{{title}}</h4>
  <div class="row">
    <div class="col p-2 text-center">
      <img *ngIf="image.url!=null" class="img-fluid" [src]="'uploaded-images/' + image.url"/>
      <create-image-upload-container *ngIf="image.url==null" (imageSet)="imageSet($event)"></create-image-upload-container>
    </div>
    <div class="col-3 border-left">
      <h5 class="header border-bottom pb-2 pt-2 mb-2">Properties</h5>
      <div class="form-group">
        <label for="title">Title</label>
        <input type="text"
               class="form-control"
               [(ngModel)]="image.title"
               id="title" />
      </div>
      <div class="form-group">
        <label for="category">Category</label>
        <input type="text"
               class="form-control"
               [(ngModel)]="image.category"
               [ngbTypeahead]="search"
               id="category" />
      </div>
      <div class="form-group">
        <label for="keywords">Keywords</label>
        <textarea type="text"
               class="form-control"
               [(ngModel)]="image.keywords"
               (keyup)="keywordSearch($event)"
               id="keywords" ></textarea>
        <div class="list-group keyword-menu">
          <a class="list-group-item" (click)="setWord(keyword)" *ngFor="let keyword of keywordList">{{keyword}}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="row border-top pt-2 mt-2">
    <div class="col text-end">
      <span *ngIf="!isNew">
        <a *ngIf="!image.deleted" class="btn btn-outline-warning ml-1" [routerLink]="['/images', 'delete', image.id, imageManagerService.selectedTab]">Delete</a>
        <a *ngIf="image.deleted" class="btn btn-outline-success ml-1" [routerLink]="['/images', 'restore', image.id, imageManagerService.selectedTab]">Restore</a>
        |
      </span>
      <a class="btn btn-primary" (click)="close()" >Close</a>
      <a class="btn btn-primary" (click)="save()">Save</a>
    </div>
  </div>
</div>
