import { Component, OnInit } from "@angular/core";

@Component({
  selector: "yw-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.less"]
})
export class LogoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
