import { Component, OnInit } from "@angular/core";

@Component({
  selector: "create-fh-images",
  templateUrl: "./fh-images.component.html",
  styleUrls: ["./fh-images.component.less"]
})
export class FHImagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
