import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "../models/MenuItem";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChooseArticleComponent } from "../choose-article/choose-article.component";
import { TemplateService } from "../../../shared/Services/template.service";
import { MenuItemsService } from "../../../shared/Services/menu-items.service";
import { MenuItemType } from "../models/MenuItemType";

@Component({
  selector: "create-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.less"]
})
export class MenuItemComponent implements OnInit {
  menuTypeDisabled: boolean;
  title: string;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private menuItemsService: MenuItemsService,
    public templateService: TemplateService,
    private modalService: NgbModal) { }


  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    this.title = "Edit Menu Item";
    this.menuItem = new MenuItem();
    this.isNew = false;
    if (id === "0") {
      this.isNew = true; 
      this.title = "New Menu Item";
      return;
    }
    this.menuItemsService.getMenuItem(id).subscribe(menuItem => {
      this.menuItem = menuItem;
      this.updated();
    });
  }
  updated() {
    this.menuTypeDisabled = false;
    if (this.menuItem.menuPosition === "survey-dialog") {
      this.menuItem.menuItemType = MenuItemType.Article;
      this.menuTypeDisabled = true;
    }
  }
  getArticle() {
    let articleDialog = this.modalService.open(ChooseArticleComponent, { size: "lg" });
    articleDialog
      .result
      .then((result) => {
        this.menuItem.article = result;

      },() => {});
  }
  save() {
    let results;
    if (this.isNew) {
      results = this.menuItemsService.add(this.menuItem);
    } else {
      results = this.menuItemsService.update(this.menuItem);
    }
    results.subscribe(() => {
      this.router.navigate(["admin", "content", "menu-items"]);
    });
  }
  menuItem: MenuItem;
  isNew: boolean = false;
}
