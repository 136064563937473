import { Component, OnInit } from "@angular/core";
import { Article } from "../models/Article";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
import { ArticleService } from "../../../shared/Services/article.service";

@Component({
  selector: "create-choose-article",
  templateUrl: "./choose-article.component.html",
  styleUrls: ["./choose-article.component.less"]
})
export class ChooseArticleComponent implements OnInit {
  articles: Article[];
  showAlert = false;
  constructor(public activeModal: NgbActiveModal,
    private articleService: ArticleService) {
  }
  toggleSelected(article: Article) {
    let isSelected = !article.isSelected;
    if (isSelected) {
      this.showAlert = false;
    }
    this.articles.forEach(art => art.isSelected = false);
    article.isSelected = isSelected;
  }
  ngOnInit() {
    this.articleService.loadArticles().subscribe(articles => {
      this.articles = articles;
    });
  }
  ok() {
    let selectedArticle = _.findWhere(this.articles, { isSelected: true });
    if (!selectedArticle) {
      this.showAlert = true;
      return;
    }
    this.activeModal.close(selectedArticle);
  }
}
