import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'underscore';
import {Observable} from 'rxjs';
import {MedallionSize} from '../../../Models';
import {IMedallionSize} from '../../../Interfaces';

@Injectable({
  providedIn: 'root'
})
export class MedallionService {
  medallionSizes = new Array<MedallionSize>();

  constructor(private http: HttpClient) {
  }

  loadMedallionSizes(): Observable<IMedallionSize[]> {
    const result = this.http.get<IMedallionSize[]>('/api/MedallionSizes/GetMedallionSizes');
    result.subscribe(results => {
      this.medallionSizes = results;
    });
    return result;
  }

  getSize(id: number): MedallionSize {
    const result = _.findWhere(this.medallionSizes, {id: id});
    return result;
  }

  getMedallionSize(id: number): Observable<IMedallionSize> {
    const result = this.http.get<IMedallionSize>(`/api/MedallionSizes/GetMedallionSize/${id}`);
    return result;
  }

  updateMedallionSize(medallionSize: MedallionSize) {
    const result = this.http.put<IMedallionSize>('/api/MedallionSizes/updateMedallionSize', medallionSize);
    return result;
  }

  addMedallionSize() {
    const result = this.http.post<IMedallionSize>('/api/MedallionSizes/addMedallionSize', new MedallionSize());
    return result;
  }

  deleteMedallionSize(medallionSize: MedallionSize): Observable<IMedallionSize> {
    const result = this.http.delete<IMedallionSize>('/api/MedallionSizes/deleteMedallionSize/' + medallionSize.id);
    return result;
  }
}
