import { Point } from "./Point";

export class Rectangle {
  constructor(x: number, y: number, width: number, height: number) {
    this.x = x;
    this.y = y;
    this.height = height;
    this.width = width;
  }

  x: number;
  y: number;
  height: number;
  width: number;
  right: number;
  bottom: number;

  pointInside(point: Point): boolean {
    this.right = this.x + this.width;
    this.bottom = this.y + this.height;
    var result = (
      point.x >= this.x &&
      point.x <= this.right &&
      point.y >= this.y &&
      point.y <= this.bottom
    );
    return result;
  }

  bind(rect: any) {
    rect.x = this.x;
    rect.y = this.y;
    rect.width = this.width;
    rect.height = this.height;
  }

  offSet(offsetRect: Rectangle): Rectangle {
    return new Rectangle(
      this.x + offsetRect.x,
      this.y + offsetRect.y,
      this.width,
      this.height
    );
  }
}
