import { Component, OnInit } from "@angular/core";
import { CategoryImageListBaseComponent } from
  "../../shared/category-image-list-base/category-image-list-base.component";
import { CreateImageCategory } from "../../shared/Services/CreateImageCategory";
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../shared/Services/authentication.service";
import { CreateService } from "../../shared/Services/create.service";

@Component({
  selector: "yw-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.less"]
})
export class SearchComponent extends CategoryImageListBaseComponent implements OnInit{
  categories: CreateImageCategory[];

  constructor(
    private imageManagerService: ImageManagerService,
    private authenticationService: AuthenticationService,
    public CreateService: CreateService) {
    super(CreateService);
  }
  clicked(image) {
    this.imageUpdated(image);
  }
  ngOnInit() {
    this.authenticationService.selectedLocation.subscribe(location => {
      this.imageManagerService.getImagesSearch(this.search, location.id)
        .subscribe(categories => {
          this.categories = categories;
        });
    });
  }

  search: string;
}
