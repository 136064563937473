import { CreateCanvasEvent } from "../../../../Models/CreateCanvasEvent";
import { Point, Medallion } from "../../../../Models/index";
import { CreateSettingsService } from "../../../shared/Services/create-settings.service";

export class WorkSpaceElement {
  constructor(private workspace,
    private createSettings: CreateSettingsService) {
    this.container = this.workspace.find(".canvas-container");
    return;
  }

  mouseDown(event: MouseEvent) {
    var cameraEvent = this.createEvent(event);
    this.dragging = true;
    this.offset = new Point(cameraEvent.pageX, cameraEvent.pageY);
    this.originalPan = new Point(this.pan.x, this.pan.y);
  }

  mouseUp() {
    this.dragging = false;
  }

  mouseMove(event: MouseEvent) {
    if (this.dragging) {
      var cameraEvent = this.createEvent(event);
      this.pan.x = this.originalPan.x + (cameraEvent.pageX - this.offset.x);
      this.pan.y = this.originalPan.y + (cameraEvent.pageY - this.offset.y);
    }
  }

  createEvent(mouseEvent: MouseEvent) {
    var event = new CreateCanvasEvent();
    let workspaceWidth = this.workspace.width();
    let workspaceHeight = this.workspace.height();
    let halfWidth = (workspaceWidth / 2);
    let halfHeight = (workspaceHeight / 2);
    var zoom = this.createSettings.zoom.current;
    var trueOffset = this.getOffset(mouseEvent);
    var newPageOffset = new Point(
      (((workspaceWidth - trueOffset.x - halfWidth) / zoom) - this.canvasOffset.x) * -1,
      (((workspaceHeight - trueOffset.y - halfHeight) / zoom) - this.canvasOffset.y) * -1
    );
    event.pageX = newPageOffset.x;
    event.pageY = newPageOffset.y;
    event.shiftKey = mouseEvent.shiftKey;
    event.ctrlKey = mouseEvent.ctrlKey;
    event.altKey = mouseEvent.altKey;
    return event;
  }

  setOffset(point: Point) {
    this.canvasOffset = point;
  }

  private getOffset(mouseEvent: MouseEvent): Point {
    var offset = this.workspace.offset();
    var offsetPoint = new Point(offset.left, offset.top);
    var page = new Point(mouseEvent.pageX, mouseEvent.pageY);
    return new Point(
      page.x - offsetPoint.x,
      page.y - offsetPoint.y
    );
  }
  capture(medallion: Medallion) {
    if (medallion.size == null) {
      return;
    }
    var current = JSON.stringify(medallion);
    if (this.currentMedallion !== current) {
      this.currentMedallion = current;
      this.history.push(current);
      var canvas = document.createElement("canvas");
      if (medallion.bleeding) {
        canvas.height = medallion.size.printHeight * this.createSettings.pixelsPerInch;
        canvas.width = medallion.size.printWidth * this.createSettings.pixelsPerInch;
      }
      else {
        canvas.height = medallion.size.height * this.createSettings.pixelsPerInch;
        canvas.width = medallion.size.width * this.createSettings.pixelsPerInch;
      }
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = medallion.backgroundColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      if (medallion.layout) {
        medallion.layout.forEach(layer => {
          ctx.drawImage(layer.image, layer.x, layer.y, layer.width, layer.height);
        });
      }
      medallion.thumbnail = canvas.toDataURL();
    }
  }
  dragging: boolean = false;
  container;
  canvasOffset = new Point(0, 0);
  pan = new Point(0, 0);
  offset = new Point(0, 0);
  originalPan = new Point(0, 0);
  currentMedallion: string;
  history = new Array<string>();

  resetPan() {
    this.pan = new Point(this.pan.x, this.pan.y);
  }
}
