import { Component, OnInit } from "@angular/core";
import { MedallionSize } from "../../../Models/index";
import { MedallionService } from "../../shared/Services/medallion.service";

@Component({
  selector: "yw-medallions",
  templateUrl: "./medallions.component.html",
  styleUrls: ["./medallions.component.less"]
})
export class AdminMedallionsComponent implements OnInit {
  medallionSizes: MedallionSize[];

  constructor(private medallionService: MedallionService) { }

  ngOnInit() {
    this.medallionService.loadMedallionSizes().subscribe(medallionSizes => {
      this.medallionSizes = medallionSizes;
    });
  }

}
