export class Point {
  x;
  y;
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  minus(b: Point): Point {
    return new Point(this.x - b.x, this.y - b.y);
  }
}