import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, BehaviorSubject } from "rxjs";
import { share } from "rxjs/operators";
import * as _ from "underscore";
import { CreateImage, Project, Medallion, User } from "../../../Models";
import { MedallionImage } from "../../../Models/MedallionImage";
import { Location } from "../../../Models/Location";
import { ILocation } from "../../../Interfaces/index";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': "application/json" })
};

  //YW - 132 - Add return to Batesville.com button
export class AppSettings {
  url = "";
}
  //YW - 132 - Add return to Batesville.com button

@Injectable({
  providedIn: "root"
})
export class CreateService {
  selectedImage = new BehaviorSubject( new CreateImage());
  appSettings = new BehaviorSubject(null);
  saving: boolean = false;
  constructor(private http: HttpClient) {
      //YW - 132 - Add return to Batesville.com button
    http.get<AppSettings>("/api/application/getsettings").subscribe(appSettings => { this.appSettings.next(appSettings) })
      //YW - 132 - Add return to Batesville.com button
  }

  project$ = new BehaviorSubject(new Project());

  projects$ = new BehaviorSubject(Array<Project>());
  private projects = new Array<Project>();

  newMedallion(project: Project): Observable<Medallion> {
    this.saving = true;
    if (project.id == null) {
      return new Observable<Medallion>();
    }
    var result = this.http.get<Medallion>(`api/medallions/create?projectId=${project.id}`).pipe(share());
    result.subscribe(i => {
      this.saving = false;
    });
    return result;
  }
  updateMedallion(medallion: Medallion) {
    this.saving = true;
    var post = this.http.put<Medallion>("/api/medallions/update", medallion).pipe(share());
    post.subscribe((med: Medallion) => {
      this.saving = false;
      medallion.url = med.url;
      medallion.thumbnailUrl = med.thumbnailUrl;
    });
    return post;
  }
  cloneMedallion(medallion: Medallion): Observable<Medallion> {
    this.saving = true;
    var post = this.http.post<Medallion>("/api/medallions/clone", medallion).pipe(share());
    post.subscribe((med: Medallion) => {
      this.saving = false;
      medallion.url = med.url;
      medallion.thumbnailUrl = med.thumbnailUrl;
    });
    return post;
  }
  deleteMedallion(medallion: Medallion) {
    this.saving = true;
    this.http.delete(`/api/medallions/delete/${medallion.id}`).subscribe(med => {
      this.saving = false;
      this.currentMedallion.next(null);
    });
  }

  newMedallionImage(medallion: Medallion, image: CreateImage): Observable<MedallionImage> {
    this.saving = true;
    if (medallion.id == null) {
      return new Observable<MedallionImage>();
    }
    var result = this.http.get<MedallionImage>(`api/medallionImages/create?medallionId=${medallion.id}&imageId=${image.id}`).pipe(share());
    result.subscribe(i => {
      this.saving = false;
    });
    return result;
  }
  deleteMedallionImage(medallionImage: MedallionImage): Observable<MedallionImage> {
    return this.http.delete<MedallionImage>(`/api/medallionImages/delete/${medallionImage.id}`);
  }
  updateMedallionImage(medallion: MedallionImage) {
    this.saving = true;
    this.http.put("/api/medallionImages/update", medallion).pipe(share()).subscribe(med => {
      this.saving = false;
    });
  }
  updateProject(project: Project) {
    this.saving = true;
    this.http.put("api/projects/update", project).subscribe(proj => {
      this.saving = false;
    });
  }
  updateProjects() {
    this.projects$.next(this.projects); // Calls/pushes to subscribe
  }

  //////// Get methods //////////
  getProjects(location: ILocation): Observable<Project[]> {
    if (location == null || location.id == null) {
      this.projects = [];
      this.updateProjects();
    }
    else {
      this.http.get<Project[]>(`/api/Projects/GetProjects/${location.id}`)
        .subscribe(result => {
            this.projects = result;
            this.updateProjects();
          },
          () => {
            this.projects = [];
            this.updateProjects();
          });
    }
    return this.projects$;
  }

  getProject(id: any): Observable<Project> {
    var result = this.http.get<Project>(`/api/Projects/GetProject?projectID=${id}`);
    return result;
  }


  getUser(): Observable<User> {
    return this.http.get<User>("/api/Designs/GetUser/");
  }


  createProject(project): Observable<Project> {
    var result = this.http.post<Project>("/api/Projects/CreateProject", project, httpOptions).pipe(share());
    result.subscribe((data : Project) => {
          this.projects.push(data);
          this.updateProjects();
        },
        error => {
          this.handleError(error);
        });
    return result;
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  getNewProject(location: Location): Observable<Project> {
    var project = new Project();
    let d = new Date();
    project.title = `New Project ${d.getFullYear()}${d.getMonth() + 1}${d.getDate()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
    project.locationId = location.id;
    return this.createProject(project);
  }
  copyProject(id: string, currentLocation: Location): Observable<Project> {
    var postObject = {
      projectId: id,
      locationId: currentLocation.id
    }
    var post = this.http.post<Project>("/api/Projects/CopyProject", postObject).pipe(share());
      post.subscribe((data: Project) => {
          this.projects.push(data);
          this.updateProjects();
        },
        error => {
          this.handleError(error);
      });
    return post;
  }

  setProject(newProject: Project) {
    this.currentProject = newProject;
  }

  setMedallion(medallion) {
    this.currentMedallion.next(medallion);
  }

  currentProject: Project;

  currentMedallion = new BehaviorSubject<Medallion>(null);
  currentImage = new CreateImage();

  deleteProject(id: string): Observable<Project> {
    const project = _.findWhere(this.projects, { id: id });
    var postProject = new BehaviorSubject(null);
    var post = this.http.delete<Project>(`/api/Projects/deleteProject/${id}`).pipe(share());
    post.subscribe((data: Project) => {
      postProject.next(project);
      },
      error => {
        this.handleError(error);
      });
    return postProject;
  }
}
