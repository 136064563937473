<div class="modal-header">
  <div class="col-10 p-0">
    <span style="font-size: 20px;">Order Images</span>&nbsp;<span style="font-size: 14px;">({{category.title}})</span>
  </div>
  <div class="col p-0 text-end">
    <button type="button" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss('Close')">
      <span class="fa fa-close"></span>
    </button>
  </div>
</div>
<div class="modal-body">
  <div cdkDropList
       (cdkDropListDropped)="drop($event, category.images)"
       class="list-group">
    <div class="list-group-item p-1"
         cdkDrag
         *ngFor="let image of category.images">
      <div class="deleted-image-overlay"
           *ngIf="image.deleted"></div>
      <div class="d-flex">
        <div style="width: 40px; height: 40px; background-size: contain; background-position: center; background-repeat: no-repeat"
             class="border"
             [style.background-image]="'url(/uploaded-images/' + image.url + ')'">
        </div>
        <div class="col align-self-center">{{image.title}}</div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.dismiss('Close')">Close</button>
</div>
