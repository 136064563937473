import { BrowserModule } from "@angular/platform-browser";
//import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { NgxPaginationModule } from "ngx-pagination";
import { GtagModule } from 'angular-gtag';

//import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  DesignerComponent,
  ProjectsComponent,
  CreateHeaderComponent, 
  CreateFooterComponent,
  MedallionListComponent,
  MedallionEditorComponent,
  ImageListComponent,
  NotFoundComponent,
  ZoomControllerComponent,
  ImageDialogComponent,
  ImageUploadContainerComponent,
  ImagePropertiesComponent,
  ImageDisplayComponent,
  MedallionCanvasHtmlComponent,
  EditorMedallionImageComponent,
  WorkspaceOverlayComponent,
  MedallionOptionsDialogComponent,
  MedallionPropertiesComponent,
  LogoutComponent
} from "./Components";

import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";

import { TrustStylePipe } from "./pipes/trust-style.pipe";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ProvideParentFormDirective } from "./Directives/provide-parent-form.directive";
import { StockImagesComponent } from "./Components/image-dialog/stock-images/stock-images.component";
import { MyStockImagesComponent } from "./Components/image-dialog/my-stock-images/my-stock-images.component";
import { InsertionDirective } from "./Directives/insertion.directive";
import { AdminModule } from "./Components/admin/admin.module";
import { SharedModule } from "./Components/shared/shared.module";
import { SearchComponent } from "./Components/image-dialog/search/search.component";
import { FamilySuppliedPhotosComponent } from "./Components/image-dialog/family-supplied-photos/family-supplied-photos.component";
import { ConfirmDialogComponent } from "./Components/confirm-dialog/confirm-dialog.component";
import { AlertDialogComponent } from "./Components/alert-dialog/alert-dialog.component";
import { AuthenticatingComponent } from "./Components/authenticating/authenticating.component";
import { ContentViewModule } from "./Components/content/content-view.module";
import { FHImagesModule } from "./Components/FH-Images/fh-images.module";
import { DefaultComponent } from "./Components/default/default.component";
import { SurveyDialogComponent } from "./Components/survey-dialog/survey-dialog.component";
import { OrderingComponent } from "./Components/FH-Images/ordering/ordering.component";
import { NgModule } from "@angular/core";

@NgModule({
    declarations: [
        AppComponent,
        DesignerComponent,
        ProjectsComponent,
        CreateHeaderComponent,
        CreateFooterComponent,
        MedallionListComponent,
        MedallionEditorComponent,
        ImageListComponent,
        NotFoundComponent,
        ZoomControllerComponent,
        ImageDialogComponent,
        ImagePropertiesComponent,
        ImageDisplayComponent,
        TrustStylePipe,
        MedallionCanvasHtmlComponent,
        EditorMedallionImageComponent,
        WorkspaceOverlayComponent,
        MedallionOptionsDialogComponent,
        MedallionPropertiesComponent,
        ProvideParentFormDirective,
        LogoutComponent,
        StockImagesComponent,
        MyStockImagesComponent,
        InsertionDirective,
        SearchComponent,
        FamilySuppliedPhotosComponent,
        ConfirmDialogComponent,
        AlertDialogComponent,
        AuthenticatingComponent,
        DefaultComponent,
        SurveyDialogComponent,
        OrderingComponent
    ],
    imports: [
        BrowserModule,
        AdminModule,
        ContentViewModule,
        FHImagesModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,        
        DragDropModule,
        NgxPaginationModule,
        SharedModule,
        GtagModule.forRoot({ trackingId: 'UA-26419965-14', trackPageviews: true }),
        UiSwitchModule.forRoot({
            size: "small",
            checkedLabel: "yes",
            uncheckedLabel: "no",
            color: "#0B406B"
        })
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
