import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./content.component";
import { ArticlesComponent } from "./articles/articles.component";
import { ArticleComponent } from "./article/article.component";
import { AdminComponent } from "./../admin.component";
import { DeleteArticleComponent } from "./delete-article/delete-article.component";
import { MenuItemsComponent } from "./menu-items/menu-items.component";
import { MenuItemComponent } from "./menu-item/menu-item.component";
import { DeleteMenuItemComponent } from "./delete-menu-item/delete-menu-item.component";
const routes: Routes = [
  {
    path: "admin", 
    component: AdminComponent,
    children: [
      {
        path: "content",
        component: ContentComponent,
        children: [
          { path: "", redirectTo: "articles", pathMatch: "full" },
          { path: "articles", component: ArticlesComponent },
          { path: "article/:id", component: ArticleComponent },
          { path: "delete-article/:id", component: DeleteArticleComponent },
          { path: "menu-items", component: MenuItemsComponent },
          { path: "menu-item/:id", component: MenuItemComponent },
          { path: "delete-menu-item/:id", component: DeleteMenuItemComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
