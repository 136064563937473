import { Directive, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[ywInsertion]"
})
export class InsertionDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
