<div  provide-parent-form>
  <div class="row">
    <select class="image-filter">
      <option [ngValue]="null">All</option>
      <option [ngValue]="category"
              *ngFor="let category of imageManager.categories">
        {{category}}
      </option>
    </select>
  </div>
  <div class="row image-container">
    <div class="image-option"
         (click)="imageClick(image)"
         (dblclick)="ok(image)"
         [class.active]="image.active"
         *ngFor="let image of imageManager.images">
      <yw-image-display [image]="image"></yw-image-display>
    </div>
  </div>
</div>
