export class CreateImage {
  id: number;
  title: string;
  category: string;
  url: string;
  active: boolean = false;
  galleryView: boolean = false;
  isBatesville: boolean = false;
  locationId: number = null;
  width: number;
  height: number;
  deleted: boolean;
  keywords: string;
  projectId: string;
}
