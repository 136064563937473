import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from "@angular/common";
import { FHImagesRouterModule } from "./router/fh-images-router.module";
import { FHImageComponent } from "./image/fh-image.component";
import { FHGroupComponent } from "./group/group.component";
import { FHImagesComponent } from "./fh-images.component";
import { FHImageListComponent } from "./images/image-list.component";
import { SharedModule } from "../shared/shared.module";
import { DeleteComponent } from "./delete/delete.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { RestoreComponent } from "./restore/restore.component";
import { FHImageUploadContainerComponent } from "./image-upload-container/fh-image-upload-container.component";
import { DefaultImageListComponent } from "./images/default-image-list/default-image-list.component";
import { BatesvilleImageListComponent } from "./images/batesville-image-list/batesville-image-list.component";
import { SearchImageListComponent } from "./images/search-image-list/search-image-list.component";
import { DragDropModule } from "@angular/cdk/drag-drop";

@
NgModule({
  declarations: [
    FHImageListComponent,
    FHImageComponent,
    FHGroupComponent,
    FHImagesComponent,
    DeleteComponent,
    RestoreComponent,
    FHImageUploadContainerComponent,
    DefaultImageListComponent,
    BatesvilleImageListComponent,
    SearchImageListComponent
  ],
  imports: [
    CommonModule,
    FHImagesRouterModule,
    SharedModule,
    FormsModule,
    NgbModule,
    DragDropModule,
    UiSwitchModule.forRoot({
      size: "small",
      checkedLabel: "yes",
      uncheckedLabel: "no",
      color: "#0B406B"
    })
  ]
})
export class FHImagesModule { }
