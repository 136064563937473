import { Component, OnInit, OnDestroy, HostListener, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Size } from "../../Models/Size";
import { Project, Medallion } from "../../Models/index";
import { MedallionEditorComponent } from "./medallion-editor/medallion-editor.component";
import { Subscription } from "rxjs";
import * as $ from "jquery";
import { CreateService } from "../shared/Services/create.service";
import { CreateSettingsService } from "../shared/Services/create-settings.service";
import { AuthenticationService } from "../shared/Services/authentication.service";
import { MedallionService } from "../shared/Services/medallion.service";
import { ApplicationService } from "../shared/Services/application-service.service";
import { MenuItemsService } from "../shared/Services/menu-items.service";

@Component({
  selector: "app-designer",
  templateUrl: "./designer.component.html",
  styleUrls: ["./designer.component.css"],
  host: { "id": "project-container" }
})
export class DesignerComponent implements OnInit, OnDestroy {
  private sub: any;
  container = new Size();
  project: Project = null;


  @ViewChild(MedallionEditorComponent) medallionEditorChild: MedallionEditorComponent;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setSize();
  }

  constructor(public createService: CreateService,
    private createSettingsService: CreateSettingsService,
    private authenticationService: AuthenticationService,
    private medallionService: MedallionService,
    private route: ActivatedRoute,
    private router: Router,
    private menuItemsService: MenuItemsService,
    private applicationService: ApplicationService) {

  }
  checkName() {
    if (this.project.title === "" || this.project.title == null) {
      this.project.title = "Unnamed Project";
      this.titleChanged();
    }
  }
  openedFull(isOpen: boolean) {
    this.medallionEditorChild.resetOverlay();
  }
  setSize() {
    let headerHeight = 61;
    if ($("body").hasClass("has-nav-bar")) {
      headerHeight += 32;
    }
    const footerHeight = 35;
    const padding = 30;
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.container.height = h - headerHeight - footerHeight - padding;
    this.container.width = w;
  }
  titleChanged() {
    this.createService.updateProject(this.project);
  }
  medallionChanged(event) {
    //console.log(JSON.stringify(this.project));
    //this.CreateService.updateProject(this.project);
  }
  completeProject() {
    this.project.completed = true;
    window.open('/Print/ViewPrint/' + this.project.id);
    setTimeout(() => {
        this.router.navigate(["projects"]);
      },
      500);
  }
  ngOnInit() {
    this.createService.currentMedallion.next(null);
    this.applicationService.condensedFooter = true;
    //this.authenticationService.changeLocationAllowed = false;
    this.setSize();
    const id = this.route.snapshot.paramMap.get("id");
    this.subscriptions.push(this.medallionService.loadMedallionSizes().subscribe(() => {
      this.subscriptions.push(this.createService.getProject(id).subscribe(project => {
        if (project.medallions == null) {
          project.medallions = new Array<Medallion>();
        }
        for (let i = 0; i < project.medallions.length; i++) {
          const size = this.medallionService.getSize(project.medallions[i].size.id);
          project.medallions[i] = Medallion.bindMedallion(project.medallions[i], this.createSettingsService);
          project.medallions[i].size = size;
        }
        this.project = project;
        this.createService.project$.next(project);
        this.createService.currentProject = project;
      }));
    }));
    this.subscriptions.push(this.menuItemsService.menuItems.subscribe(() => {
      this.setSize();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  subscriptions: Subscription[] = [];
}
