import { Component, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "create-form-input-text",
  templateUrl: "./form-input-text.component.html",
  styleUrls: ["./form-input-text.component.less"]
})
export class FormInputTextComponent {
  @Input() label: string;

  currentValue: string = "";
  @Output() currentValueChange = new EventEmitter<string>();

  @Input()
  get value() {
    return this.currentValue;
  }
  set value(val) {
    this.currentValue = val;
    this.currentValueChange.emit(this.currentValue);
  }
}
