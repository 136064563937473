import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "../shared/Services/authentication.service";

@Component({
  selector: "yw-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.less"]
})
export class AdminComponent implements OnInit {

  constructor(public authentication: AuthenticationService) { }

  ngOnInit() {
    this.authentication.changeLocationAllowed = true;
  }

}
