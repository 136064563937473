import { Medallion, Location } from "./index";
import { IProject } from "../Interfaces/index";
import { IUser } from "../Interfaces/user";

export class Project implements IProject {
  user: IUser;
  completed: boolean;
  medallions = new Array<Medallion>();
  id: string;
  title: string;
  location: Location;
  dateCreated: string;
  locationId: number;
  userId: number;
  isSelected: boolean;
}
