<div class="modal-header">
  <h5>{{menuItem.title}}</h5>
  <div (click)="close()" class="btn btn-outline-danger">&times;</div>
</div>
<div class="modal-body">
  <div *ngIf="menuItem.menuItemType == 1" [innerHTML]="trustedHtml | htmlTrust"></div>
  <div *ngIf="menuItem.menuItemType == 3">
    <a [href]="menuItem.url" target="_blank">{{menuItem.url}}</a>
  </div>
  <a class="btn btn-link" (click)="neverAgain = !neverAgain" *ngIf="menuItem.allowOptOut">
    <create-toggle-check style="display: inline-block" class="mr-1" [value]="neverAgain"></create-toggle-check> Never Again
  </a>
</div>
<div class="modal-footer">
  <div (click)="close()" class="btn btn-outline-danger">Dismiss</div>
</div>
