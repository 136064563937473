import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Article } from "../models/Article";
import { ArticleService } from "../../../shared/Services/article.service";

@Component({
  selector: "create-delete-article",
  templateUrl: "./delete-article.component.html",
  styleUrls: ["./delete-article.component.less"]
})
export class DeleteArticleComponent implements OnInit {


  constructor(private route: ActivatedRoute,
    private router: Router,
    private articleService: ArticleService) { }


  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    this.article = new Article();
    if (id === "0") {
      return;
    }
    this.articleService.getArticle(id).subscribe(article => {
      this.article = article;
    });
  }
  delete() {
    this.articleService.delete(this.article.id).subscribe(() => {
      this.router.navigate(["admin", "content", "articles"]);
    });
  }
  article;
}
