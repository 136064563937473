import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AdminMedallionsComponent } from "./medallions/medallions.component";
import { AdminEditMedallionsComponent } from "./edit-medallions/edit-medallions.component";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminComponent } from "./admin.component";
import { SharedModule } from "../shared/shared.module";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UserSearchComponent } from "./user-search/user-search.component";
import { UserEditComponent } from "./user-edit/user-edit.component";
import { NgxPaginationModule } from "ngx-pagination";
import { DeleteMedallionComponent } from "../../Components/admin/delete-medallion/delete-medallion.component";
import { ContentModule } from "./content/content.module";
@NgModule({
    declarations: [
        AdminComponent,
        AdminMedallionsComponent,
        AdminEditMedallionsComponent,
        UserSearchComponent,
        UserEditComponent,
        DeleteMedallionComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        ContentModule,
        FormsModule,
        NgxPaginationModule,
        NgbModule,
        AdminRoutingModule
    ]
})
export class AdminModule { }
