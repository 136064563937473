<div class="row"
     [style.height.px]="container.height"
     [style.width.px]="container.width">
  <div class="col-sm p-0 mx-5" style="z-index: 1" *ngIf="project != null">
    <div class="row border-bottom pb-1">
      <div class="col-sm p-0">
        <label>
          Project Name: <input class="project-input border-bottom"
                               [disabled]="project.completed"
                               (change)="titleChanged()"
                               (blur)="checkName()"
                               [(ngModel)]="project.title" />
        </label>
        <create-help-docs-button class="search-help" [target]="'55ADFAE5-0986-4B97-AE78-627A2B71AA26'"></create-help-docs-button>
      </div>
      <div class="col-sm p-0 text-end">
        <span class="m-1 font-weight-bold"
              *ngIf="createService.saving">
          <span class="fa fa-spinner fa-spin"></span> Saving!
        </span>
        <a class="btn btn-primary"
           target="_blank"
           [class.disabled]="createService.saving"
           href="#"
           [href]="'/Print/Summary/' + project.id">Preview</a>
        <create-help-docs-button [target]="'A1B2210D-0D02-4EC1-9925-6F8B012DC971'"></create-help-docs-button>
        <a class="btn btn-primary"
           href="#"
           [class.disabled]="createService.saving"
           (click)="completeProject()">Print</a>
        <create-help-docs-button [target]="'98CD8B24-30DF-4173-BDC7-7AC5F001A99D'"></create-help-docs-button>
        <span>|</span>
        <a routerLink="/" class="btn btn-primary">Close</a>
      </div>
    </div>
    <div class="flex-container">
      <yw-medallion-list [project]="project"
                         (openedFull)="openedFull($event)"></yw-medallion-list>
      <yw-medallion-editor #medallionEditor
                           (changed)="medallionChanged($event)"
                           [project]="project"></yw-medallion-editor>
    </div>
  </div>
</div>
