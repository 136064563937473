import { Component, OnInit } from "@angular/core";
import { CreateImage } from "../../../Models/CreateImage";
import { Subscription } from "rxjs";
import * as _ from "underscore";
import { CategoryImageListBaseComponent } from
  "../../shared/category-image-list-base/category-image-list-base.component";
import { ImageManagerService } from "../../shared/Services/image-manager.service";
import { AuthenticationService } from "../../shared/Services/authentication.service";
import { CreateService } from "../../shared/Services/create.service";
import { CreateImageCategory } from "../../shared/Services/CreateImageCategory";

@Component({
  selector: "yw-my-stock-images",
  templateUrl: "./my-stock-images.component.html",
  styleUrls: ["./my-stock-images.component.less"]
})
export class MyStockImagesComponent extends CategoryImageListBaseComponent implements OnInit {
  constructor(private imageManagerService: ImageManagerService,
    private authenticationService: AuthenticationService,
    public createService: CreateService) {
    super(createService);
  }

  ngOnInit() {
    this.authenticationService.selectedLocation.subscribe(location => {
      this.subscription = this.imageManagerService.getLocationImages(location.id).subscribe(images => {
        this.unfilteredImageCategories = images;
        this.filterItems();
      });
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  clicked(image) {
    this.imageUpdated(image);
  }

  filterItems() {
    if (this.search.length > 0) {
      this.images = _.map(this.unfilteredImageCategories, category => this.filterCategory(category))
        .filter(cat => cat.images.length > 0);
    }
    else {
      this.images = this.unfilteredImageCategories;
    }
  }

  filterCategory(category: CreateImageCategory): CreateImageCategory {
    var cat = new CreateImageCategory();
    cat.title = category.title;
    cat.images = _.filter(category.images, image => this.searchImage(image));
    return cat;
  }

  searchImage(image: CreateImage) {
    var search = this.search.toLowerCase();
    return (image.title && image.title.toLowerCase().indexOf(search) > -1) || (image.category && image.category.toLowerCase().indexOf(search) > -1);
  }
  subscription: Subscription;
  search: string = "";
  images: Array<CreateImageCategory> = [];
  unfilteredImageCategories: CreateImageCategory[];
}
