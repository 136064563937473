<div class="help-container">
  <div class="handle"
       (click)="toggleOpen()">Help</div>
  <div class="row border-bottom p-1 mb-1">
    <div class="col-9">
      <h3>
        Help
      </h3>
    </div>
    <div class="col-3 text-end">
      <span class="btn btn-primary"
            [class.active]="sorting"
            *ngIf="allowOrdering"
            (click)="toggleSorting()">
        <span class="fa fa-sort-amount-desc"></span> Sort
      </span>
      <span class="btn btn-outline-danger"
            (click)="close()">Close</span>
    </div>
  </div>
  <div *ngIf="allowOrdering && sorting"
       class="help-content example-list"
       cdkDropList
       (cdkDropListDropped)="drop($event)">
    <div cdkDrag
         cdkDragLockAxis="y"
         class="example-box"
         *ngFor="let doc of helpDocs">
      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
      <create-help-docs [class.active]="doc.active"
                        [sorting]="sorting"
                        [document]="doc"></create-help-docs>
    </div>
  </div>
  <div *ngIf="!allowOrdering || !sorting"
       class="help-content">
    <div *ngFor="let doc of helpDocs">
      <create-help-docs [class.active]="doc.active" [document]="doc"></create-help-docs>
    </div>
  </div>
</div>
