import { Component, OnInit } from "@angular/core";
import { Medallion } from "../../Models/Medallion";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MedallionSize } from "../../Models/index";
import * as $ from "jquery";
import * as bowser from "bowser";
import { MedallionService } from "../shared/Services/medallion.service";

@Component({
  selector: "yw-medallion-options-dialog",
  templateUrl: "./medallion-options-dialog.component.html",
  styleUrls: ["./medallion-options-dialog.component.less"]
})
export class MedallionOptionsDialogComponent implements OnInit {
  constructor(public medallionService: MedallionService,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.medallionSizes = this.medallionService.medallionSizes.filter(s=>s.published);
  }
  openColorPicker($event) {
    const browser = bowser.getParser(window.navigator.userAgent);
    var info = browser.getBrowser();
// ReSharper disable once InconsistentNaming
    const isIE11 = info.name === "Internet Explorer" && info.version === "11.0";
    if (isIE11) {
      this.IEFix();
      $(window).on("resize",
        () => {
          this.IEFix();
        });
    }
  }
  medallion: Medallion;
  medallionSizes: MedallionSize[];

// ReSharper disable once InconsistentNaming
  IEFix() {
    let left = $("body").width() / 2;
    let halfWidth = ($(".color-picker").width() / 2) + 40;
    $(".color-picker").css({ "transform": `translate(${left - halfWidth}px, 20px)` });
  }
}
