<div>
  <div class="d-flex border-bottom p-2 m-2">
    <h3 class="col">Medallions</h3>
    <div class="col text-end">
      <a class="btn btn-primary" [routerLink]="['../edit-medallion/0']">
        <span class="fa fa-plus"></span> New Medallion
      </a>
      <create-help-docs-button [target]="'0E34DA64-74F8-45D7-949F-D7FE94112A99'"></create-help-docs-button>
    </div>
  </div>
  <table class="table table-bordered table-hover table-striped">
    <thead>
      <tr class="p-2">
        <td>Title</td>
        <td>Width</td>
        <td>Height</td>
        <td>Bleed Size</td>
        <td>Multiplier</td>
        <td>Allow Bleed</td>
        <td>Medallion Count</td>
        <td>Published</td>
        <td>Actions</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let medallion of medallionSizes" class="p-2">
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">{{medallion.title}}</a>
        </td>
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">{{medallion.width}}"</a>
        </td>
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">{{medallion.height}}"</a>
        </td>
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">{{medallion.bleedSize}}"</a>
        </td>
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">{{medallion.multiplier}} &times;</a>
        </td>
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">
            <span class="fa"
                  [class.fa-check-square]="medallion.allowBleed"
                  [class.fa-square-o]="!medallion.allowBleed"></span>
          </a>
        </td>
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">{{medallion.medallionCount}}</a>
        </td>
        <td>
          <a [routerLink]="['../edit-medallion', medallion.id]">
            <span class="fa"
                  [class.fa-check-square]="medallion.published"
                  [class.fa-square-o]="!medallion.published"></span>
          </a>
        </td>
        <td>
          <a class="btn btn-primary" [routerLink]="['../edit-medallion', medallion.id]"><span class="fa fa-edit"></span> edit</a>
          <a class="btn btn-primary"
             [class.disabled]="medallion.medallionCount>0"
             [routerLink]="['../delete-medallion', medallion.id]"><span class="fa fa-trash"></span>delete</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
