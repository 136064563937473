import { Component, OnInit } from "@angular/core";
import { ApplicationService } from "../shared/Services/application-service.service";

@Component({
  selector: "app-create-footer",
  templateUrl: "./create-footer.component.html",
  styleUrls: ["./create-footer.component.css"]
})
export class CreateFooterComponent implements OnInit {
  currentYear = 0;
  currentVersion = "";

  constructor(public applicationService: ApplicationService) {
    this.currentYear = (new Date()).getFullYear();
    this.currentVersion = "1.0";
  }

  ngOnInit() {
  }

}
