<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-Create">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="fa fa-bars"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="/images/images/batesville">Images</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-link" routerLink="medallions">Medallions</a>
        </li>
        <li class="nav-item" *ngIf="authentication.user.role>100">
          <a class="nav-link" routerLinkActive="active-link" routerLink="userSearch">User Search</a>
        </li>
        <li class="nav-item" *ngIf="authentication.user.role>99">
          <a class="nav-link" routerLinkActive="active-link" routerLink="content">Content Manager</a>
        </li>
      </ul>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
