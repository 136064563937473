<div class="d-flex">
  <form #medallionForm class="col-3 m-2 p-2 bg-white border" (submit)="onSubmit()">
    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" name="published" id="published" [(ngModel)]="medallion.published" />
        <label class="form-check-label" for="published">Published</label>
      </div>
    </div>
    <div class="form-group">
      <label for="title">Title</label>
      <input class="form-control border-bottom" name="title" id="title" [(ngModel)]="medallion.title" />
    </div>
    <div class="form-group">
      <label for="width">Width</label>
      <input class="form-control border-bottom" name="width" id="width" [(ngModel)]="medallion.width" />
    </div>
    <div class="form-group">
      <label for="height">Height</label>
      <input class="form-control border-bottom" name="height" id="height" [(ngModel)]="medallion.height" />
    </div>
    <div class="form-group">
      <label for="multiplier">Multiplier</label>
      <input class="form-control border-bottom" step=".001" type="number" name="multiplier" id="multiplier" [(ngModel)]="medallion.multiplier" />
    </div>
    <div class="form-group" *ngIf="medallion.allowBleed">
      <label for="bleedSize">Bleed Size</label>
      <input class="form-control border-bottom" step=".001" type="number" name="bleedSize" id="bleedSize" [(ngModel)]="medallion.bleedSize" />
    </div>
    <div class="form-group">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" name="allowBleed" id="allowBleed" [(ngModel)]="medallion.allowBleed" />
        <label class="form-check-label" for="allowBleed">Allow Bleed</label>
      </div>
    </div>
    <div class="text-end">
      <button type="submit" class="btn btn-primary">
        <span class="fa fa-save"></span> Save
      </button>
    </div>
  </form>
  <div class="col m-2 p-2 bg-white border">
    <div class="demo-bleed-box"
         [class.no-bleed]="!medallion.allowBleed"
         [style.width.in]="medallion.width + (medallion.bleedSize * 2)"
         [style.height.in]="medallion.height + (medallion.bleedSize * 2)">
      <div class="border-offset">
        <div class="demo-cut-box"
             [style.width.in]="medallion.width"
             [style.height.in]="medallion.height"
             [style.left.in]="medallion.bleedSize"
             [style.top.in]="medallion.bleedSize">
        </div>
      </div>
    </div>
    <div class="text-end text-light">This is representative only and not completely accurate.</div>
  </div>
</div>
